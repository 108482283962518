import { Component } from '@angular/core';
import { ModalService } from '../../../services/modal.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'tna-report-times-modal',
  templateUrl: './report-times-modal.component.html',
  styleUrls: ['./report-times-modal.component.scss'],
})
export class ReportTimesModalComponent {
  public form = new FormGroup({
    onTheGo: new FormControl(null, [Validators.required]),
    arrival: new FormControl(null, [Validators.required]),
    departure: new FormControl(null, [Validators.required]),
  });

  constructor(private modalService: ModalService) {}

  public onConfirm(): void {
    this.modalService.close({ success: true, data: this.form.value });
  }

  public onCancel(): void {
    this.modalService.close({ success: false });
  }
}
