<app-page-header title="Type klant"></app-page-header>

<app-page-body>
  <div class="container">
    <div class="card">
      <div class="body p-1">
        <ng-container class="list" *ngIf="workOrder$ | async as workOrder; else loading">
          <p class="py-2">Betreft het een zakelijke of particuliere klant?</p>
          <div class="row">
            <div class="col col-48">
              <button class="btn btn-success btn-xxl" (click)="setType('private')">Particulier</button>
            </div>
            <div class="col col-48">
              <button class="btn btn-success btn-xxl" (click)="setType('company')">Zakelijk</button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</app-page-body>

<ng-template #loading>
  <div class="loader">
    <img class="loader" src="assets/img/loader-primary.svg" />
  </div>
</ng-template>
