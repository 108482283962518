import { Exclude, Expose } from 'class-transformer';
import { IOrderLine } from '@vandelft/shared/interfaces';
import { QuantifiedProductHelper } from '@vandelft/shared/helpers';

@Exclude()
export class OrderLine implements IOrderLine {
  @Expose()
  public id: string;

  @Expose()
  public orderId!: string;

  @Expose()
  public productId!: string;

  @Expose()
  public articleNumber!: string;

  @Expose()
  public description!: string;

  @Expose()
  public quantity!: number;

  @Expose()
  public price!: number;

  @Expose()
  public remarks!: string;

  public get isDepositProduct(): boolean {
    return this.articleNumber?.toUpperCase() === 'P5-0011';
  }

  public get priceExc(): number {
    return QuantifiedProductHelper.getProductPriceExc(this);
  }

  public get tax(): number {
    return QuantifiedProductHelper.getProductTax(this);
  }

  public get priceInc(): number {
    return QuantifiedProductHelper.getProductPriceInc(this);
  }

  public get totalPriceExc(): number {
    return QuantifiedProductHelper.getTotalProductPriceExc(this);
  }

  public get totalTax(): number {
    return QuantifiedProductHelper.getTotalProductTax(this);
  }

  public get totalPriceInc(): number {
    return QuantifiedProductHelper.getTotalProductPriceInc(this);
  }
}
