<ng-container *ngIf="depositItem$ | async as item">
  <app-page-header title="Afletteren">
    <div class="actions ml-auto">
      <button class="btn btn-primary btn-sm" (click)="registerDeposit($event, item)">Goedkeuren</button>
    </div>
  </app-page-header>

  <div class="container">
    <div class="card mt-3">
      <div class="body">
        <ngx-extended-pdf-viewer
          src="{{ apiUrl }}/deposit/{{ item.mechanic.id }}/download?token={{ token$ | async }}"
          [showToolbar]="true"
          [showSidebarButton]="false"
          [showFindButton]="false"
          [showPagingButtons]="true"
          [showZoomButtons]="true"
          [showPresentationModeButton]="false"
          [showOpenFileButton]="false"
          [showPrintButton]="true"
          [showDownloadButton]="true"
          [showSecondaryToolbarButton]="true"
          [showRotateButton]="false"
          [showHandToolButton]="false"
          [showScrollingButton]="false"
          [showSpreadButton]="false"
          [showPropertiesButton]="false"
          backgroundColor="#ffffff"
          [showBorders]="true"
          height="81rem"></ngx-extended-pdf-viewer>
      </div>
    </div>
  </div>
</ng-container>
