import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { jwtDecode } from 'jwt-decode';

import { User } from '../models';
import { environment } from '@vandelft/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  public constructor(private http: HttpClient) {}

  public getUsers(): Observable<User[]> {
    return this.http.get(`${environment.apiUrl}/users`).pipe(map((data: User[]) => plainToInstance(User, data)));
  }

  public getMechanics(): Observable<User[]> {
    return this.http
      .get(`${environment.apiUrl}/users/mechanics`)
      .pipe(map((data: User[]) => plainToInstance(User, data)));
  }

  public getUserById(id: string): Observable<User> {
    return this.http.get(`${environment.apiUrl}/users/${id}`).pipe(map((data: User) => plainToInstance(User, data)));
  }

  public checkEmailInUse(email: string, userId: string): Observable<boolean | null> {
    const reqBody = { email, userId };
    return this.http
      .post(`${environment.apiUrl}/users/check-username`, reqBody)
      .pipe(map((data: { taken: boolean }) => data.taken));
  }

  public checkHash(hash: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/users/check-hash/${hash}`);
  }

  public activateUser(hash: string, password: string): Observable<any> {
    const data = { password };
    return this.http.put(`${environment.apiUrl}/users/${hash}/activate`, data);
  }

  public resetPassword(username: string): Observable<any> {
    return this.http.put(`${environment.apiUrl}/users/reset-password`, {
      username,
    });
  }

  public signInWithEmailAndPassword(email: string, password: string): Observable<string> {
    const reqBody = { username: email, password };
    return this.http
      .post(`${environment.apiUrl}/users/login`, reqBody)
      .pipe(map((data: { access_token: string }) => data.access_token));
  }

  public logout(): void {
    localStorage.removeItem('jwtToken');
  }

  public getCurrentJwtToken(): string {
    const token = localStorage.getItem('jwtToken');
    return JSON.parse(token);
  }

  public getCurrentUser(): User {
    const token = this.getCurrentJwtToken();
    const decoded: any = jwtDecode(token);
    return plainToInstance(User, {
      id: decoded.sub,
      role: decoded.role,
      name: decoded.name,
    });
  }

  public refreshToken(token: string): Observable<string> {
    const reqBody = { token };
    return this.http
      .post(`${environment.apiUrl}/users/refresh`, reqBody)
      .pipe(map((data: { access_token: string }) => data.access_token));
  }
}
