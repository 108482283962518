<form class="form p-2" [formGroup]="paymentForm" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <div class="modal-title">
      <h1>Betaling toevoegen</h1>
    </div>
  </div>
  <div class="modal-body">
    <div class="form-group required">
      <label class="label">Bedrag</label>
      <input type="number" class="form-control" formControlName="amount" />
    </div>

    <div class="form-group required">
      <label class="label">Betaalmethode</label>
      <select class="form-control" formControlName="method">
        <option *ngFor="let paymentMethod of paymentMethods" value="{{ paymentMethod.key }}">
          {{ paymentMethod.label }}
        </option>
      </select>
    </div>
  </div>
  <div class="modal-footer">
    <div class="actions">
      <a class="btn btn-secondary" (click)="onCancel()">Annuleren</a>
      <button type="submit" [disabled]="paymentForm.invalid" class="btn btn-primary ml-1">OK</button>
    </div>
  </div>
</form>
