import { Exclude, Expose, Type } from 'class-transformer';
import { Address } from './address';
import { Message } from './message';

@Exclude()
export class Chat {
  @Expose()
  @Type(() => Message)
  public messages: Message[];

  @Expose()
  @Type(() => Message)
  public lastMessage: Message;

  @Expose()
  @Type(() => Address)
  address: Address;
}
