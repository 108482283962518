import { Component, Input, ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-wizard-step',
  templateUrl: './wizard-step.component.html',
})
export class WizardStepComponent {
  @Input()
  public title: string;

  @Input()
  public active: boolean;

  public constructor(private el: ElementRef, private renderer: Renderer2) {
    this.updateVisibility();
  }

  public setState(active: boolean): void {
    this.active = active;
    this.updateVisibility();
  }

  private updateVisibility(): void {
    if (!this.active) {
      return this.renderer.addClass(this.el.nativeElement, 'hidden');
    }

    this.renderer.removeClass(this.el.nativeElement, 'hidden');
  }
}
