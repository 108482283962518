import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { v4 as uuid } from 'uuid';
import { LocalImage, Image } from '@vandelft/modules/shared/models';
import { Observable, from, of } from 'rxjs';
import { IBaseFile } from '@vandelft/shared/interfaces/base-file';
import { environment } from '@vandelft/environments/environment';
import { Store } from '@ngxs/store';
import { AuthState } from '@vandelft/modules/shared/state/auth';
import { plainToInstance } from 'class-transformer';

@Component({
  selector: 'tna-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FileUploadComponent,
    },
  ],
})
export class FileUploadComponent implements ControlValueAccessor {
  @Input()
  public buttonLabel = `Foto's toevoegen`;

  @Input()
  public fileTypeAccept = 'image/*';

  @Input()
  public appFileType = null;

  @Input()
  public allowMultiple = true;

  public files: IBaseFile[] = [];
  public previews = [];

  private onChange = (_: IBaseFile[]) => {};
  private onTouched = () => {};

  public constructor(private store: Store) {}

  public writeValue(files: IBaseFile[]): void {
    this.files = [];
    this.previews = [];
    for (const file of files ?? []) {
      this.addFile(file);
    }
  }

  public registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  public async onFileSelected(e: any): Promise<void> {
    this.onTouched();
    for (const file of e.target.files) {
      const data = await this.readFile(file);

      const newFile = plainToInstance(LocalImage, {
        id: uuid(),
        name: file.name,
        size: file.size,
        fileType: this.appFileType,
        internalType: 'localImage',
        data,
      });

      this.addFile(newFile);
    }
  }

  private readFile(file: File): Promise<string> {
    return new Promise((resolve: (_: string) => void) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result.toString());
      reader.readAsDataURL(file);
    });
  }

  private generateThumbnailFromBase64Image(base64Image: string, maxWidth: number): Observable<string> {
    const promise = new Promise<string>(async (resolve) => {
      const img = document.createElement('img');

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const scale = maxWidth / img.width;
        canvas.width = img.width * scale;
        canvas.height = img.height * scale;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        resolve(canvas.toDataURL());
      };

      img.src = base64Image;
    });

    return from(promise);
  }

  public deleteFile(index: number, id: string): void {
    const file = this.files.find((f) => f.id === id);
    const fileIndex = this.files.findIndex((f) => f.id === id);

    if (file instanceof LocalImage) {
      this.files.splice(fileIndex, 1);
    }

    if (file instanceof Image) {
      this.files[fileIndex] = plainToInstance(Image, {
        ...file,
        deleted: new Date(),
      });
    }

    this.previews.splice(index, 1);
    this.onChange(this.files);
  }

  public addFile(file: IBaseFile): void {
    this.files.push(file);

    if (file instanceof LocalImage) {
      const thumbnail = this.getThumbnail(file);
      this.previews.push({
        id: file.id,
        fileType: file.fileType,
        thumbnail,
      });
    }

    if (file instanceof Image) {
      const token = this.store.selectSnapshot(AuthState.token);
      this.previews.push({
        id: file.id,
        fileType: file.fileType,
        thumbnail: of(`${environment.apiUrl}/images/${file.id}/thumbnail?token=${token}`),
      });
    }

    this.onChange(this.files);
  }

  private getThumbnail(file: LocalImage): Observable<any> {
    if (file.name.endsWith('.pdf')) {
      return of(`/assets/img/pdf.png`);
    }

    return this.generateThumbnailFromBase64Image(file.data, 250);
  }
}
