import { WorkOrder } from '../../models';

export class OpenInvoicesList {
  public static readonly type = '[Invoices] Open invoices list';
}

export class OpenInvoicesDetails {
  public static readonly type = '[Invoices] Open invoice detail';
  public constructor(public workOrder: WorkOrder) {}
}

export class LoadInvoices {
  public static readonly type = '[Invoices] Load invoices';
  public constructor(public filters?: any) {}
}

export class LoadInvoice {
  public static readonly type = '[Invoices] Load invoice';
  public constructor(public id: string) {}
}
