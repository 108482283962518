import { Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { v4 as uuid } from 'uuid';
import { of } from 'rxjs';
import { debounceTime, switchMap, catchError, map, filter, tap } from 'rxjs/operators';
import { GeocodingService } from '../../../services/geocoding.service';

@Component({
  selector: 'app-address-form-group',
  templateUrl: './address-form-group.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressFormGroupComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AddressFormGroupComponent),
      multi: true,
    },
  ],
})
export class AddressFormGroupComponent implements OnChanges {
  @Input()
  public isCompany = false;

  public addressForm = new FormGroup({
    id: new FormControl(uuid(), [Validators.required]),
    contact: new FormControl(null, [Validators.required]),
    street: new FormControl(null, [Validators.required]),
    houseNumber: new FormControl(null, [Validators.required]),
    houseNumberSuffix: new FormControl(null),
    postalCode: new FormControl(null, [Validators.required]),
    city: new FormControl(null, [Validators.required]),
    phoneNumber: new FormControl(null, [Validators.required, Validators.minLength(10), Validators.maxLength(15)]),
    phoneNumber2: new FormControl(null, [Validators.minLength(10), Validators.maxLength(15)]),
    email: new FormControl(null, [Validators.required, Validators.email]),
  });

  private onChange: any = () => {};
  public onTouch: any = () => {};

  public constructor(private geocodingService: GeocodingService) {
    this.addressForm.valueChanges.subscribe((value) => {
      this.onChange(value);
    });

    this.addressForm
      .get('postalCode')
      .valueChanges.pipe(
        filter(() => !this.addressForm.get('street')?.value || this.addressForm.get('street')?.value?.length === 0),
        debounceTime(250),
        map((value) => {
          value = value.replace(/ /g, '').toUpperCase();
          const regex = new RegExp('^[0-9]{4}[A-Z]{2}$');
          if (!regex.test(value)) {
            return;
          }

          return value;
        }),
        switchMap((value: string) => {
          if (value) {
            return this.geocodingService.getAddressByPostalCode(value).pipe(
              catchError((e: any) => {
                return null;
              }),
            );
          }

          return of(null);
        }),
      )
      .subscribe((value) => {
        if (!value) {
          return;
        }

        this.addressForm.get('street').setValue(value.street);
        this.addressForm.get('city').setValue(value.city);
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('isCompany' in changes) {
      const isCompany = changes.isCompany.currentValue;

      if (isCompany) {
        this.addressForm.get('email').clearValidators();
        return this.addressForm.updateValueAndValidity();
      }

      this.addressForm.get('email').setValidators([Validators.required, Validators.email]);

      this.addressForm.updateValueAndValidity();
    }
  }

  public writeValue(value: any): void {
    if (value) {
      this.addressForm.patchValue(value);
      this.onChange(this.addressForm.value);
    }
  }
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public validate(_: FormControl): { invalid: boolean } {
    if (this.addressForm.valid) {
      return null;
    }

    return { invalid: true };
  }
}
