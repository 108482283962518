<div *ngIf='chat$ | async as chat'>
  <app-page-header [title]='chat.address.contact'>
    <ng-container *ngIf='getReport(chat) as report'>
      <ng-container *ngIf='prefix$ | async as prefix'>
        <a
          class='btn btn-secondary'
          style='display: inline-block'
          routerLink='/{{prefix}}/reports/{{report.id}}'
          target='_blank'>
          Melding bekijken
        </a>
      </ng-container>
    </ng-container>
  </app-page-header>

  <div class='container chat' *ngIf='templates$ | async as templates'>
    <div class='row messages' *ngFor='let message of chat.messages' [class.sentByUs]='message.sentByUs'>
      <div class='message' [class.sentByUs]='message.sentByUs' [id]='message.id'>
        <div class='reply' *ngIf='message.reply' (click)='scrollTo(message.reply.id)'>
          <div class='text' *ngIf='message.reply.message else replyTemplate'>
            <app-message
              [content]='message.reply.message.substring(0, 120)'
              [shortened]='message.reply.message.length > 120'>
            </app-message>
          </div>
          <ng-template #replyTemplate>
            <div class='template' *ngIf='getTemplate(message.reply, templates) as template'>
              <div class='text'>
                <app-message
                  [content]='template.bodyText.substring(0, 120)'
                  [shortened]='template.bodyText.length > 120'>
                </app-message>
              </div>
            </div>
          </ng-template>
        </div>
        <div class='text' *ngIf='message.message else template'>
          <app-message [content]='message.message'></app-message>
        </div>
        <ng-template #template>
          <div class='template' *ngIf='getTemplate(message, templates) as template'>
            <div class='text'>{{ template.bodyText }}</div>
            <ul class='buttons' *ngFor='let button of template.buttons'>
              <ng-container *ngIf='button.type === "URL"'>
                <li>
                  <a [href]='button.url' target='_blank'>{{ button.text }}</a>
                </li>
              </ng-container>
              <ng-container *ngIf='button.type === "PHONE_NUMBER"'>
                <li>
                  <a href='tel:{{button.phone_number}}'>{{ button.text }}</a>
                </li>
              </ng-container>
            </ul>
          </div>
        </ng-template>
        <div class='time'>
          <ng-container *ngIf='isToday(message.timestamp) else showDay'>
            {{ message.timestamp | date:'HH:mm' }}
          </ng-container>
          <ng-template #showDay>
            {{ message.timestamp | date:'YYYY-MM-dd HH:mm' }}
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <div class='container'>
    <form class='row form' [formGroup]='form'>
      <div class='col-10'>
        <textarea
          type='text'
          formControlName='message'
          cdkAutosizeMinRows='1'
          cdkAutosizeMaxRows='5'
          cdkTextareaAutosize
          #autosize='cdkTextareaAutosize'></textarea>
      </div>
      <div class='col-2'>
        <button class='send btn btn-success' type='submit' (click)='send()'>Verstuur</button>
      </div>
    </form>
  </div>
</div>
