import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { ModalService } from '@vandelft/modules/shared/services/modal.service';

import { Payment } from '../../../models';
import { PAYMENT_METHODS } from '../../../constants';

@Component({
  selector: 'app-payment-form-modal',
  templateUrl: './payment-form-modal.component.html',
})
export class PaymentFormModalComponent implements OnInit {
  @Input()
  public payment: Payment;

  public paymentMethods = PAYMENT_METHODS;

  public paymentForm = new FormGroup({
    amount: new FormControl(0, [Validators.required]),
    method: new FormControl('pin', [Validators.required]),
    date: new FormControl(new Date(), [Validators.required]),
    remarks: new FormControl(null),
  });

  constructor(private modalService: ModalService) {}

  async ngOnInit(): Promise<void> {
    if (this.payment) {
      this.paymentForm.patchValue(this.payment);
      this.paymentForm.get('amount').setValidators([Validators.required]);
      this.paymentForm.updateValueAndValidity();
    }
  }

  public onSubmit(): void {
    const data = this.paymentForm.value;
    this.modalService.close(data);
  }

  public onCancel(): void {
    this.modalService.close(null);
  }
}
