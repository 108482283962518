import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';

import { CLIENT_TYPES } from '@vandelft/modules/shared/constants';
import { Report, User, LocalImage, WorkOrder, Note } from '@vandelft/modules/shared/models';
import { ReportsState, LoadReport, AddNoteToReport } from '@vandelft/modules/shared/state/reports';
import { AuthState } from '@vandelft/modules/shared/state/auth';
import { environment } from '@vandelft/environments/environment';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { plainToInstance } from 'class-transformer';
import { CreateWorkOrderDraftFromExistingWorkOrder } from '@vandelft/modules/shared/state/work-order-draft';

@Component({
  selector: 'app-reports-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent {
  @Select(ReportsState.report)
  public report$: Observable<Report>;

  @Select(AuthState.token)
  public token$: Observable<string>;

  @Select(AuthState.user)
  public user$: Observable<User>;

  public apiUrl = environment.apiUrl;

  public clientTypeMapping = {};

  public notesForm = new FormGroup({
    note: new FormControl(null, [Validators.required]),
    attachment: new FormControl(null),
  });

  public constructor(
    private route: ActivatedRoute,
    private store: Store,
  ) {
    const reportId = this.route.snapshot.paramMap.get('id');

    for (const clientType of CLIENT_TYPES) {
      this.clientTypeMapping[clientType.key] = clientType.label;
    }

    this.store.dispatch([new LoadReport(reportId)]);
  }

  public async onAttachmentSelected(ev: any): Promise<void> {
    const file = ev.target.files[0];
    const data = await this.readFile(file);

    const attachment = plainToInstance(LocalImage, {
      id: uuid(),
      name: file.name,
      size: file.size,
      data,
    });

    this.notesForm.patchValue({ attachment });
  }

  public addNote(): void {
    const report: Report = this.store.selectSnapshot(ReportsState.report);
    const data: Note = { ...this.notesForm.value } as Note;
    this.store.dispatch(new AddNoteToReport(report, data));
    this.notesForm.reset();
  }

  public onEditWorkOrder(workOrder: WorkOrder): void {
    this.store.dispatch(new CreateWorkOrderDraftFromExistingWorkOrder(workOrder));
  }

  private readFile(file: File): Promise<string> {
    return new Promise((resolve: (_: string) => void): void => {
      const reader: FileReader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => resolve(e.target.result.toString());
      reader.readAsDataURL(file);
    });
  }
}
