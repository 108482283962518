import { Expose, Exclude } from 'class-transformer';

import { ICompany } from '@vandelft/shared/interfaces';

@Exclude()
export class Company implements ICompany {
  @Expose()
  public id: string | null;

  @Expose()
  public name: string | null;

  @Expose()
  public email: string | null;
}
