import { Component } from '@angular/core';
import { Store } from '@ngxs/store';

import { AppService } from '@vandelft/modules/shared/services/app.service';
import { LogoutUser } from '@vandelft/modules/shared/state/auth';

@Component({
  selector: 'app-authenticated-layout',
  templateUrl: './authenticated-layout.component.html',
})
export class AuthenticatedLayoutComponent {
  public constructor(private store: Store, public appService: AppService) {}

  public logout(): void {
    this.store.dispatch([new LogoutUser()]);
  }
}
