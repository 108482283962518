import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { Order } from '@vandelft/modules/shared/models';

import {
  MATERIAL_OPTIONS,
  DOOR_TYPE_OPTIONS,
  YES_NO_OPTIONS,
  BACKSET_LOCK_OPTIONS,
  SPACING_LOCK_OPTIONS,
} from '@vandelft/modules/shared/constants';
import { environment } from '@vandelft/environments/environment';
import { Store, Select } from '@ngxs/store';
import { AuthState } from '@vandelft/modules/shared/state/auth';
import { OrdersState, LoadOrder } from '@vandelft/modules/shared/state/orders';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
})
export class ViewComponent {
  @Select(OrdersState.order)
  public order$: Observable<Order>;

  @Select(AuthState.token)
  public token$: Observable<string>;

  public apiUrl = environment.apiUrl;
  public token: string;

  public materialOptions = {};
  public doorTypeOptions = {};
  public yesNoOptions = {};
  public backsetLockOptions = {};
  public spacingLockOptions = {};

  public constructor(private store: Store, private route: ActivatedRoute) {
    const orderId = this.route.snapshot.paramMap.get('id');
    this.store.dispatch(new LoadOrder(orderId));

    this.materialOptions = this.processOptions(MATERIAL_OPTIONS);
    this.doorTypeOptions = this.processOptions(DOOR_TYPE_OPTIONS);
    this.yesNoOptions = this.processOptions(YES_NO_OPTIONS);
    this.backsetLockOptions = this.processOptions(BACKSET_LOCK_OPTIONS);
    this.spacingLockOptions = this.processOptions(SPACING_LOCK_OPTIONS);
  }

  private processOptions(options: any): object {
    const mapping = {};

    for (const option of options) {
      if (option.value === true) {
        mapping[1] = option.label;
        continue;
      }

      if (option.value === false) {
        mapping[0] = option.label;
        continue;
      }
      mapping[option.value] = option.label;
    }

    return mapping;
  }
}
