import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { LoadChat, LoadMessageTemplates, MessagesState, SendMessage } from '@vandelft/modules/shared/state/messages';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Chat, Message, MessageTemplate, Report } from '@vandelft/modules/shared/models';
import { FormControl, FormGroup } from '@angular/forms';
import { v4 as uuid } from 'uuid';
import { filter } from 'rxjs/operators';
import { EnvironmentState } from '@vandelft/modules/shared/state/environment';

@Component({
  selector: 'app-chat',
  styleUrls: ['./chat.component.scss'],
  templateUrl: './chat.component.html',
})
export class ChatComponent implements OnInit, OnDestroy {
  @Select(MessagesState.chat) public chat$: Observable<Chat>;
  @Select(MessagesState.templates) public templates$: Observable<MessageTemplate[]>;
  public prefix$: Observable<string> = inject(Store).select(EnvironmentState.prefix);

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  public subscriptions: Subscription[] = [];

  public form: FormGroup = new FormGroup({
    id: new FormControl(uuid()),
    message: new FormControl(),
    addressId: new FormControl(),
    sentByUs: new FormControl(true),
    to: new FormControl(),
  });

  public constructor(
    private store: Store,
    private router: ActivatedRoute,
  ) {}

  public ngOnInit(): void {
    const addressId: string = this.router.snapshot.paramMap.get('id');
    this.store.dispatch(new LoadChat(addressId));
    this.store.dispatch(new LoadMessageTemplates());
    this.subscriptions.push(
      this.chat$.pipe(filter((a: Chat): boolean => a !== null)).subscribe((chat: Chat): void => {
        this.form.patchValue({ addressId, to: chat.address.phoneNumber });

        setTimeout(() => this.store.dispatch(new LoadChat(addressId)), 3000);
      }),
    );
  }

  public scrollTo(id: string): void {
    const element: HTMLElement = document.getElementById(id);
    if (!element) {
      return;
    }
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  public isToday(date: Date): boolean {
    return date?.toDateString() === new Date().toDateString();
  }

  public getTemplate(message: Message, templates: MessageTemplate[]): MessageTemplate {
    return templates.find((template: MessageTemplate): boolean => template.name === message.template);
  }

  public getReport(chat: Chat): Report {
    const message: Message = chat.messages.find((message: Message): boolean => message.report !== null);
    return message.report;
  }

  public send(): void {
    const formData: Partial<Message> = { ...this.form.value };
    this.store.dispatch(new SendMessage(formData));

    this.form.patchValue({ id: uuid(), message: '' });

    setTimeout(() => this.scrollTo(formData.id), 1000);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
