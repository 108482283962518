import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { StockItem } from '../models';
import { environment } from '../../../../environments/environment';
import { plainToInstance } from 'class-transformer';

@Injectable({
  providedIn: 'root',
})
export class StockService {
  public constructor(private http: HttpClient) {}

  public getStockItems(): Observable<StockItem[]> {
    return this.http
      .get(`${environment.apiUrl}/stock?`)
      .pipe(map((data: StockItem[]) => plainToInstance(StockItem, data)));
  }

  public getStockItemByMechanicId(id: string): Observable<StockItem> {
    return this.http
      .get(`${environment.apiUrl}/stock/mechanic/${id}`)
      .pipe(map((data: StockItem) => plainToInstance(StockItem, data)));
  }
}
