import { Pipe, PipeTransform } from '@angular/core';
import { Report } from '@vandelft/modules/shared/models';
import * as moment from 'moment';

@Pipe({ name: 'stateFilter' })
export class StateFilterPipe implements PipeTransform {
  public transform(value: Report[], ...args: any[]): Report[] {
    const [status] = args;
    const filtered = value.filter((v) => v.status === status);

    if (['completed'].includes(args[0])) {
      return this.sortByLastWorkOrderDesc(filtered);
    }

    return filtered;
  }

  private sortByLastWorkOrderDesc(reports: Report[]): Report[] {
    return reports.sort((a: Report, b: Report) => {
      const lastA = a.workOrders.length > 0 ? a.workOrders[a.workOrders.length - 1].executed : null;

      const lastB = b.workOrders.length > 0 ? b.workOrders[b.workOrders.length - 1].executed : null;

      if (lastA && lastB) {
        const momentA = moment(lastA);
        const momentB = moment(lastB);
        if (momentA.isBefore(momentB)) {
          return 1;
        }

        if (momentA.isAfter(momentB)) {
          return -1;
        }
      }

      return 0;
    });
  }
}
