import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';

import { ProgressStateModel } from './progress.state-model';
import { SetProgress, ResetProgress } from './progress.actions';

@State<ProgressStateModel>({
  name: 'progress',
  defaults: {
    steps: null,
    current: null,
  },
})
@Injectable()
export class ProgressState {
  @Selector()
  static steps(state: ProgressStateModel): Array<any> {
    return state.steps;
  }

  @Selector()
  static current(state: ProgressStateModel): number {
    return state.current;
  }

  @Action(SetProgress)
  public setProgress(ctx: StateContext<ProgressStateModel>, { current, total }: SetProgress): ProgressStateModel {
    const steps = [];
    const keys = ['client-info', 'activities', 'order-product', 'completion'];

    for (let i = 0; i < total; i++) {
      steps.push({
        key: keys[i],
        label: `Stap ${i + 1}`,
        done: current - 1 > i,
      });
    }

    return ctx.patchState({ steps, current });
  }

  @Action(ResetProgress)
  public resetProgress(ctx: StateContext<ProgressStateModel>): ProgressStateModel {
    return ctx.patchState({ steps: null, current: null });
  }
}
