import { Exclude, Expose, Type } from 'class-transformer';

import { IMessage } from '@vandelft/shared/interfaces';
import { Address } from '@vandelft/modules/shared/models/address';
import { Report } from '@vandelft/modules/shared/models/report';

@Exclude()
export class Message implements IMessage {
  @Expose()
  @Type(() => String)
  public id: string | null;

  @Expose()
  @Type(() => String)
  addressId: string;
  @Expose()
  @Type(() => String)
  reportId: string;
  @Expose()
  @Type(() => String)
  replyOf: string;

  @Expose()
  @Type(() => String)
  message: string;
  @Expose()
  @Type(() => String)
  template: string;

  @Expose()
  @Type(() => Boolean)
  sentByUs: boolean;

  @Expose()
  @Type(() => Date)
  sent?: Date;
  @Expose()
  @Type(() => Date)
  read?: Date;

  @Expose()
  @Type(() => Date)
  scheduled?: Date;

  @Expose()
  @Type(() => String)
  to?: string;

  @Expose()
  @Type(() => String)
  waMessageId: string;
  @Expose()
  @Type(() => String)
  waUserId: string;

  @Expose()
  @Type(() => String)
  messageType:
    | 'text'
    | 'reaction'
    | 'image'
    | 'sticker'
    | 'unknown'
    | 'location'
    | 'contact'
    | 'button'
    | 'interactive'
    | 'order'
    | 'system'
    | 'unsupported';
  @Expose()
  @Type(() => String)
  messageSubType: 'list_reply' | 'button_reply';

  @Expose()
  @Type(() => Date)
  timestamp: Date;

  @Expose()
  @Type(() => Date)
  created: Date;
  @Expose()
  @Type(() => Date)
  updated: Date;
  @Expose()
  @Type(() => Date)
  deleted: Date;

  @Expose()
  @Type(() => Address)
  address: Address;

  @Expose()
  @Type(() => Message)
  reply: Message;

  @Expose()
  @Type(() => Message)
  report?: Report;
}
