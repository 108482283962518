<ng-container *ngIf="report$ | async as report">
  <app-page-header title="Melding">
    <div
      class="actions ml-auto"
      *ngIf="
        !['admin', 'planner'].includes(prefix) &&
        report.status !== 'completed' &&
        report.mechanicId === (user$ | async)?.id
      ">
      <a class="btn btn-sm btn-primary" (click)="addWorkOrder($event, report)">Nieuwe werkbon</a>
    </div>
  </app-page-header>

  <app-reports-details></app-reports-details>
</ng-container>
