<app-page-header title="Afletteren"></app-page-header>

<div class="container">
  <div class="card">
    <div class="body">
      <ng-container *ngIf="depositItems$ | async as depositItems; else loading">
        <ul class="list">
          <li *ngFor="let depositItem of depositItems" class="py-1" (click)="show(depositItem.mechanic?.id)">
            <div class="details">
              <div class="bold">{{ depositItem.mechanic.fullName }}</div>
              <div class="muted">€ {{ depositItem.totalAmount }}</div>
              <div class="muted">
                Laatst afgeletterd:
                <ng-container *ngIf="depositItem.mechanic.lastDeposit">
                  {{ depositItem.mechanic.lastDeposit | date: 'dd-MM-yyyy HH:mm' }}
                </ng-container>
                <ng-container *ngIf="!depositItem.mechanic.lastDeposit">Nooit</ng-container>
              </div>
            </div>
          </li>
        </ul>

        <div class="py-2" *ngIf="depositItems?.length === 0">Alle monteurs hebben afgeletterd</div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="loader">
    <img class="loader" src="assets/img/loader-primary.svg" />
  </div>
</ng-template>
