<input type="text" #autocomplete class="form-control" (keyup)="onKeyUp($event, autocomplete.value)" />
<div class="suggestions" *ngIf="(query$ | async)?.length && (items$ | async)?.length > 0">
  <ul>
    <li
      *ngFor="let item of items$ | async"
      [ngClass]="{ selected: item == selectedItem }"
      (click)="setSelectedItem($event, item)">
      {{ item.name }}
    </li>
  </ul>
</div>
