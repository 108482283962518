import { IImage, IOrder } from '../interfaces';

export class OrderHelper {
  public static getProductImages(order: IOrder): any {
    return (order.images as any[])?.filter((i: IImage) => i.fileType === 'productImage');
  }

  public static getCertificates(order: IOrder): any {
    return (order.images as any[])?.filter((i: IImage) => i.fileType === 'certificate');
  }
}
