import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { EnvironmentState } from '../environment';
import { filter, tap } from 'rxjs/operators';
import { firstValueFrom, Observable } from 'rxjs';
import { WhatsappService } from '@vandelft/modules/shared/services/whatsapp.service';
import {
  DeleteMessage,
  EditMessage,
  LoadChat,
  LoadChatQueue,
  LoadChats,
  LoadMessage,
  LoadMessageTemplates,
  MarkChatAsRead,
  OpenChat,
  OpenChatOverview,
  SendMessage,
  UpdateMessage,
} from '@vandelft/modules/shared/state/messages/messages.actions';
import { MessagesStateModel } from '@vandelft/modules/shared/state/messages/messages.state-model';
import { Chat, Message, MessageTemplate } from '@vandelft/modules/shared/models';

@State<MessagesStateModel>({
  name: 'messages',
  defaults: {
    chats: [],
    chatQueue: [],
    chat: null,
    message: null,
    templates: [],
  },
})
@Injectable()
export class MessagesState {
  public constructor(
    private store: Store,
    private whatsappService: WhatsappService,
  ) {}

  @Selector()
  static chats(state: MessagesStateModel): Array<Chat> {
    return state.chats;
  }

  @Selector()
  static chatQueue(state: MessagesStateModel): Array<Chat> {
    return state.chatQueue;
  }

  @Selector()
  static chat(state: MessagesStateModel): Chat {
    return state.chat;
  }

  @Selector()
  static message(state: MessagesStateModel): Message {
    return state.message;
  }

  @Selector()
  static templates(state: MessagesStateModel): MessageTemplate[] {
    return state.templates;
  }

  @Action(LoadMessageTemplates)
  public async loadMessageTemplates(ctx: StateContext<MessagesStateModel>): Promise<void> {
    const templates: MessageTemplate[] = await firstValueFrom(this.whatsappService.getMessageTemplates());

    ctx.patchState({ templates });
  }

  @Action(LoadChats)
  public async loadChats(ctx: StateContext<MessagesStateModel>): Promise<void> {
    const chats: Chat[] = await firstValueFrom(this.whatsappService.getChats('all'));

    ctx.patchState({ chats });
  }

  @Action(LoadChatQueue)
  public async loadChatQueue(ctx: StateContext<MessagesStateModel>): Promise<void> {
    const chatQueue: Chat[] = await firstValueFrom(this.whatsappService.getChats('unsent'));

    ctx.patchState({ chatQueue });
  }

  @Action(LoadChat)
  public loadChat(ctx: StateContext<MessagesStateModel>, { addressId }: LoadChat): Observable<Chat> {
    return this.whatsappService.getChat(addressId).pipe(
      tap((chat: Chat): void => {
        ctx.patchState({ chat });
      }),
    );
  }

  @Action(LoadMessage)
  public loadMessage(ctx: StateContext<MessagesStateModel>, { id }: LoadMessage): Observable<Message> {
    return this.whatsappService.getMessage(id).pipe(tap((message: Message) => ctx.patchState({ message })));
  }

  @Action(SendMessage)
  public sendMessage(ctx: StateContext<MessagesStateModel>, { message }: SendMessage): Observable<Message> {
    const chat: Chat = ctx.getState().chat;
    return this.whatsappService.sendMessage(message).pipe(
      filter((a: Message) => !!a),
      tap((updatedMessage: Message) =>
        ctx.patchState({
          chat: { address: chat.address, lastMessage: updatedMessage, messages: [...chat.messages, updatedMessage] },
        }),
      ),
    );
  }

  @Action(UpdateMessage)
  public updateMessage(ctx: StateContext<MessagesStateModel>, { message }: UpdateMessage): Observable<Message> {
    return this.whatsappService
      .updateMessage(message)
      .pipe(tap((updatedMessage: Message) => ctx.patchState({ message: updatedMessage })));
  }

  @Action(DeleteMessage)
  public async deleteMessage(ctx: StateContext<MessagesStateModel>, { id }: DeleteMessage): Promise<void> {
    await firstValueFrom(
      this.whatsappService.deleteMessage(id).pipe(
        tap((): void => {
          ctx.patchState({ message: null });
          ctx.dispatch(new OpenChatOverview());
        }),
      ),
    );
  }

  @Action(OpenChatOverview)
  public openChatOverview(ctx: StateContext<MessagesStateModel>): void {
    const prefix = this.store.selectSnapshot(EnvironmentState.prefix);
    ctx.dispatch(new Navigate([`/${prefix}/messages`]));
  }

  @Action(OpenChat)
  public openChat(ctx: StateContext<MessagesStateModel>, { addressId }: OpenChat): void {
    const prefix = this.store.selectSnapshot(EnvironmentState.prefix);
    ctx.dispatch(new Navigate([`/${prefix}/messages/${addressId}`]));
    ctx.dispatch(new MarkChatAsRead(addressId));
  }

  @Action(MarkChatAsRead)
  public markChatAsRead(ctx: StateContext<MessagesStateModel>, { addressId }: MarkChatAsRead): Observable<Chat> {
    return this.whatsappService.markChatAsRead(addressId).pipe(tap((chat: Chat) => ctx.patchState({ chat })));
  }

  @Action(EditMessage)
  public editChat(ctx: StateContext<MessagesStateModel>, { message }: EditMessage): void {
    const prefix = this.store.selectSnapshot(EnvironmentState.prefix);
    ctx.dispatch(new Navigate([`/${prefix}/messages/${message.id}/edit`]));
  }
}
