import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { first } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { CompaniesService } from '@vandelft/modules/shared/services/companies.service';
import { Company } from '@vandelft/modules/shared/models';
import { OpenCompaniesList, SaveCompany } from '@vandelft/modules/shared/state/companies';
import { plainToInstance } from 'class-transformer';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'tna-companies-form',
  templateUrl: './form.component.html',
})
export class FormComponent implements OnInit {
  public isBusy = false;
  public companyId: string;

  public companyForm = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    email: new FormControl(null, [Validators.email]),
  });

  public constructor(
    private route: ActivatedRoute,
    private store: Store,
    private companiesService: CompaniesService,
  ) {}

  public async ngOnInit(): Promise<void> {
    const companyId: string = this.route.snapshot.paramMap.get('id');
    this.companyId = companyId || uuid();

    if (companyId) {
      const company: Company = await firstValueFrom(this.companiesService.getCompanyById(companyId).pipe(first()));
      this.companyForm.patchValue(company);
    }
  }

  public onSubmit(): void {
    try {
      this.isBusy = true;
      const company: Company = plainToInstance(Company, {
        ...this.companyForm.value,
        id: this.companyId,
      });
      this.store.dispatch([new SaveCompany(company), new OpenCompaniesList()]);
    } catch (e) {
    } finally {
      this.isBusy = false;
    }
  }
}
