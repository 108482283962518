import { Component } from '@angular/core';
import { CLIENT_TYPES } from '@vandelft/modules/shared/constants';
import { Report, User } from '@vandelft/modules/shared/models';
import { ReportsState, LoadReport } from '@vandelft/modules/shared/state/reports';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { ActivatedRoute } from '@angular/router';
import { AuthState } from '@vandelft/modules/shared/state/auth';
import { EnvironmentState } from '@vandelft/modules/shared/state/environment';
import { CreateWorkOrderDraftAndOpenWorkOrderForm } from '@vandelft/modules/shared/state/work-order-draft';

@Component({
  selector: 'app-reports-view',
  templateUrl: './details.component.html',
})
export class ViewComponent {
  @Select(ReportsState.report)
  public report$: Observable<Report>;

  @Select(AuthState.user)
  public user$: Observable<User>;

  public prefix: string;

  public clientTypeMapping = {};

  public constructor(private route: ActivatedRoute, private store: Store) {
    this.prefix = this.store.selectSnapshot(EnvironmentState.prefix);
    const reportId = this.route.snapshot.paramMap.get('id');

    for (const clientType of CLIENT_TYPES) {
      this.clientTypeMapping[clientType.key] = clientType.label;
    }

    this.store.dispatch([new LoadReport(reportId)]);
  }

  public addWorkOrder(e: Event, report?: Report): boolean {
    e.preventDefault();
    e.stopPropagation();
    this.store.dispatch(new CreateWorkOrderDraftAndOpenWorkOrderForm(report));
    return false;
  }
}
