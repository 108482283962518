import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-timeline-note',
  templateUrl: './timeline-note.component.html',
})
export class TimelineNoteComponent {
  @Input()
  public note: any;

  @Input()
  public apiUrl: any;

  @Input()
  public token: any;
}
