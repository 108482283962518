import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment/moment';
import { stringify } from 'qs';

import { WorkOrder } from '../models';
import { environment } from '../../../../environments/environment';
import { plainToInstance } from 'class-transformer';

interface InvoiceFilters {
  end?: string;
  isPaid?: boolean;
  mechanicId?: string;
  paymentMethod?: string;
  sortDirection?: string;
  start?: string;
  state?: string;
}

@Injectable({
  providedIn: 'root',
})
export class InvoicesService {
  public constructor(private http: HttpClient) {}

  public getQueryString(options?: InvoiceFilters): string {
    const defaultOptions = {
      end: null,
      isPaid: null,
      mechanicId: null,
      paymentMethod: null,
      sortDirection: 'asc',
      start: null,
    };

    const mergedOptions = { ...defaultOptions, ...options };

    if (mergedOptions.start !== null && mergedOptions.start.length > 0) {
      mergedOptions.start = moment(mergedOptions.start).toDate();
    }

    if (mergedOptions.end !== null && mergedOptions.end.length > 0) {
      mergedOptions.end = moment(mergedOptions.end).toDate();
    }

    for (const key of Object.keys(mergedOptions)) {
      const value = mergedOptions[key];
      if (!value) {
        delete mergedOptions[key];
      }
    }

    return stringify(mergedOptions);
  }

  public getInvoices(options?: InvoiceFilters): Observable<WorkOrder[]> {
    const queryString = this.getQueryString(options);
    return this.http
      .get(`${environment.apiUrl}/invoices?${queryString}`)
      .pipe(map((data: WorkOrder[]) => plainToInstance(WorkOrder, data)));
  }

  public getInvoiceById(id: string): Observable<WorkOrder> {
    return this.http
      .get(`${environment.apiUrl}/invoices/${id}`)
      .pipe(map((data: WorkOrder) => plainToInstance(WorkOrder, data)));
  }

  public resendInvoice(workOrder: WorkOrder): Observable<any> {
    return this.http.post(`${environment.apiUrl}/invoices/${workOrder.id}/send`, {});
  }
}
