import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-message',
  styleUrls: ['./message.component.scss'],
  templateUrl: './message.component.html',
})
export class MessageComponent implements OnInit {
  @Input() public content: string;
  @Input() public shortened = false;

  public text: string;

  italicExp: RegExp = /_(.*?)_/g;
  boldExp: RegExp = /\*(.*?)\*/g;
  strikethroughExp: RegExp = /~(.*?)~/g;

  httpExp: RegExp =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&\/=]*)/g;

  tripleQuoteExp: RegExp = /`{3}([\s\S]*?`{3})/g;
  codeExp: RegExp = /`.*?`/g;

  ngOnInit(): void {
    this.text = this.content
      .replace(this.italicExp, '<i>$1</i>')
      .replace(this.boldExp, '<b>$1</b>')
      .replace(this.strikethroughExp, '<s>$1</s>')
      .replace(this.httpExp, '<a href="$&" target="_blank">$&</a>')
      .replace(this.tripleQuoteExp, (match: string): string => {
        return `<code>${match.replace(/```/g, '')}</code>`;
      })
      .replace(this.codeExp, (match: string): string => {
        return `<code>${match.replace(/`/g, '')}</code>`;
      });
  }
}
