import { Note, Report } from '../../models';

export class OpenReportsList {
  public static readonly type = '[Reports] Open reports list';
}

export class OpenReportForm {
  public static readonly type = '[Reports] Open report form';
  public constructor(public report?: Report) {}
}

export class OpenReportDetails {
  public static readonly type = '[Reports] Open report details';
  public constructor(public report?: Report) {}
}

export class OpenReportApproval {
  public static readonly type = '[Reports] Open report approval';
  public constructor(public report?: Report) {}
}

export class SetReport {
  public static readonly type = '[Reports] Set current report';
  public constructor(public report: Report) {}
}

export class LoadReport {
  public static readonly type = '[Reports] Load report';
  public constructor(public id: string) {}
}

export class LoadReportsByMechanic {
  public static readonly type = '[Reports] Load reports by mechanic';
  public constructor(public mechanicId: string) {}
}

export class LoadReportsWithOrderProducts {
  public static readonly type = '[Reports] Load reports with order products';
  public constructor(public date: string) {}
}

export class LoadReportsByMechanics {
  public static readonly type = '[Reports] Load reports by mechanics';
  public constructor(public ids: string[]) {}
}

export class ApproveReport {
  public static readonly type = '[Reports] Approve report';
  public constructor(public report?: Report) {}
}

export class CreateReport {
  public static readonly type = '[Reports] Create report';
  public constructor() {}
}

export class CancelReport {
  public static readonly type = '[Reports] Cancel report';
  public constructor(
    public id: string,
    public reason: string,
  ) {}
}

export class LoadReports {
  public static readonly type = '[Reports] Load reports';
}

export class DeleteReport {
  public static readonly type = '[Reports] Delete report';
  public constructor(public report: Report) {}
}

export class SaveReport {
  public static readonly type = '[Reports] Save report';
  public constructor(public report: Report) {}
}

export class SaveReportTimes {
  public static readonly type = '[Reports] Save report times';
  public constructor(
    public report: Report,
    public data: { onTheGo: string; arrival: string; departure: string },
  ) {}
}

export class AddNoteToReport {
  public static readonly type = '[Reports] Add note to report';
  public constructor(
    public report: Report,
    public note: Note,
  ) {}
}
