import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { jwtDecode } from 'jwt-decode';
import 'reflect-metadata';
import 'zone.js';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
  dsn: 'https://cb8d94bce7724342bca205a613fa7278@o162096.ingest.sentry.io/5465072',
  environment: 'frontend',
  enabled: environment.production,
  maxValueLength: 1000000,
  normalizeDepth: 100,
});

const scope: Sentry.Scope = Sentry.getCurrentScope();

let user = null;
try {
  const token: string = localStorage.getItem('auth.token');
  const decoded = jwtDecode(token) as any;
  user = {
    id: decoded.sub,
    username: decoded.username,
  };
} catch {}

scope.setUser(user).setTag('flavor', environment.production ? 'production' : 'development');

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
