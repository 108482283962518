<form class="form" [formGroup]="addressForm">
  <div class="form-group required">
    <label class="label">Contactpersoon</label>
    <input type="text" class="form-control" formControlName="contact" placeholder="Contactpersoon" />
  </div>

  <div class="row">
    <div class="col col-30">
      <div class="form-group required">
        <label class="label">Postcode</label>
        <input type="text" class="form-control postal-code" formControlName="postalCode" placeholder="1111AA" />
      </div>
    </div>

    <div class="col col-30">
      <div class="form-group required">
        <label class="label">Huisnummer</label>
        <input type="text" class="form-control house-number" formControlName="houseNumber" placeholder="100" />
      </div>
    </div>

    <div class="col col-30">
      <div class="form-group">
        <label class="label">Toevoeging</label>
        <input
          type="text"
          class="form-control house-number-suffix"
          formControlName="houseNumberSuffix"
          placeholder="A" />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col col-48">
      <div class="form-group required">
        <label class="label">Straat</label>
        <input type="text" class="form-control" formControlName="street" placeholder="Straat" />
      </div>
    </div>

    <div class="col col-48">
      <div class="form-group required">
        <label class="label">Plaats</label>
        <input type="text" class="form-control city" formControlName="city" placeholder="Rotterdam" />
      </div>
    </div>
  </div>

  <div class="form-group required">
    <label class="label">Telefoonnummer</label>
    <input type="text" class="form-control" formControlName="phoneNumber" placeholder="010 1234567" />
  </div>

  <div class="form-group">
    <label class="label">Telefoonnummer 2</label>
    <input type="text" class="form-control" formControlName="phoneNumber2" placeholder="010 1234567" />
  </div>

  <div class="form-group required" *ngIf="!isCompany">
    <label class="label">Email</label>
    <input
      type="text"
      class="form-control"
      formControlName="email"
      autocorrect="off"
      autocapitalize="none"
      placeholder="info@vandelftsloten.nl" />
  </div>

  <ng-container *ngIf="isCompany">
    <input type="hidden" formControlName="email" />
  </ng-container>
</form>
