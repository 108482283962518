export const CLIENT_TYPES = [
  {
    key: 'private',
    label: 'Particulier',
  },
  {
    key: 'company',
    label: 'Zakelijk',
  },
];

export const PAYMENT_METHODS = [
  {
    key: 'pin',
    label: 'Pin',
  },
  {
    key: 'cash',
    label: 'Contant',
  },
];

export const MATERIAL_OPTIONS = [
  {
    value: 'wood',
    label: 'Hout',
  },
  {
    value: 'synthetic',
    label: 'Kunstof',
  },
  {
    value: 'aluminium',
    label: 'Aluminium',
  },
  {
    value: 'steel',
    label: 'Staal',
  },
];

export const DOOR_TYPE_OPTIONS = [
  {
    value: 'single',
    label: 'Enkel',
  },
  {
    value: 'double',
    label: 'Dubbel',
  },
];

export const YES_NO_OPTIONS = [
  {
    value: true,
    label: 'Ja',
  },
  {
    value: false,
    label: 'Nee',
  },
];

export const BACKSET_LOCK_OPTIONS = [
  {
    value: 25,
    label: '25mm',
  },
  {
    value: 30,
    label: '30mm',
  },
  {
    value: 35,
    label: '35mm',
  },
  {
    value: 40,
    label: '40mm',
  },
  {
    value: 45,
    label: '45mm',
  },
  {
    value: 50,
    label: '50mm',
  },
  {
    value: 55,
    label: '55mm',
  },
  {
    value: 60,
    label: '60mm',
  },
];

export const SPACING_LOCK_OPTIONS = [
  {
    value: 55,
    label: 'PC 55',
  },
  {
    value: 70,
    label: 'PC 70',
  },
  {
    value: 72,
    label: 'PC 72',
  },
  {
    value: 85,
    label: 'PC 85',
  },
  {
    value: 92,
    label: 'PC 92',
  },
];
