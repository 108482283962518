import { Report, WorkOrder } from '../../models';

export class CreateWorkOrderDraftAndOpenWorkOrderForm {
  public static readonly type = '[WorkOrderDraft] Create work order draft and open work order form';
  public constructor(public report: Report) {}
}

export class CreateWorkOrderDraftFromExistingWorkOrder {
  public static readonly type = '[WorkOrderDraft] Create work order draft from existing work order';
  public constructor(public workOrder: WorkOrder) {}
}

export class LoadWorkOrderDraftById {
  public static readonly type = '[WorkOrderDraft] Load workOrder';
  public constructor(public id: string) {}
}

export class OpenDraftClientTypeForm {
  public static readonly type = '[WorkOrderDraft] Open client type form';
}

export class OpenDraftClientInfoForm {
  public static readonly type = '[WorkOrderDraft] Open client info form';
}

export class OpenDraftActivitiesForm {
  public static readonly type = '[WorkOrderDraft] Open activities form';
}

export class OpenDraftOrderProductForm {
  public static readonly type = '[WorkOrderDraft] Open order product form';
}

export class OpenDraftCompletionForm {
  public static readonly type = '[WorkOrderDraft] Open completion form';
}

export class SaveWorkOrderDraft {
  public static readonly type = '[WorkOrderDraft] Save work order draft';
  public constructor(public workOrder: WorkOrder) {}
}

export class PatchWorkOrderDraft {
  public static readonly type = '[WorkOrderDraft] Patch work order draft';
  public constructor(public data: any) {}
}

export class CompleteWorkOrderDraft {
  public static readonly type = '[WorkOrderDraft] Complete work order draft';
  public constructor(public workOrder: WorkOrder) {}
}
