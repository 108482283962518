<form class="form" id="loginForm" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
  <div class="header">Wachtwoord vergeten</div>
  <div class="body">
    <div class="form-group">
      <label>E-mailadres</label>
      <input
        type="text"
        id="username"
        class="form-control"
        formControlName="email"
        autocorrect="off"
        autocapitalize="none"
        placeholder="E-mailadres" />
    </div>
  </div>

  <div class="footer text-center">
    <button type="submit" class="btn btn-primary btn-block mb-1" [disabled]="forgotPasswordForm.invalid || isBusy">
      <span *ngIf="!isBusy" class="text">Verstuur</span>
      <span *ngIf="isBusy" class="loader"><img src="assets/img/loader-white.svg" /></span>
    </button>
  </div>
</form>
