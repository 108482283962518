import { Component, OnInit } from '@angular/core';

import {
  OpenDraftClientInfoForm,
  PatchWorkOrderDraft,
  SaveWorkOrderDraft,
  WorkOrderDraftState,
} from '@vandelft/modules/shared/state/work-order-draft';
import { DraftBaseFormComponent } from '@vandelft/modules/shared/components/work-orders/draft-base-form';
import { FormControl, FormGroup } from '@angular/forms';
import { SetProgress } from '@vandelft/modules/shared/state/progress';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-draft-client-type-form',
  templateUrl: './draft-client-type-form.component.html',
})
export class DraftClientTypeFormComponent extends DraftBaseFormComponent implements OnInit {
  public newWorkOrderForm = new FormGroup({
    type: new FormControl(),
  });

  public ngOnInit(): void {
    this.store.dispatch(new SetProgress(1, 5));
  }

  public async setType(type: string): Promise<any> {
    const data = {
      report: {
        client: {
          type,
        },
      },
    };

    await firstValueFrom(this.store.dispatch(new PatchWorkOrderDraft(data)));
    const workOrderDraft = this.store.selectSnapshot(WorkOrderDraftState.workOrder);
    await firstValueFrom(this.store.dispatch(new SaveWorkOrderDraft(workOrderDraft)));
    return this.store.dispatch(new OpenDraftClientInfoForm());
  }
}
