import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { LocalStoreService } from '@vandelft/modules/shared/services/local-store.service';
import { AppEventsService } from '@vandelft/modules/shared/services/app-events.service';

import { ProcessUploadQueue } from './sync.actions';
import { SyncStateModel } from './sync.state-model';

import { instanceToPlain } from 'class-transformer';
import { RefreshUserDepositItem } from '../deposit';
import { captureExceptionInSentry } from 'src/sentry-capturer';
import { v4 } from 'uuid';
import { Image, LocalImage } from '../../models';
import { firstValueFrom } from 'rxjs';

@State<SyncStateModel>({
  name: 'sync',
  defaults: {
    isRunning: false,
    queueSize: 0,
  },
})
@Injectable()
export class SyncState implements NgxsOnInit {
  public constructor(
    private store: Store,
    private localStore: LocalStoreService,
    private appEventsService: AppEventsService,
  ) {}

  @Selector()
  static isRunning(state: SyncStateModel): boolean {
    return state.isRunning;
  }

  @Selector()
  static queueSize(state: SyncStateModel): number {
    return state.queueSize;
  }

  public async ngxsOnInit(ctx: StateContext<SyncStateModel>): Promise<void> {
    const workOrders = await this.localStore.getWorkOrders();
    ctx.patchState({
      queueSize: workOrders?.length || 0,
    });
  }

  @Action(ProcessUploadQueue)
  public async processUploadQueue(ctx: StateContext<SyncStateModel>): Promise<void> {
    const isRunning = this.store.selectSnapshot(SyncState.isRunning);

    if (isRunning) {
      return;
    }

    ctx.patchState({ isRunning: true });
    const workOrders = await this.localStore.getWorkOrders();

    ctx.patchState({ queueSize: workOrders?.length || 0 });

    let data = null;
    for (const workOrder of workOrders) {
      try {
        data = instanceToPlain(workOrder);

        if (!workOrder.isEdit && data.localClientSignature) {
          await firstValueFrom(
            this.appEventsService.save({
              event: 'clientSignature.saved',
              shouldWait: true,
              payload: {
                id: v4(),
                workOrderId: data.id,
                data: data.localClientSignature,
              },
            }),
          );
        }

        if (!workOrder.isEdit && data.localMechanicSignature) {
          await firstValueFrom(
            this.appEventsService.save({
              event: 'mechanicSignature.saved',
              shouldWait: true,
              payload: {
                id: v4(),
                workOrderId: data.id,
                data: data.localMechanicSignature,
              },
            }),
          );
        }

        for (const image of workOrder.images) {
          if (image instanceof LocalImage) {
            await firstValueFrom(
              this.appEventsService.save({
                event: 'workOrderImage.saved',
                payload: { workOrderId: data.id, ...image },
              }),
            );
          }

          if (image instanceof Image && !!image.deleted) {
            await firstValueFrom(
              this.appEventsService.save({
                event: 'workOrderImage.deleted',
                payload: { workOrderId: data.id, ...image },
              }),
            );
          }
        }

        for (const image of workOrder.order?.images ?? []) {
          if (image instanceof LocalImage) {
            await firstValueFrom(
              this.appEventsService.save({
                event: `${image.fileType}.saved`,
                payload: {
                  workOrderId: data.id,
                  orderId: data?.order?.id,
                  ...image,
                },
              }),
            );
          }

          if (image instanceof Image && !!image.deleted) {
            await firstValueFrom(
              this.appEventsService.save({
                event: `${image.fileType}.deleted`,
                payload: {
                  workOrderId: data.id,
                  orderId: data?.order?.id,
                  ...image,
                },
              }),
            );
          }
        }

        delete data.images;
        delete data.certificate;
        delete data.productImage;
        delete data.report.workOrders;
        delete data.report.images;
        delete data.isNew;
        delete data.isEdit;

        if (data?.order?.images) {
          delete data.order.images;
        }

        await firstValueFrom(this.appEventsService.save({ event: 'workOrder.saved', shouldWait: true, payload: data }));
        await this.localStore.deleteWorkOrder(workOrder);
      } catch (e) {
        captureExceptionInSentry(e);
      }
    }

    const updatedWorkOrders = await this.localStore.getWorkOrders();
    ctx.patchState({
      isRunning: false,
      queueSize: updatedWorkOrders?.length || 0,
    });
    this.store.dispatch([new RefreshUserDepositItem()]);
  }
}
