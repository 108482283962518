<form class="form p-2" [formGroup]="productForm" onkeydown="return event.key !== 'Enter';" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <div class="modal-title">
      <h1>Product toevoegen</h1>
    </div>
  </div>
  <div class="modal-body">
    <div class="form-group required">
      <label class="label">Omschrijving</label>
      <input type="text" #description class="form-control" formControlName="description" />
      <small class="product-hint py-1" *ngIf="product?.articleNumber">
        Dit is een bestaand product met artikelnummer: {{ product?.articleNumber }}
      </small>
      <ng-container *ngIf="suggestions$ | async as suggestions">
        <div class="suggestions" *ngIf="suggestions?.length > 0">
          <ul>
            <li
              *ngFor="let suggestion of suggestions"
              (click)="setProductDetails($event, suggestion)"
              [ngClass]="{ selected: suggestion == selectedSuggestion }">
              {{ suggestion.articleNumber }} - {{ suggestion.description }}
            </li>
          </ul>
        </div>
      </ng-container>
    </div>

    <div class="form-group required">
      <label class="label">Aantal</label>
      <input type="number" class="form-control" formControlName="quantity" />
    </div>

    <div class="form-group required" *ngIf="product?.onLoan">
      <label class="label">In bruikleen</label>
      <select class="form-control" #onLoan formControlName="onLoan">
        <option [ngValue]="false">Nee</option>
        <option [ngValue]="true">Ja</option>
      </select>
    </div>

    <div class="form-group" *ngIf="!isCompany && productForm?.value?.onLoan !== true">
      <label class="label">Prijs nader te bepalen?</label>
      <input type="checkbox" formControlName="priceLater" />
    </div>

    <div class="form-group" *ngIf="!isCompany && !productForm?.value?.onLoan && !productForm.get('priceLater').value">
      <label class="label">Prijs (incl. BTW)</label>
      <input type="number" step="0.01" min="0" class="form-control" formControlName="price" placeholder="Prijs" />
    </div>

    <div class="form-group required">
      <label class="label">Opmerkingen</label>
      <textarea class="form-control" formControlName="remarks" rows="3"></textarea>
    </div>

    <ng-container *ngIf="product?.images?.length > 0">
      <ng-container *ngIf="token$ | async as token">
        <h3 class="mt-2">Afbeeldingen</h3>
        <div class="images mt-2">
          <a
            class="img"
            [href]="apiUrl + '/images/' + img.id + '?token=' + token"
            target="_blank"
            *ngFor="let img of product.images">
            <img [src]="apiUrl + '/images/' + img.id + '?token=' + token" [alt]="img.model + ' image'" />
          </a>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div class="modal-footer">
    <div class="actions">
      <a class="btn btn-secondary" (click)="onCancel()">Annuleren</a>
      <button type="submit" class="btn btn-primary ml-1">OK</button>
    </div>
  </div>
</form>
