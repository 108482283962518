import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map, switchMap, tap, toArray } from 'rxjs/operators';
import * as moment from 'moment/moment';
import { stringify } from 'qs';

import { Report, User } from '../models';
import { environment } from '../../../../environments/environment';
import { plainToInstance } from 'class-transformer';
import { transformModel } from '../rxjs/operators/transform-model';

interface ReportFilters {
  end?: string;
  mechanicId?: string;
  start?: string;
  state?: string;
  city?: string;
  street?: string;
  houseNumber?: string;
  postalCode?: string;
  executed?: string;
  number?: string;
  companyId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  public constructor(private http: HttpClient) {}

  public getQueryString(options?: ReportFilters): string {
    const defaultOptions = {
      end: null,
      mechanicId: null,
      start: null,
      state: null,
      city: null,
      street: null,
      houseNumber: null,
      postalCode: null,
      executed: null,
      number: null,
      companyId: null,
      isSearch: true,
    };

    const mergedOptions = { ...defaultOptions, ...options };

    if (mergedOptions.start !== null && mergedOptions.start.length > 0) {
      mergedOptions.start = moment(mergedOptions.start).toDate();
    }

    if (mergedOptions.end !== null && mergedOptions.end.length > 0) {
      mergedOptions.end = moment(mergedOptions.end).toDate();
    }

    if (mergedOptions.executed !== null && mergedOptions.executed.length > 0) {
      mergedOptions.executed = moment(mergedOptions.executed).toDate();
    }

    for (const key of Object.keys(mergedOptions)) {
      const value = mergedOptions[key];
      if (!value) {
        delete mergedOptions[key];
      }
    }

    return stringify(mergedOptions);
  }

  public getReports(options?: ReportFilters): Observable<Report[]> {
    const queryString = this.getQueryString(options);
    return this.http.get(`${environment.apiUrl}/reports?${queryString}`).pipe(
      switchMap((data: Report[]) => from(data)),
      transformModel<Report>(Report),
      toArray(),
    );
  }

  public getReportsWithOrderProducts(date: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/reports/order-products?date=${date}`).pipe(
      switchMap((data: any) => from(data)),
      map((data: any) => {
        return {
          mechanic: plainToInstance(User, data.mechanic),
          reports: plainToInstance(Report, data.reports),
        };
      }),
      toArray(),
    );
  }

  public cancelReport(id: string, reason: string): Observable<Report> {
    return this.http
      .put<Report>(`${environment.apiUrl}/reports/${id}/cancel`, { reason })
      .pipe(map((data: Report) => plainToInstance(Report, data)));
  }

  public getFeedbackReports(): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/reports/feedback`)
      .pipe(map((data: Report[]) => plainToInstance(Report, data)));
  }

  public getReportById(id: string): Observable<Report> {
    return this.http.get(`${environment.apiUrl}/reports/${id}`).pipe(transformModel<Report>(Report));
  }

  public getReportsByMechanic(mechanicId: string): Observable<Report[]> {
    return this.http
      .get(`${environment.apiUrl}/reports/mechanic/${mechanicId}`)
      .pipe(map((data: Report[]) => plainToInstance(Report, data)));
  }
}
