import { Injectable } from '@angular/core';
import { State, Action, StateContext, Store, Selector } from '@ngxs/store';

import { ShowError, RemoveOldestError } from './error.actions';

export interface ErrorStateModel {
  errors: Array<{ message: string; statusCode?: string }>;
}

@State<ErrorStateModel>({
  name: 'error',
  defaults: {
    errors: [],
  },
})
@Injectable()
export class ErrorState {
  public constructor(private store: Store) {}

  @Selector()
  static errors(state: ErrorStateModel): any {
    return state.errors;
  }

  @Action(ShowError)
  public showError(ctx: StateContext<ErrorStateModel>, action: ShowError): any {
    const state = ctx.getState();

    const error = { message: action.message, statusCode: action.statusCode };
    const errors = [...state.errors, error];
    ctx.patchState({ errors });

    setTimeout(() => {
      ctx.dispatch(new RemoveOldestError());
    }, 5000);
  }

  @Action(RemoveOldestError)
  public removeOldestError(ctx: StateContext<ErrorStateModel>): any {
    const state = ctx.getState();
    const errors = [...state.errors];

    if (errors.length > 0) {
      errors.shift();
      ctx.patchState({ errors });
    }
  }
}
