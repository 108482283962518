import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { v4 as uuid } from 'uuid';

import {
  BACKSET_LOCK_OPTIONS,
  DOOR_TYPE_OPTIONS,
  MATERIAL_OPTIONS,
  SPACING_LOCK_OPTIONS,
  YES_NO_OPTIONS,
} from '@vandelft/modules/shared/constants';
import { Product, WorkOrder } from '@vandelft/modules/shared/models';
import { DraftBaseFormComponent } from '../draft-base-form';
import {
  OpenDraftCompletionForm,
  PatchWorkOrderDraft,
  SaveWorkOrderDraft,
  WorkOrderDraftState,
} from '@vandelft/modules/shared/state/work-order-draft';
import { SetProgress } from '@vandelft/modules/shared/state/progress';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { ModalRef, ModalService } from '@vandelft/modules/shared/services/modal.service';
import { ProductFormModalComponent } from '../product-form-modal';
import { ConfirmModalComponent } from '../../ui';
import { IQuantifiedProduct } from '@vandelft/shared/interfaces';
import { DepositHelper, QuantifiedProductHelper } from '@vandelft/shared/helpers';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-draft-product-order-form',
  templateUrl: './draft-product-order-form.component.html',
})
export class DraftProductOrderFormComponent extends DraftBaseFormComponent implements OnInit {
  public isCompany = false;
  public minDeposit: number;
  public maxDeposit: number;
  public form = new FormGroup({
    id: new FormControl(uuid()),
    deposit: new FormControl(0),
    takenProduct: new FormControl(null),
    doorMaterial: new FormControl(null, [Validators.required]),
    doorType: new FormControl(null, [Validators.required]),
    frameMaterial: new FormControl(null, [Validators.required]),
    cylinderOperated: new FormControl(null, [Validators.required]),
    backsetLock: new FormControl(null, [Validators.required]),
    spacingLock: new FormControl(null, [Validators.required]),
    keyRepeatOrder: new FormControl(null, [Validators.required]),
    cylinder: new FormControl(null, [Validators.required]),
    cylinderBrand: new FormControl(null),
    cylinderNumber: new FormControl(null),
    certificates: new FormControl([]),
    productImages: new FormControl([]),
    remarks: new FormControl(null),
    orderLines: new FormArray([]),
  });

  public product: Product;

  public materialOptions = MATERIAL_OPTIONS;
  public doorTypeOptions = DOOR_TYPE_OPTIONS;
  public yesNoOptions = YES_NO_OPTIONS;
  public backsetLockOptions = BACKSET_LOCK_OPTIONS;
  public spacingLockOptions = SPACING_LOCK_OPTIONS;

  constructor(
    protected route: ActivatedRoute,
    protected store: Store,
    private modalService: ModalService,
  ) {
    super(route, store);
  }

  public ngOnInit(): void {
    this.store.dispatch(new SetProgress(3, 4));

    this.form.valueChanges.subscribe((value) => {
      const formControl = this.form.get('certificates');
      if (value.keyRepeatOrder || value.cylinder) {
        formControl.setValidators([Validators.required]);
        formControl.updateValueAndValidity({ emitEvent: false });
        return;
      }

      formControl.clearValidators();
      formControl.updateValueAndValidity({ emitEvent: false });
    });
  }

  private updateDeposit(deposit?: number): void {
    const calculatedDeposit = DepositHelper.getDeposit(this.form.value.orderLines as IQuantifiedProduct[]);
    this.form.patchValue({
      deposit: deposit ?? calculatedDeposit,
    });
    this.minDeposit = calculatedDeposit;
    this.maxDeposit = QuantifiedProductHelper.getTotalPriceInc(this.form.value.orderLines);

    const depositControl = this.form.get('deposit');
    depositControl.setValidators([Validators.min(this.minDeposit), Validators.max(this.maxDeposit)]);

    this.form.patchValue({ deposit: calculatedDeposit });
    depositControl.updateValueAndValidity();
  }

  public initForm(workOrder: WorkOrder): void {
    if (!workOrder || !this.form) {
      return;
    }

    this.orderLines.clear();
    this.isCompany = workOrder.report.client.type === 'company';

    this.form.patchValue({
      ...workOrder.order,
      deposit: workOrder.effectiveDeposit,
      cylinderOperated: (workOrder.order?.cylinderOperated as any) === 1,
      keyRepeatOrder: (workOrder.order?.keyRepeatOrder as any) === 1,
      cylinder: (workOrder.order?.cylinder as any) === 1,
      certificates: (workOrder.order?.images as any[])?.filter((i) => i.fileType === 'certificate'),
      productImages: (workOrder.order?.images as any[])?.filter((i) => i.fileType === 'productImage'),
    });

    if (workOrder.order?.orderLines) {
      for (const orderLine of workOrder.order.orderLines) {
        this.addOrderLine(orderLine);
      }
    }

    this.updateDeposit(workOrder.effectiveDeposit);
  }

  public async onSubmit(): Promise<any> {
    const formData = {
      ...this.form.value,
      images: (this.form.value.certificates ?? []).concat(this.form.value.productImages ?? []),
    };

    const data = {
      order: formData,
    };

    await firstValueFrom(this.store.dispatch(new PatchWorkOrderDraft(data)));
    const workOrderDraft: WorkOrder = this.store.selectSnapshot(WorkOrderDraftState.workOrder);
    await firstValueFrom(this.store.dispatch(new SaveWorkOrderDraft(workOrderDraft)));
    return this.store.dispatch(new OpenDraftCompletionForm());
  }

  public get orderLines(): FormArray {
    return this.form.get('orderLines') as FormArray;
  }

  public async openProductModal(index = null): Promise<void> {
    const formGroup: AbstractControl = this.orderLines.controls[index];
    const modalRef: ModalRef = this.modalService.open(ProductFormModalComponent);
    modalRef.componentInstance.isCompany = this.isCompany;

    if (formGroup) {
      modalRef.componentInstance.product = {
        ...formGroup.value,
        id: formGroup.get('productId').value,
      };
    }

    const result = await modalRef.result;

    if (!result) {
      return;
    }

    if (result.priceLater) {
      result.price = null;
    }

    delete result.priceLater;

    if (formGroup) {
      formGroup.patchValue({
        ...result,
        productId: result.id,
        id: formGroup.get('id').value,
      });

      this.updateDeposit();
      return;
    }

    this.addOrderLine({
      ...result,
      productId: result.id,
      id: uuid(),
    });
    this.updateDeposit();
  }

  public addOrderLine(product: IQuantifiedProduct): void {
    const formGroup = new FormGroup({
      id: new FormControl(product.id || uuid()),
      productId: new FormControl(product.productId),
      articleNumber: new FormControl(product.articleNumber),
      price: new FormControl(product.price),
      description: new FormControl(product.description, [Validators.required]),
      quantity: new FormControl(product.quantity, [Validators.required]),
      remarks: new FormControl(product.remarks),
    });

    this.orderLines.push(formGroup);
  }

  public async deleteOrderLine(e: Event, orderLine: AbstractControl): Promise<void> {
    e.preventDefault();
    e.stopPropagation();

    if (this.modalService.activeModal) {
      this.modalService.close();
    }

    const modalRef = this.modalService.open(ConfirmModalComponent, {
      modalType: 'modal-floating-confirmation',
      barrier: false,
      alignment: {
        element: e.target,
      },
    });

    modalRef.componentInstance.message = `Weet u zeker dat u ${orderLine.value.description} wilt verwijderen?`;

    const result = await modalRef.result;

    if (!result) {
      return;
    }

    const index = this.orderLines.controls.indexOf(orderLine);
    this.orderLines.removeAt(index);
    this.updateDeposit();
  }
}
