<app-page-header title="Voorraad"></app-page-header>

<div class="container">
  <div class="card">
    <div class="body">
      <ng-container *ngIf="stockItems$ | async as stockItems; else loading">
        <ul class="list">
          <li *ngFor="let stockItem of stockItems" class="py-1">
            <a (click)="open(stockItem)">
              <div class="details">
                <div class="bold">{{ stockItem.mechanic.fullName }}</div>
                <div class="muted">Gebruikt: {{ stockItem.totalProductQuantity }} producten</div>
                <div class="muted">
                  Laatst aangevuld:
                  <ng-container *ngIf="stockItem.mechanic.lastRefill">
                    {{ stockItem.mechanic.lastRefill | date: 'dd-MM-yyyy HH:mm' }}
                  </ng-container>
                  <ng-container *ngIf="!stockItem.mechanic.lastRefill">Nooit</ng-container>
                </div>
              </div>
            </a>
          </li>
        </ul>

        <div class="py-2" *ngIf="stockItems?.length === 0">Alle monteurs hebben voldoende voorraad</div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="loader">
    <img class="loader" src="assets/img/loader-primary.svg" />
  </div>
</ng-template>
