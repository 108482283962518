import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthState } from '@vandelft/modules/shared/state/auth';
import { environment } from '@vandelft/environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
})
export class DetailComponent implements OnInit {
  @Select(AuthState.token)
  public token$: Observable<string>;

  public apiUrl = environment.apiUrl;

  public mechanicId: string;
  public date: string;

  constructor(private route: ActivatedRoute) {
    this.date = this.route.snapshot.queryParams.date ?? moment().format('YYYY-MM-DD');
  }

  async ngOnInit(): Promise<void> {
    this.mechanicId = this.route.snapshot.paramMap.get('id');
  }
}
