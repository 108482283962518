import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { Report, User } from '@vandelft/modules/shared/models';
import { Select, Store } from '@ngxs/store';
import { OpenOrderedProductsForm } from '@vandelft/modules/shared/state/ordered-products';
import { ReportsState, LoadReportsWithOrderProducts } from '@vandelft/modules/shared/state/reports';
import { FormGroup, FormControl } from '@angular/forms';
import { switchMap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
})
export class ListComponent {
  @Select(ReportsState.reports)
  public reports$: Observable<Report[]>;

  public filterForm = new FormGroup({
    date: new FormControl(null),
  });

  constructor(private router: Router, private route: ActivatedRoute, private store: Store) {
    this.filterForm.valueChanges
      .pipe(switchMap((value: any) => this.store.dispatch([new LoadReportsWithOrderProducts(value.date)])))
      .subscribe();

    const date = this.route.snapshot.queryParams.date ?? moment().format('YYYY-MM-DD');
    this.filterForm.patchValue({ date });
    this.filterForm.valueChanges.subscribe((data) => {
      this.updateRoute(data);
    });
  }

  public updateRoute(data: any): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: data,
    });
  }

  public open(mechanic: User): void {
    this.store.dispatch(new OpenOrderedProductsForm(this.filterForm.value.date, mechanic));
  }
}
