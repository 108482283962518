import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { WorkOrder } from '@vandelft/modules/shared/models';
import { WorkOrdersService } from '@vandelft/modules/shared/services/work-orders.service';
import { plainToInstance } from 'class-transformer';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
})
export class FormComponent implements OnInit {
  public isBusy = false;
  public workOrderId: string;
  public activeTab: { key: string; label: string };
  public tabs = [
    {
      key: 'order',
      label: 'Opdracht',
    },
    {
      key: 'activities',
      label: 'Werkzaamheden',
    },
    {
      key: 'completion',
      label: 'Afronding',
    },
  ];

  public workOrderForm = new FormGroup({
    order: new FormControl(null, [Validators.required]),
    activities: new FormControl(null, [Validators.required]),
    completion: new FormControl(null, [Validators.required]),
    state: new FormControl('open', [Validators.required]),
    isPaid: new FormControl(false),
  });

  public constructor(
    private route: ActivatedRoute,
    private router: Router,
    private workOrdersService: WorkOrdersService,
  ) {
    this.activeTab = this.tabs[0];
  }

  public activateTab(tab: { key: string; label: string }): void {
    this.activeTab = tab;
  }

  public async ngOnInit(): Promise<void> {
    const workOrderId = this.route.snapshot.paramMap.get('id');
    this.workOrderId = workOrderId || uuid();

    if (workOrderId) {
      const workOrder: WorkOrder = await firstValueFrom(
        this.workOrdersService.getWorkOrderById(workOrderId).pipe(first()),
      );

      if (workOrder) {
        this.workOrderForm.patchValue(workOrder);
      }
    }
  }

  public async onCompletion(): Promise<void> {
    this.workOrderForm.get('state').setValue('completed');
    await this.onSubmit();
  }

  public async onSubmit(): Promise<void> {
    try {
      this.isBusy = true;

      const workOrder: WorkOrder = plainToInstance(
        WorkOrder,
        {
          ...this.workOrderForm.value,
          id: this.workOrderId,
        },
        { groups: ['related'] },
      );

      // await firstValueFrom(this.workOrdersService.save(workOrder, ));
      await this.router.navigate(['/work-orders']);
    } catch (e) {
      console.error(e);
    } finally {
      this.isBusy = false;
    }
  }
}
