import { IMessageTemplate } from '../../../shared/interfaces';
import { MessageTemplateComponent } from '@vandelft/modules/shared/models/message-template-component';

export class MessageTemplate implements IMessageTemplate {
  category: string;
  components: MessageTemplateComponent[];
  id: string;
  language: string;
  name: string;
  status: string;

  public get bodyText(): string {
    return this.components.find((component: MessageTemplateComponent): boolean => component.type === 'BODY').text;
  }

  public get buttons(): MessageTemplateComponent[] {
    return this.components.filter((component: MessageTemplateComponent): boolean => component.actions);
  }

  created: Date;
  updated?: Date;
  deleted?: Date;
}
