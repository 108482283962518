import { User } from '../../models';

export class OpenOrderedProductsList {
  public static readonly type = '[Ordered Products] Open ordered products list';
}

export class OpenOrderedProductsForm {
  public static readonly type = '[Ordered Products] Open ordered product form';
  public constructor(public date: string, public mechanic: User) {}
}
