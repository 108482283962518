import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { CompaniesStateModel } from '@vandelft/modules/shared/state/companies/companies.state-model';
import { CompaniesService } from '@vandelft/modules/shared/services/companies.service';

import { LoadCompanies, OpenCompanyForm, DeleteCompany, OpenCompaniesList, SaveCompany } from './companies.actions';
import { Company } from '../../models';
import { EnvironmentState } from '../environment';
import { Navigate } from '@ngxs/router-plugin';
import { AppEventsService } from '../../services/app-events.service';
import { patch, updateItem, removeItem } from '@ngxs/store/operators';

@State<CompaniesStateModel>({
  name: 'companies',
  defaults: {
    companies: [],
  },
})
@Injectable()
export class CompaniesState {
  public constructor(
    private store: Store,
    private appEventsService: AppEventsService,
    private companiesService: CompaniesService,
  ) {}

  @Selector()
  static companies(state: CompaniesStateModel): Array<Company> {
    return state.companies;
  }

  @Action(OpenCompaniesList)
  public openUsersList(_: StateContext<CompaniesStateModel>): Observable<any> {
    const prefix = this.store.selectSnapshot(EnvironmentState.prefix);
    return this.store.dispatch(new Navigate([`/${prefix}/companies`]));
  }

  @Action(LoadCompanies)
  public loadCompanies(ctx: StateContext<CompaniesStateModel>): Observable<Array<Company>> {
    return this.companiesService.getCompanies().pipe(tap((companies: Array<Company>) => ctx.patchState({ companies })));
  }

  @Action(OpenCompanyForm)
  public openCompanyForm(_: StateContext<CompaniesStateModel>, { company }: OpenCompanyForm): Observable<any> {
    const prefix = this.store.selectSnapshot(EnvironmentState.prefix);

    if (company) {
      return this.store.dispatch(new Navigate([`/${prefix}/companies/${company.id}`]));
    }

    return this.store.dispatch(new Navigate([`/${prefix}/companies/add`]));
  }

  @Action(DeleteCompany)
  public deleteCompany(ctx: StateContext<CompaniesStateModel>, { company }: DeleteCompany): Observable<any> {
    return this.appEventsService
      .save({
        event: 'company.deleted',
        payload: company,
      })
      .pipe(
        tap((appEvent: any) =>
          ctx.setState(
            patch({
              companies: removeItem<Company>((c) => c.id === appEvent.payload.id),
            }),
          ),
        ),
      );
  }

  @Action(SaveCompany)
  public saveCompany(ctx: StateContext<CompaniesStateModel>, { company }: SaveCompany): Observable<any> {
    return this.appEventsService
      .save({
        event: 'company.saved',
        payload: company,
      })
      .pipe(
        tap((appEvent: any) =>
          ctx.setState(
            patch({
              companies: updateItem<Company>((c) => c.id === appEvent.payload.id, appEvent.payload),
            }),
          ),
        ),
      );
  }
}
