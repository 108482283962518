import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DepositItem } from '@vandelft/modules/shared/models';
import { Select, Store } from '@ngxs/store';
import { DepositState, LoadMechanicDepositItem, RegisterDeposit } from '@vandelft/modules/shared/state/deposit';
import { ConfirmModalComponent } from '@vandelft/modules/shared/components/ui';
import { ModalService } from '@vandelft/modules/shared/services/modal.service';
import { AuthState } from '@vandelft/modules/shared/state/auth';
import { environment } from '@vandelft/environments/environment';

@Component({
  selector: 'app-deposit-detail',
  templateUrl: './detail.component.html',
})
export class DetailComponent {
  @Select(DepositState.mechanicDepositItem)
  public depositItem$: Observable<DepositItem>;

  @Select(AuthState.token)
  public token$: Observable<string>;

  public apiUrl = environment.apiUrl;

  public constructor(private route: ActivatedRoute, private store: Store, private modalService: ModalService) {
    const mechanicId = this.route.snapshot.paramMap?.get('id');

    this.store.dispatch(new LoadMechanicDepositItem(mechanicId));
  }

  public async registerDeposit(e: Event, depositItem: DepositItem): Promise<void> {
    e.preventDefault();
    e.stopPropagation();

    const modalRef = this.modalService.open(ConfirmModalComponent, {
      modalType: 'modal-floating-confirmation',
      barrier: false,
      alignment: {
        element: e.target,
      },
    });

    modalRef.componentInstance.message = `Heeft ${depositItem.mechanic.fullName} € ${depositItem.totalAmount} afgeletterd?`;
    const result = await modalRef.result;

    if (!result) {
      return;
    }

    this.store.dispatch([new RegisterDeposit(depositItem)]);
  }
}
