import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import * as moment from 'moment';

import { environment } from '@vandelft/environments/environment';
import { Company, User, WorkOrder } from '@vandelft/modules/shared/models';
import {
  ExternalInvoicesState,
  LoadExternalInvoices,
  ProcessExternalInvoices,
} from '@vandelft/modules/shared/state/external-invoices';
import { AuthState } from '@vandelft/modules/shared/state/auth';
import { UsersState } from '@vandelft/modules/shared/state/users';
import { startWith, switchMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ExternalInvoicesService } from '../../../services/external-invoices.service';
import { CompaniesState } from '@vandelft/modules/shared/state/companies';

@Component({
  selector: 'app-list',
  styleUrls: ['list.component.scss'],
  templateUrl: 'list.component.html',
})
export class ListComponent implements OnInit {
  @Select(ExternalInvoicesState.workOrders)
  public workOrders$: Observable<WorkOrder[]>;

  @Select(CompaniesState.companies)
  public companies$: Observable<Company[]>;

  @Select(AuthState.token)
  public token$: Observable<string>;

  @Select(UsersState.mechanics)
  public mechanics$: Observable<User[]>;

  public apiUrl = environment.apiUrl;

  public isBusy = false;

  public filterForm = new FormGroup({
    start: new FormControl(moment().format('YYYY-MM-DD')),
    end: new FormControl(moment().format('YYYY-MM-DD')),
    companyId: new FormControl(null),
  });

  public filterQs$ = new BehaviorSubject(null);

  public externalInvoicesForm = new FormGroup({
    externalInvoices: new FormArray([]),
  });

  public constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private externalInvoicesService: ExternalInvoicesService,
  ) {}

  public async updateRoute(data: any): Promise<void> {
    await this.router.navigate([], {
      relativeTo: this.route,
      queryParams: data,
    });
  }

  public get externalInvoices(): FormArray {
    return this.externalInvoicesForm.get('externalInvoices') as FormArray;
  }

  public async ngOnInit(): Promise<void> {
    this.filterForm.patchValue(this.route.snapshot.queryParams);

    this.filterForm.valueChanges.pipe(startWith(this.filterForm.value)).subscribe(async (data: any): Promise<void> => {
      await this.updateRoute(data);
      setTimeout(() => this.filterQs$.next(this.externalInvoicesService.getQueryString(data)));
      this.loadExternalInvoices(data);
    });
  }

  public loadExternalInvoices(filters?: any): void {
    this.store
      .dispatch(new LoadExternalInvoices(filters))
      .pipe(switchMap(() => this.store.select(ExternalInvoicesState.workOrders)))
      .subscribe((workOrders: WorkOrder[]): void => {
        const workOrderFormGroups = [];
        for (const workOrder of workOrders) {
          workOrderFormGroups.push(
            new FormGroup({
              workOrderId: new FormControl(workOrder.id),
              externalInvoiceNumber: new FormControl(workOrder.externalInvoiceNumber),
              externalOfferNumber: new FormControl(workOrder.externalOfferNumber),
            }),
          );
        }

        this.externalInvoicesForm.setControl('externalInvoices', new FormArray(workOrderFormGroups));
      });
  }

  public externalInvoiceByIndex(index: number): FormGroup {
    return this.externalInvoices.at(index) as FormGroup;
  }

  public async onSubmit(): Promise<void> {
    const filters = { ...this.route.snapshot.queryParams };

    try {
      this.isBusy = true;
      const processedExternalInvoices = this.externalInvoices.value.filter((i) => {
        return i.externalInvoiceNumber?.length > 0 || i.externalOfferNumber?.length > 0;
      });

      await firstValueFrom(this.store.dispatch(new ProcessExternalInvoices(processedExternalInvoices, filters)));
    } finally {
      this.isBusy = false;
    }
  }
}
