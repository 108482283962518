import { Injectable, Inject } from '@angular/core';

import { Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class PageVisiblityService {
  public visibilityChanged$ = new Subject();
  public constructor(@Inject(DOCUMENT) private document: any) {
    this.document.addEventListener('visibilitychange', () => {
      this.visibilityChanged$.next(!this.document.hidden);
    });
  }
}
