import { Expose, Exclude, Transform, Type } from 'class-transformer';

import { INote } from '../../../shared/interfaces/note';
import { datetimeToPlain } from '../transformers';
import { User } from './user';

@Exclude()
export class Note implements INote {
  @Expose()
  public id: string;

  @Expose()
  public note: string;

  @Expose()
  public attachment: string;

  @Expose()
  @Type(() => User)
  public user: User;

  @Expose()
  @Transform(datetimeToPlain, { toPlainOnly: true })
  public created: Date;
}
