import { v4 as uuid } from 'uuid';

import { Report, User, WorkOrder } from '../models';
import { ClassTransformOptions, plainToInstance, instanceToPlain } from 'class-transformer';

export class ObjectGenerator {
  public generateNewReport(options: { user: User }, classTransformOptions?: ClassTransformOptions): Report {
    options = { ...options };
    return plainToInstance(
      Report,
      {
        id: uuid(),
        mechanic: options.user.role === 'mechanic' ? options.user : null,
        company: null,
        client: {
          id: uuid(),
          type: 'private',
        },
        images: [],
      },
      classTransformOptions,
    );
  }

  public cloneExistingWorkOrder(workOrder: WorkOrder): WorkOrder {
    workOrder.report.images = workOrder.report.images ?? [];
    const workOrderClone = { ...workOrder } as WorkOrder;
    workOrderClone.isNew = false;
    workOrderClone.isEdit = true;

    if (workOrderClone.report.client?.clientAddress) {
      workOrderClone.report.client.clientAddress.contact = workOrderClone.contact;
    }

    return workOrderClone;
  }

  public generateNewWorkOrder(
    options: { user: User; report: Report },
    classTransformOptions?: ClassTransformOptions,
  ): WorkOrder {
    const report = options.report ?? this.generateNewReport({ user: options.user });

    const workOrderData = {
      id: uuid(),
      state: 'draft',
      isNew: !options.report,
      isEdit: false,
      mechanic: options.user.role === 'mechanic' ? options.user : null,
      images: [],
    };

    const instance = plainToInstance(WorkOrder, workOrderData, classTransformOptions);

    instance.report = report;
    return instance;
  }

  public patchWorkOrder(workOrder: WorkOrder, data: any, classTransformOptions?: ClassTransformOptions): WorkOrder {
    const clone = { ...workOrder } as WorkOrder;
    const workOrderData = instanceToPlain(clone);

    const patch = (currentData: any, newData: any) => {
      for (const i of Object.keys(newData)) {
        const newValue = newData[i];

        if (Array.isArray(newValue)) {
          currentData[i] = patch([], newValue) || {};
          continue;
        }

        if (newValue instanceof Object && !(newValue instanceof Date)) {
          currentData[i] = patch(currentData[i] || {}, newValue) || {};
          continue;
        }

        currentData[i] = newValue;
      }

      return currentData;
    };

    const patchedData = patch(workOrderData, data);
    const patchedWorkOrder = plainToInstance(WorkOrder, patchedData, classTransformOptions);
    patchedWorkOrder.id = workOrder.id;
    return patchedWorkOrder;
  }
}
