import { Company } from '../../models';

export class OpenCompaniesList {
  public static readonly type = '[Companies] Open companies list';
}

export class LoadCompanies {
  public static readonly type = '[Companies] Load companies';
}

export class OpenCompanyForm {
  public static readonly type = '[Companies] Open company form';
  public constructor(public company?: Company) {}
}

export class SaveCompany {
  public static readonly type = '[Companies] Save company';
  public constructor(public company: Company) {}
}

export class DeleteCompany {
  public static readonly type = '[Companies] Delete company';
  public constructor(public company: Company) {}
}
