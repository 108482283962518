import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

import { WorkOrder } from '@vandelft/modules/shared/models';
import { ResetProgress } from '@vandelft/modules/shared/state/progress';
import { LoadWorkOrderDraftById, WorkOrderDraftState } from '@vandelft/modules/shared/state/work-order-draft';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-draft-base-form',
  template: '',
})
export class DraftBaseFormComponent implements OnDestroy {
  @Select(WorkOrderDraftState.workOrder)
  public workOrder$: Observable<WorkOrder>;

  public initFormSub: Subscription;

  public constructor(
    protected route: ActivatedRoute,
    protected store: Store,
  ) {
    const workOrderId = this.route.snapshot.paramMap.get('workOrderId');

    this.initFormSub = this.workOrder$
      .pipe(filter((workOrder: WorkOrder) => !!workOrder))
      .subscribe((workOrder: WorkOrder): void => {
        setTimeout(() => this.initForm(workOrder));
      });

    this.store.dispatch(new LoadWorkOrderDraftById(workOrderId));
  }

  public ngOnDestroy(): void {
    if (this.initFormSub) {
      this.initFormSub.unsubscribe();
    }

    this.store.dispatch(new ResetProgress());
  }

  public compareById(item1: any, item2: any): boolean {
    return item1?.id === item2?.id;
  }

  protected initForm(_: WorkOrder): void {}
}
