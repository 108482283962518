import { IReportBackReplacements } from '@vandelft/shared/interfaces';

export class OpenReportBackItemsList {
  public static readonly type = '[Feedback] Open report back items list';
}

export class LoadReportBackItems {
  public static readonly type = '[Feedback] Load report back items';
}

export class OpenReportBackItemDetails {
  public static readonly type = '[Feedback] Open report back items details';
  public constructor(public id: string) {}
}

export class LoadReportBackItemDetails {
  public static readonly type = '[Feedback] Load report back items details';
  public constructor(public id: string) {}
}

export class ReportBack {
  public static readonly type = '[Feedback] Report back item';
  public constructor(public workOrderId: string, public replacements: IReportBackReplacements) {}
}

export class CancelReportBack {
  public static readonly type = '[Feedback] Cancel report back item';
  public constructor(public workOrderId: string) {}
}
