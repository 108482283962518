<ng-container *ngIf='!!message && !!message.address'>
  <app-page-header title='Bericht bewerken'>
    <button class='btn btn-delete actions' (click)='delete(message.id)'>Bericht verwijderen</button>
  </app-page-header>

  <div class='container mt-3 mb-3'>
    <div class='row'>
      <div class='col'>
        <div class='header mb-3'>
          <h1 class='title'>Contact</h1>
        </div>
      </div>
      <div class='col'>
        <div class='header mb-3'>
          <h1 class='title'>Adres</h1>
        </div>
      </div>
    </div>
    <div class='row'>
      <div class='col'>
        {{ message.address.contact }}<br>
        <a href='tel:{{message.address.phoneNumber}}'>{{ message.address.phoneNumber }}</a>
      </div>
      <div class='col'>
        {{ message.address.postalCode }}<br>
        {{ message.address.streetAndHouseNumber }}, {{ message.address.city }}
      </div>
    </div>
  </div>

  <div class='container mt-3' *ngIf='templates$ | async as templates'>
    <div class='row message-overview'>
      <div class='config col'>
        <div class='header mb-3'>
          <h1 class='title'>Instellingen</h1>
        </div>
        <div class='form' [formGroup]='form'>
          <div class='form-group mb-2'>
            <label for='template'>
              Kies een template
            </label>
            <select formControlName='template' id='template'>
              <option *ngFor='let template of templates' [value]='template.name'>{{ template.name }}</option>
            </select>
          </div>
          <div class='form-group'>
            <label for='scheduled'>
              Wanneer moet het bericht verstuurd worden?
            </label>
            <input type='datetime-local' id='scheduled' formControlName='scheduled'>
          </div>
        </div>
        <div class='col mt-2'>
          <div class='body'>
            <button class='btn btn-success' (click)='submit()'>Opslaan</button>
          </div>
        </div>
        <div class='col mt-2' *ngIf='message.report'>
          <div class='body'>
            <ng-container *ngIf='prefix$ | async as prefix'>
              <a
                class='btn btn-secondary'
                style='display: inline-block'
                routerLink='/{{prefix}}/reports/{{message.report.id}}'
                target='_blank'>
                Melding bekijken
              </a>
            </ng-container>
          </div>
        </div>
      </div>
      <div class='preview col'>
        <div class='header mb-3'>
          <h1 class='title'>Preview</h1>
        </div>
        <div class='message'>
          <div class='text' *ngIf='message.message else template'>
            <app-message [content]='message.message'></app-message>
          </div>
          <ng-template #template>
            <div class='template' *ngIf='previewTemplate'>
              <div class='text'>{{ previewTemplate.bodyText }}</div>
              <ul class='buttons' *ngFor='let button of previewTemplate.buttons'>
                <ng-container *ngIf='button.type === "URL"'>
                  <li>
                    <a [href]='button.url' target='_blank'>{{ button.text }}</a>
                  </li>
                </ng-container>
                <ng-container *ngIf='button.type === "PHONE_NUMBER"'>
                  <li>
                    <a href='tel:{{button.phone_number}}'>{{ button.text }}</a>
                  </li>
                </ng-container>
              </ul>
            </div>
          </ng-template>
          <div class='time'>
            {{ message.scheduled | date:'YYYY-MM-dd HH:mm' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
