<form [formGroup]="stockItemForm" class="form" (ngSubmit)="onSubmit()">
  <app-page-header title="Voorraad aanvullen {{ stockItem?.mechanic?.fullName }}">
    <div class="actions ml-auto">
      <button type="submit" class="btn btn-primary ml-auto" [disabled]="stockItemForm.invalid">
        <span *ngIf="!isBusy" class="text">Opslaan</span>
        <span *ngIf="isBusy" class="loader"><img src="assets/img/loader-white.svg" /></span>
      </button>
    </div>
  </app-page-header>

  <div class="container">
    <div class="card">
      <div class="body p-2">
        <ul class="list stock-list" formArrayName="products">
          <li class="header">
            <div class="description">Product</div>
            <div class="quantity">Gebruikt</div>
            <div class="quantity ml-1">Aanvullen</div>
          </li>

          <li *ngFor="let product of products.controls; let i = index" [formGroupName]="i">
            <div class="form-group description required">
              <div class="form-control">
                {{ stockItem.products[i].description }} ({{ stockItem.products[i].articleNumber }})
              </div>
            </div>

            <div class="form-group quantity">
              <input type="number" readonly class="form-control" formControlName="quantity" placeholder="0" />
            </div>

            <div class="form-group quantity ml-1">
              <input type="number" class="form-control" formControlName="refilled" placeholder="0" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</form>
