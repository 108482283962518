<div *ngIf="errors?.length > 0" class="card mb-1 errors-list">
  <div class="error" *ngFor="let error of errors">{{ error }}</div>
</div>

<ng-container *ngIf="!isBusy; else loading">
  <div class="card mb-1">
    <a class="btn btn-primary" (click)="sendAll()">Verzend alles</a>
  </div>

  <div class="card mb-1">
    <h1>
      Werkbonnen
      <small>({{ workOrders?.length }})</small>
    </h1>
  </div>

  <div class="card">
    <h1>
      Drafts
      <small>({{ workOrderDrafts?.length }})</small>
    </h1>
  </div>
</ng-container>

<ng-template #loading>
  <img class="loader" src="assets/img/loader-primary.svg" />
</ng-template>

<input *ngIf="false" type="file" (change)="onFileChanged($event)" />
