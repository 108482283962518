import { Expose, Exclude } from 'class-transformer';

import { IImage } from '@vandelft/shared/interfaces';

@Exclude()
export class Image implements IImage {
  @Expose()
  public id: string;

  @Expose()
  public model: string;

  @Expose()
  public foreignKey: string;

  @Expose()
  public fileType: string;

  @Expose()
  public location: string;

  @Expose()
  public deleted: Date;

  @Expose({ toPlainOnly: true })
  public internalType = 'image';

  public isLocal = false;
}
