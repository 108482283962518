import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ConfirmModalComponent } from '@vandelft/modules/shared/components/ui/confirm-modal';
import { ModalService } from '@vandelft/modules/shared/services/modal.service';

import { Product } from '@vandelft/modules/shared/models';
import { DeleteProduct, OpenProductForm, ProductsState, LoadProducts } from '@vandelft/modules/shared/state/products';

@Component({
  selector: 'app-products-list',
  templateUrl: './list.component.html',
})
export class ListComponent {
  @Select(ProductsState.products)
  public products$: Observable<Product[]>;

  public constructor(private store: Store, private modalService: ModalService) {
    this.store.dispatch(new LoadProducts());
  }

  public addItem(): void {
    this.store.dispatch([new OpenProductForm()]);
  }

  public selectItem(product: Product): void {
    this.store.dispatch([new OpenProductForm(product)]);
  }

  public async deleteProduct(e: Event, product: Product): Promise<void> {
    e.preventDefault();
    e.stopPropagation();

    const modalRef = this.modalService.open(ConfirmModalComponent, {
      modalType: 'modal-floating-confirmation',
      barrier: false,
      alignment: {
        element: e.target,
      },
    });

    modalRef.componentInstance.message = `Weet u zeker dat ${product.description} wilt verwijderen?`;
    const result = await modalRef.result;
    if (!result) {
      return;
    }

    this.store.dispatch([new DeleteProduct(product)]);
  }
}
