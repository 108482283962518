<form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <app-page-header title="Hoe wilt u betalen"></app-page-header>

  <app-page-body>
    <div class="container">
      <ng-container class="list" *ngIf="workOrder$ | async as workOrder; else loading">
        <div class="row" *ngIf="!isCompany">
          <div class="col card">
            <div class="header px-2 pt-2">
              <h2>Betaling</h2>
            </div>
            <div class="body p-2">
              <ul class="material-list">
                <ng-container formArrayName="payments">
                  <li *ngFor="let payment of payments.controls; let i = index">
                    <a (click)="openPaymentModal(i)">
                      <div class="details">
                        <div class="title">{{ paymentMethods[payment.value.method] }}</div>
                        <div class="price">
                          <div tna-money [amount]="payment.value.amount"></div>
                        </div>
                        <div class="actions">
                          <a
                            class="btn btn-primary btn-icon btn-outline ml-auto"
                            (click)="deletePayment($event, payment)">
                            <img src="assets/img/trash.svg" />
                          </a>
                        </div>
                      </div>
                    </a>
                  </li>
                </ng-container>
                <li *ngIf="deposit">
                  <div class="details">
                    <div class="title bold">Totaal gebruikte producten</div>
                    <div class="price bold">
                      <div tna-money [amount]="totalProductsPriceInc"></div>
                    </div>
                    <div class="actions"></div>
                  </div>
                </li>
                <li *ngIf="deposit">
                  <div class="details">
                    <div class="title bold">Aanbetaling bestelde producten</div>
                    <div class="price bold">
                      <div tna-money [amount]="deposit"></div>
                    </div>
                    <div class="actions"></div>
                  </div>
                </li>

                <li *ngIf="totalPayments">
                  <div class="details">
                    <div class="title bold">Reeds voldaan</div>
                    <div class="price bold">
                      <div
                        tna-money
                        [ngClass]="{ 'text-danger': toPay >= 0, 'text-success': toPay === 0 }"
                        [amount]="totalPayments"></div>
                    </div>
                    <div class="actions"></div>
                  </div>
                </li>

                <li *ngIf="deposit">
                  <div class="details">
                    <div class="title bold">Te voldoen</div>
                    <div class="price bold">
                      <div
                        tna-money
                        [amount]="toPay"
                        [ngClass]="{ 'text-danger': toPay >= 0, 'text-success': toPay === 0 }"></div>
                    </div>
                    <div class="actions"></div>
                  </div>
                </li>
              </ul>

              <div class="buttons mt-1">
                <a class="btn btn-success ml-auto" (click)="openPaymentModal()">Betaling toevoegen</a>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-1">
          <div class="col card">
            <div class="header px-2 pt-2">
              <h2>Akkoord klant</h2>
            </div>
            <div class="body px-2">
              <div class="form-group required">
                <label class="label">Naam</label>
                <input type="text" class="form-control" formControlName="clientName" placeholder="Naam klant" />
              </div>
              <ng-container *ngIf="workOrder?.report?.client?.type !== 'company'">
                <div class="form-group required">
                  <label class="label">Email (voor factuur)</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="clientEmail"
                    autocorrect="off"
                    autocapitalize="none"
                    placeholder="Email klant" />
                </div>
              </ng-container>
              <ng-container *ngIf="workOrder?.report?.client?.type === 'company'">
                <input type="hidden" formControlName="clientEmail" />
              </ng-container>
              <div class="form-group required">
                <label class="label">Handtekening</label>
                <canvas
                  *ngIf="!workOrder.isEdit"
                  width="400"
                  height="100"
                  app-signature-pad
                  formControlName="clientSignature"></canvas>
                <img
                  *ngIf="workOrder.isEdit && workOrder?.clientSignature?.id"
                  width="400"
                  height="100"
                  src="{{ apiUrl }}/images/{{ workOrder.clientSignature.id }}?token={{ token$ | async }}" />
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-1">
          <div class="col card">
            <div class="header px-2 pt-2">
              <h2>Akkoord monteur</h2>
            </div>
            <div class="body px-2">
              <div class="form-group required">
                <label class="label">Handtekening</label>
                <canvas
                  *ngIf="!workOrder.isEdit"
                  width="400"
                  height="100"
                  app-signature-pad
                  formControlName="mechanicSignature"></canvas>
                <img
                  *ngIf="workOrder.isEdit && workOrder?.mechanicSignature?.id"
                  width="400"
                  height="100"
                  src="{{ apiUrl }}/images/{{ workOrder.mechanicSignature.id }}?token={{ token$ | async }}" />
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-1">
          <div class="col card">
            <div class="body p-2">
              <div class="row">
                <ng-container *ngIf="!isCompany">
                  <label>
                    <input type="checkbox" id="paymentSuccessful" #paymentSuccessful />
                    Is de betaling succesvol afgerond?
                  </label>
                  <button
                    type="submit"
                    class="btn btn-success ml-auto"
                    [disabled]="form.invalid || !paymentSuccessful?.checked || toPay !== 0">
                    Afronden
                  </button>
                </ng-container>

                <ng-container *ngIf="isCompany">
                  <button type="submit" class="btn btn-success ml-auto" [disabled]="form.invalid">Afronden</button>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </app-page-body>
</form>

<ng-template #loading>
  <div class="loader">
    <img class="loader" src="assets/img/loader-primary.svg" />
  </div>
</ng-template>
