<ng-container *ngIf="reportBackItem$ | async as workOrder">
  <app-page-header title="Terugmeldingen">
    <div class="actions ml-auto" *ngIf="!isBusy">
      <a class="btn btn-secondary mr-1" (click)="cancelReportBack()">Terugmelden annuleren</a>
      <a class="btn btn-primary" (click)="reportBack()">Terugmelden</a>
    </div>
  </app-page-header>

  <div class="container">
    <ng-container *ngIf="!isBusy; else loading">
      <div class="form-container">
        <div class="card">
          <div class="body py-2">
            <div class="row mt-1 mb-3">
              <div class="col card">
                <div class="body">
                  <div class="invoice-header border-top border-bottom">
                    <div class="row border-bottom">
                      <div class="col bold">Gegevens opdrachtgever</div>
                    </div>

                    <div class="row">
                      <div class="col">
                        {{ workOrder?.report?.company?.name }}
                      </div>
                      <div class="col">
                        {{ workOrder?.report?.company?.email }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="invoice-header border-top">
            <div class="row border-bottom">
              <div class="col bold">Opmerking monteur</div>
            </div>

            <div class="row">
              <div class="col form-group">
                <textarea class="form-control" formControlName="customRemarks" rows="5"></textarea>
              </div>
            </div>
          </div>

          <div class="invoice-header border-top">
            <div class="row border-bottom">
              <div class="col bold">Arbeid / material</div>
            </div>

            <div class="form-group" *ngFor="let control of productControls">
              <ng-container [formGroup]="control">
                <input type="text" class="form-control" formControlName="customDescription" />
              </ng-container>
            </div>

            <div class="form-group" *ngFor="let control of orderLineControls">
              <ng-container [formGroup]="control">
                <input type="text" class="form-control" formControlName="customDescription" />
              </ng-container>
            </div>
          </div>

          <button class="btn btn-secondary" type="submit">Preview</button>
        </form>
      </div>

      <div class="pdf-viewer">
        <ngx-extended-pdf-viewer
          *ngIf="replacements"
          src="{{ apiUrl }}/work-orders/{{ workOrder.id }}/download?isReportBack=true&isInternal=false&token={{
            token$ | async
          }}&{{ replacements }}"
          [showToolbar]="true"
          [showSidebarButton]="false"
          [showFindButton]="false"
          [showPagingButtons]="true"
          [showZoomButtons]="true"
          [showPresentationModeButton]="false"
          [showOpenFileButton]="false"
          [showPrintButton]="true"
          [showDownloadButton]="true"
          [showSecondaryToolbarButton]="true"
          [showRotateButton]="false"
          [showHandToolButton]="false"
          [showScrollingButton]="false"
          [showSpreadButton]="false"
          [showPropertiesButton]="false"
          backgroundColor="#ffffff"
          [showBorders]="false"
          [zoom]="'100%'"
          height="85rem"></ngx-extended-pdf-viewer>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-template #loading>
  <div class="loader">
    <img class="loader" src="assets/img/loader-primary.svg" />
  </div>
</ng-template>
