import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';

import { ModalService } from '@vandelft/modules/shared/services/modal.service';

import { ProductsService } from '@vandelft/modules/shared/services/products.service';
import { Product, Report, WorkOrder } from '@vandelft/modules/shared/models';
import { Select } from '@ngxs/store';
import { ReportsState } from '@vandelft/modules/shared/state/reports';
import { AuthState } from '@vandelft/modules/shared/state/auth';
import { environment } from '@vandelft/environments/environment';

@Component({
  selector: 'app-product-form-modal',
  templateUrl: './product-form-modal.component.html',
  styleUrls: ['product-form-modal.component.scss'],
})
export class ProductFormModalComponent implements OnInit {
  @Select(ReportsState.report)
  public report$: Observable<Report>;

  @Input()
  public workOrder: WorkOrder;

  @Select(AuthState.token)
  public token$: Observable<string>;

  @Input()
  public product: Product;

  @Input()
  public isCompany: boolean;

  public apiUrl = environment.apiUrl;

  public suggestions$: Subject<Product[]> = new Subject<Product[]>();

  public selectedSuggestion: Product;

  public productForm = new FormGroup({
    id: new FormControl(null),
    description: new FormControl('', [Validators.required]),
    quantity: new FormControl(1, [Validators.required]),
    priceLater: new FormControl(false),
    price: new FormControl(0),
    onLoan: new FormControl(false),
    articleNumber: new FormControl(null),
    remarks: new FormControl(null),
  });

  private selectedSuggestionIndex = -1;

  constructor(
    private modalService: ModalService,
    private productsService: ProductsService,
  ) {}

  public async ngOnInit(): Promise<void> {
    if (this.product) {
      this.productForm.patchValue(this.product);
    }

    this.productForm
      .get('description')
      .valueChanges.pipe(debounceTime(300))
      .subscribe(async (value: string): Promise<void> => {
        const products: Product[] = await firstValueFrom(this.productsService.getProductsByQuery(value).pipe(first()));
        this.suggestions$.next(products);

        if (products.length === 0) {
          this.selectedSuggestion = null;
          this.selectedSuggestionIndex = -1;
          this.productForm.patchValue({
            id: null,
            articleNumber: null,
            onLoan: false,
          });
          this.product = null;
        }
      });
  }

  @HostListener('window:keyup.arrowUp', ['$event'])
  public async selectPrevSuggestion(_: KeyboardEvent): Promise<void> {
    const suggestions: Product[] = await firstValueFrom(this.suggestions$.pipe(first()));
    const currentIndex: number = this.selectedSuggestionIndex;

    if (!suggestions || currentIndex <= 0) {
      return;
    }

    this.selectedSuggestionIndex = currentIndex - 1;
    this.selectedSuggestion = suggestions[this.selectedSuggestionIndex];
  }

  @HostListener('window:keyup.arrowDown', ['$event'])
  public async selectNextSuggestion(_: KeyboardEvent): Promise<void> {
    const suggestions: Product[] = await firstValueFrom(this.suggestions$.pipe(first()));
    const currentIndex: number = this.selectedSuggestionIndex;

    if (!suggestions || currentIndex >= suggestions.length - 1) {
      return;
    }

    this.selectedSuggestionIndex = currentIndex + 1;
    this.selectedSuggestion = suggestions[this.selectedSuggestionIndex];
  }

  @HostListener('window:keyup.enter', ['$event'])
  public setProductDetails(_: MouseEvent, suggestion = null): void {
    const activeSuggestion = suggestion || this.selectedSuggestion;
    if (!activeSuggestion) {
      return;
    }

    this.productForm.patchValue(activeSuggestion, { emitEvent: false });
    this.product = activeSuggestion;

    this.selectedSuggestionIndex = -1;
    this.selectedSuggestion = null;
    this.suggestions$.next(null);
  }

  @HostListener('window:keyup.escape', ['$event'])
  public cancelSuggestions(_: KeyboardEvent): void {
    this.suggestions$.next(null);
  }

  public onSubmit(): void {
    const data = this.productForm.value;
    this.modalService.close(data);
  }

  public onCancel(): void {
    this.modalService.close(null);
  }
}
