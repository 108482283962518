import { Component, AfterViewInit } from '@angular/core';
import { LocalStoreService } from '@vandelft/modules/shared/services/local-store.service';
import { IWorkOrder } from '@vandelft/shared/interfaces';
import { v4 } from 'uuid';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Component({
  selector: 'app-debug-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements AfterViewInit {
  public isBusy = false;
  public errors = [];
  public workOrders: IWorkOrder[];
  public workOrderDrafts: IWorkOrder[];

  public constructor(
    private localStorageService: LocalStoreService,
    private storage: AngularFireStorage,
    private auth: AngularFireAuth,
  ) {}

  public async ngAfterViewInit(): Promise<void> {
    this.workOrders = await this.localStorageService.getWorkOrders();
    this.workOrderDrafts = await this.localStorageService.getWorkOrderDrafts();
  }

  public async sendWorkOrders(): Promise<void> {
    const workOrders = await this.localStorageService.getWorkOrdersRaw();

    for (const workOrder of workOrders) {
      try {
        const ref = this.storage.ref(`work-orders/${v4()}.json`);
        const workOrderData = JSON.stringify(workOrder);
        await ref.putString(workOrderData);
      } catch (e) {
        this.errors.push(`${e}`);
      }
    }
  }

  public async sendWorkOrderDrafts(): Promise<void> {
    const workOrderDrafts = await this.localStorageService.getWorkOrderDraftsRaw();

    for (const workOrderDraft of workOrderDrafts) {
      try {
        const ref = this.storage.ref(`drafts/${v4()}.json`);
        const workOrderDraftData = JSON.stringify(workOrderDraft);
        await ref.putString(workOrderDraftData);
      } catch (e) {
        this.errors.push(`${e}`);
      }
    }
  }

  public async sendAll(): Promise<void> {
    this.isBusy = true;
    this.errors = [];
    await this.auth.signInWithEmailAndPassword('berry@thenextapp.nl', 'p&9B^E%s%92f^Y');
    await this.sendWorkOrders();
    await this.sendWorkOrderDrafts();
    this.isBusy = false;
  }

  public onFileChanged(e: any): void {
    const reader: FileReader = new FileReader();
    reader.addEventListener('load', async (event: ProgressEvent<FileReader>): Promise<void> => {
      const content: string | ArrayBuffer = event.target.result;
      const [, data] = `${content}`.split(',');
      const workOrderData = JSON.parse(atob(data));
      await this.localStorageService.saveWorkOrderRaw(workOrderData);
    });
    reader.readAsDataURL(e.target.files[0]);
  }
}
