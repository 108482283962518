<div class="form" *ngIf="order$ | async as order">
  <app-page-header title="Bestelling"></app-page-header>

  <div class="container px-2">
    <div class="card">
      <div class="body py-2">
        <div class="row">
          <div class="col">
            <div class="form-group plain-text">
              {{ order.workOrder.report.client.clientAddress.contact }}
              <br />
              {{ order.workOrder.report.client.clientAddress.street }}
              {{ order.workOrder.report.client.clientAddress.houseNumber }}
              {{ order.workOrder.report.client.clientAddress.houseNumberSuffix }}
              <br />
              {{ order.workOrder.report.client.clientAddress.postalCode }}
              {{ order.workOrder.report.client.clientAddress.city }}
              <br />
              {{ order.workOrder.clientEmail }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-1 mb-3">
      <div class="col card">
        <div class="body">
          <div class="invoice-header border-top border-bottom">
            <div class="row border-bottom">
              <div class="col bold">Werbondatum</div>
              <div class="col bold">Werbonnummer</div>
            </div>

            <div class="row">
              <div class="col">{{ order.workOrder.executed | date: 'dd MMMM yyyy' }}</div>
              <div class="col">{{ order.workOrder.number }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-1 mb-3" *ngIf="order.remarks?.length > 0">
      <div class="col card">
        <div class="body">
          <div class="invoice-header border-top border-bottom">
            <div class="row border-bottom">
              <div class="col bold">Opmerking</div>
            </div>

            <div class="row">
              <div class="col">{{ order.remarks }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-1 mb-3">
      <div class="col card">
        <div class="body">
          <div class="invoice-header border-top border-bottom">
            <div class="row">
              <div class="col bold">Meegenomen product</div>
              <div class="col">{{ order.takenProduct }}</div>
            </div>

            <div class="row">
              <div class="col bold">Materiaal deur</div>
              <div class="col">{{ materialOptions[order.doorMaterial] }}</div>
            </div>

            <div class="row">
              <div class="col bold">Materiaal kozijn</div>
              <div class="col">{{ materialOptions[order.frameMaterial] }}</div>
            </div>

            <div class="row">
              <div class="col bold">Deurtype</div>
              <div class="col">{{ doorTypeOptions[order.doorType] }}</div>
            </div>

            <div class="row">
              <div class="col bold">Cylinderbediend</div>
              <div class="col">{{ yesNoOptions[order.cylinderOperated ? '1' : '0'] }}</div>
            </div>

            <div class="row">
              <div class="col bold">Doornmaat</div>
              <div class="col">{{ backsetLockOptions[order.backsetLock] }}</div>
            </div>

            <div class="row">
              <div class="col bold">H.O.H. PC maat slot</div>
              <div class="col">{{ spacingLockOptions[order.spacingLock] }}</div>
            </div>

            <div class="row">
              <div class="col bold">Sleutel nabestelling</div>
              <div class="col">{{ yesNoOptions[order.keyRepeatOrder] }}</div>
            </div>

            <div class="row">
              <div class="col bold">Cylinder</div>
              <div class="col">{{ yesNoOptions[order.cylinder] }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-1 mb-3">
      <div class="col card">
        <div class="body">
          <div class="invoice-header border-top border-bottom">
            <div class="row">
              <div class="col bold">Foto certificaat</div>
              <div class="col bold">Foto product</div>
            </div>

            <div class="row">
              <div class="col">
                <ul class="thumbnails">
                  <li *ngFor="let certificate of order.certificates">
                    <a
                      *ngIf="certificate?.id; else noImage"
                      class="thumbnail-container"
                      target="_blank"
                      href="{{ apiUrl }}/images/{{ certificate.id }}?token={{ token$ | async }}">
                      <img src="{{ apiUrl }}/images/{{ certificate.id }}?token={{ token$ | async }}" />
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col">
                <ul class="thumbnails">
                  <li *ngFor="let productImage of order.productImages">
                    <a
                      *ngIf="productImage?.id; else noImage"
                      class="thumbnail-container"
                      target="_blank"
                      href="{{ apiUrl }}/images/{{ productImage.id }}?token={{ token$ | async }}">
                      <img src="{{ apiUrl }}/images/{{ productImage.id }}?token={{ token$ | async }}" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col card">
        <div class="body py-2">
          <ul class="material-list">
            <li class="border-bottom">
              <div class="details">
                <div class="title bold">Arbeid / materiaal</div>
                <div class="quantity bold">Aantal</div>
                <div class="price bold">Prijs</div>
                <div class="price bold">Totaalbedrag</div>
              </div>
            </li>

            <li *ngFor="let orderLine of order.orderLines">
              <a>
                <div class="details">
                  <div class="title">
                    {{ orderLine.description }}
                    <ng-container *ngIf="orderLine.articleNumber">({{ orderLine.articleNumber }})</ng-container>
                  </div>
                  <div class="quantity">{{ orderLine.quantity }}</div>
                  <div class="price"><div tna-money [amount]="orderLine.priceExc"></div></div>
                  <div class="price"><div tna-money [amount]="orderLine.totalPriceExc"></div></div>
                </div>
              </a>
            </li>
            <li class="footer">&nbsp;</li>
            <li class="footer">
              <div class="details">
                <div class="spacer"></div>
                <div class="label">Totaal exclusief BTW</div>
                <div class="price"><div tna-money [amount]="order.totalPriceExc"></div></div>
              </div>
            </li>
            <li class="footer">
              <div class="details">
                <div class="spacer"></div>
                <div class="label">BTW</div>
                <div class="price"><div tna-money [amount]="order.totalTax"></div></div>
              </div>
            </li>
            <li class="footer">
              <div class="details">
                <div class="spacer"></div>
                <div class="label bold border-bottom border-top">Totaal</div>
                <div class="price bold border-bottom border-top">
                  <div tna-money [amount]="order.totalPriceInc"></div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noImage>
  <div class="no-image">Geen afbeelding geupload</div>
</ng-template>
