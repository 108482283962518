import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { startWith } from 'rxjs/operators';
import * as moment from 'moment';

import { environment } from '@vandelft/environments/environment';
import { Order } from '@vandelft/modules/shared/models';
import { OrdersState, LoadOrders, OpenOrdersDetails } from '@vandelft/modules/shared/state/orders';
import { OrdersService } from '@vandelft/modules/shared/services/orders.service';
import { AuthState } from '@vandelft/modules/shared/state/auth';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
})
export class ListComponent implements OnInit {
  @Select(OrdersState.orders)
  public orders$: any;

  @Select(AuthState.token)
  public token$: Observable<string>;

  public apiUrl = environment.apiUrl;

  public filterQs$ = new BehaviorSubject(null);

  public filterForm = new FormGroup({
    start: new FormControl(moment().format('YYYY-MM-DD')),
    end: new FormControl(moment().format('YYYY-MM-DD')),
  });

  public constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private ordersService: OrdersService,
  ) {
    const queryParams = this.route.snapshot.queryParams;
    this.filterForm.patchValue(queryParams);
    this.filterForm.valueChanges.subscribe((data) => {
      this.updateRoute(data);
    });
  }

  public async ngOnInit(): Promise<void> {
    this.filterForm.valueChanges.pipe(startWith(this.filterForm.value)).subscribe((data: any) => {
      setTimeout(() => this.filterQs$.next(this.ordersService.getQueryString(data)));
      this.store.dispatch(new LoadOrders(data));
    });
  }

  public open(order: Order): void {
    this.store.dispatch(new OpenOrdersDetails(order));
  }

  public updateRoute(data: any): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: data,
    });
  }
}
