import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Observable } from 'rxjs';

import { Product } from '../../models';
import { EnvironmentState } from '../environment';
import { OrderedProductsStateModel } from './ordered-products.state-model';
import {
  OpenOrderedProductsForm,
  OpenOrderedProductsList,
} from '@vandelft/modules/shared/state/ordered-products/ordered-products.actions';

@State<OrderedProductsStateModel>({
  name: 'orderedProducts',
  defaults: {
    orderedProducts: [],
    orderedProduct: null,
  },
})
@Injectable()
export class OrderedProductsState {
  public constructor(private store: Store) {}

  @Selector()
  static stocks(state: OrderedProductsStateModel): Array<Product> {
    return state.orderedProducts;
  }

  @Selector()
  static stock(state: OrderedProductsStateModel): Product {
    return state.orderedProduct;
  }

  @Action(OpenOrderedProductsList)
  public openReportsList(): Observable<any> {
    const prefix = this.store.selectSnapshot(EnvironmentState.prefix);
    return this.store.dispatch(new Navigate([`/${prefix}/ordered-products`]));
  }

  @Action(OpenOrderedProductsForm)
  public openStockItemForm(
    _: StateContext<OrderedProductsStateModel>,
    { mechanic, date }: OpenOrderedProductsForm,
  ): Observable<any> {
    const prefix = this.store.selectSnapshot(EnvironmentState.prefix);

    if (!['admin', 'planner', 'financial'].includes(prefix)) {
      return;
    }

    return this.store.dispatch(new Navigate([`/${prefix}/ordered-products/${mechanic.id}`], { date }));
  }
}
