<form class="form px-2" [formGroup]="workOrderForm" (ngSubmit)="onSubmit()">
  <div class="page-header">
    <div class="page-title">
      <h1>Werkbon toevoegen</h1>
      <div class="actions ml-auto">
        <button
          type="button"
          *ngIf="workOrderForm.get('state').value !== 'completed'"
          class="btn btn-primary btn-outline"
          (click)="onCompletion()"
          [disabled]="workOrderForm.invalid">
          <span *ngIf="!isBusy" class="text">afronden</span>
          <span *ngIf="isBusy" class="loader"><img src="assets/img/loader-primary.svg" /></span>
        </button>

        <button type="submit" class="btn btn-primary ml-1" [disabled]="workOrderForm.get('order').invalid">
          <span *ngIf="!isBusy" class="text">opslaan</span>
          <span *ngIf="isBusy" class="loader"><img src="assets/img/loader-primary.svg" /></span>
        </button>
      </div>
    </div>

    <ul class="tabs">
      <li *ngFor="let tab of tabs" (click)="activateTab(tab)" [ngClass]="{ active: tab.key == activeTab.key }">
        <a>{{ tab.label }}</a>
      </li>
    </ul>
  </div>

  <div class="tab-content">
    <app-order-form [ngClass]="{ hidden: activeTab.key !== 'order' }" formControlName="order"></app-order-form>

    <app-activities-form
      [ngClass]="{ hidden: activeTab.key !== 'activities' }"
      formControlName="activities"></app-activities-form>

    <app-completion-form
      [ngClass]="{ hidden: activeTab.key !== 'completion' }"
      formControlName="completion"></app-completion-form>
  </div>
</form>
