import { Component } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { LogoutUser, AuthState } from '@vandelft/modules/shared/state/auth';
import { environment } from '@vandelft/environments/environment';
import { Observable } from 'rxjs';
import { User } from '@vandelft/modules/shared/models';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  public version = environment.version;

  @Select(AuthState.user)
  public user$: Observable<User>;

  public constructor(private store: Store) {}

  public logout(): void {
    this.store.dispatch([new LogoutUser()]);
  }
}
