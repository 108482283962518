import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';

import { LoginUser } from '@vandelft/modules/shared/state/auth';

@Component({
  selector: 'tna-users-login',
  templateUrl: './login.component.html',
})
export class LoginComponent {
  public isBusy = false;
  public loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });

  public constructor(private store: Store) {}

  public onSubmit(): void {
    try {
      this.isBusy = true;
      const data = this.loginForm.value;
      this.store.dispatch([new LoginUser(data.email, data.password)]);
    } catch (e) {
      console.error(e);
    } finally {
      this.isBusy = false;
    }
  }
}
