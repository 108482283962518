import { Component, forwardRef, Input } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import * as moment from 'moment/moment';

import { LoadMechanics, UsersState } from '@vandelft/modules/shared/state/users';
import { CompaniesState, LoadCompanies } from '@vandelft/modules/shared/state/companies';
import { Company, Report, User } from '@vandelft/modules/shared/models';
import { IUser } from '@vandelft/shared/interfaces';
import { reportStatuses } from '@vandelft/shared/constants';
import { instanceToPlain, plainToClassFromExist } from 'class-transformer';

@Component({
  selector: 'app-report-form',
  templateUrl: './report-form.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReportFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ReportFormComponent),
      multi: true,
    },
  ],
})
export class ReportFormComponent implements ControlValueAccessor {
  @Input()
  public isNew = false;

  @Input()
  public editMode = false;

  @Input()
  public user: IUser;

  @Select(CompaniesState.companies)
  public companies$: Observable<Company[]>;

  @Select(UsersState.mechanics)
  public mechanics$: Observable<User[]>;

  public report: Report;

  public statuses = reportStatuses;

  public thumbnails: string[] = [];

  public form = new FormGroup({
    locationIsSameAddress: new FormControl(true),
    date: new FormControl(null, [Validators.required]),
    start: new FormControl(null, [Validators.required]),
    end: new FormControl(null, [Validators.required]),
    onTheGo: new FormControl(null),
    arrival: new FormControl(null),
    departure: new FormControl(null),
    description: new FormControl(null),
    reference: new FormControl(null),
    mechanic: new FormControl(null),
    company: new FormControl(null),
    status: new FormControl('open'),
    installOrderedProducts: new FormControl(false),
    installRemarks: new FormControl(null),
    remarks: new FormControl(null),
    images: new FormControl(null),
    client: new FormGroup({
      id: new FormControl(null, [Validators.required]),
      type: new FormControl(null, [Validators.required]),
      clientAddress: new FormControl(null, [Validators.required]),
      locationAddress: new FormControl(null),
    }),
  });

  public constructor(private store: Store) {
    const events = [new LoadMechanics(), new LoadCompanies()];

    this.form.valueChanges.subscribe((value) => {
      setTimeout(() => {
        const data = this.processData(value);
        return this.onChange(data);
      });
    });

    this.form.get('client.type').valueChanges.subscribe((value) => {
      const referenceControl = this.form.get('reference');
      const sameAddressControl = this.form.get('locationIsSameAddress');
      referenceControl.clearValidators();

      if (value !== 'company') {
        const company = this.form.get('company');
        company.setValue(null);
        company.clearValidators();
        company.updateValueAndValidity({ emitEvent: false });
      }

      if (value === 'company') {
        referenceControl.setValidators([Validators.required]);
        this.form.get('client.clientAddress').patchValue({ email: 'opdrachten@vandelftbeveiligingen.nl' });
        sameAddressControl.setValue(true);
      }

      referenceControl.updateValueAndValidity({ emitEvent: false });
    });

    this.store.dispatch(events);
  }

  public onTouch(): any {}

  public writeValue(report: Report): void {
    this.report = report;
    this.initForm(report);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public validate(_: FormControl): any {
    if (!this.form.valid) {
      return { invalid: true };
    }

    return null;
  }

  public compareById(item1: any, item2: any): boolean {
    return item1?.id === item2?.id;
  }

  public processData(data: any): Report {
    if (!data) {
      return data;
    }

    const reportData = instanceToPlain(this.report, { excludePrefixes: ['images'] }) as any;

    reportData.plannedStart = `${data.date} ${data.start}`;
    reportData.plannedEnd = `${data.date} ${data.end}`;
    reportData.onTheGo = data.onTheGo;
    reportData.arrival = data.arrival;
    reportData.departure = data.departure;
    reportData.installOrderedProducts = data.installOrderedProducts;
    reportData.installRemarks = data.installRemarks;
    reportData.mechanic = data.mechanic;
    reportData.mechanicId = data.mechanic?.id || null;
    reportData.company = data.company;
    reportData.companyId = data.company?.id || null;
    reportData.client.type = data.client.type;
    reportData.client.clientAddress = data.client.clientAddress;
    reportData.client.locationAddress = null;
    reportData.description = data.description;
    reportData.reference = data.reference;
    reportData.remarks = data.remarks;
    reportData.status = data.status;

    if (!data.locationIsSameAddress) {
      reportData.client.locationAddress = data.client.locationAddress;
    }

    (data.images ?? []).map((image: any) => {
      const match = (this.report.images as any[]).find((i: any) => i.id === image.id);

      if (match) {
        return plainToClassFromExist({ type: match.type }, image);
      }

      return image;
    });

    reportData.images = data.images;
    return reportData;
  }

  private onChange(_: any): void {}

  private initForm(report: Report): void {
    if (!report) {
      return;
    }

    const plain = instanceToPlain(report, { excludePrefixes: ['images'] });

    this.form
      .get('locationIsSameAddress')
      .valueChanges.subscribe((val) => this.setLocationIsSameAddressValidation(val));

    const locationIsSameAddress =
      !report.client?.locationAddress || report.client.clientAddress?.id === report.client.locationAddress?.id;

    const start = report.plannedStart ? moment(report.plannedStart) : moment().tz('Europe/Amsterdam');

    const end = report.plannedEnd ? moment(report.plannedEnd) : moment().tz('Europe/Amsterdam');

    const data = this.processData(plain);
    const formData = {
      ...data,
      locationIsSameAddress,
      date: moment(report.plannedStart).format('YYYY-MM-DD'),
      start: start.format('HH:mm'),
      end: end.format('HH:mm'),
      onTheGo: !!report.onTheGo ? report.onTheGo.substring(0, report.onTheGo.length - 3) : null,
      arrival: !!report.arrival ? report.arrival.substring(0, report.arrival.length - 3) : null,
      departure: !!report.departure ? report.departure.substring(0, report.departure.length - 3) : null,
    };

    for (const item of Object.keys(formData)) {
      if (formData[item] === undefined) {
        delete formData[item];
      }
    }

    this.form.patchValue(formData);
    this.form.get('images').setValue(report.images);
  }

  private setLocationIsSameAddressValidation(val: boolean): void {
    const formControl = this.form.get('client.locationAddress');

    if (!formControl) {
      return;
    }

    if (val) {
      formControl.clearValidators();
      formControl.patchValue(null);
      formControl.updateValueAndValidity();
      return;
    }

    formControl.setValidators([Validators.required]);
    formControl.updateValueAndValidity();
  }
}
