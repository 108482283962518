import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { StockItem } from '@vandelft/modules/shared/models';
import { StockService } from '@vandelft/modules/shared/services/stock.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { OpenStockItemForm } from '@vandelft/modules/shared/state/stock';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
})
export class ListComponent {
  public stockItems$: Observable<StockItem[]>;

  public constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private stockService: StockService,
  ) {
    this.stockItems$ = this.stockService.getStockItems();
  }

  public open(stockItem: StockItem): void {
    this.store.dispatch(new OpenStockItemForm(stockItem));
  }

  public updateRoute(data: any): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: data,
    });
  }
}
