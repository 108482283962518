import { Expose, Exclude } from 'class-transformer';

import { Product } from './product';

@Exclude()
export class RefillableProduct extends Product {
  @Expose()
  public quantity: number | null;

  @Expose()
  public refilled: number | null;
}
