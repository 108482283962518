import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { switchMap, startWith, tap } from 'rxjs/operators';
import * as moment from 'moment';

import { environment } from '@vandelft/environments/environment';
import { WorkOrder } from '@vandelft/modules/shared/models';
import { WorkOrdersService } from '@vandelft/modules/shared/services/work-orders.service';
import { AuthState } from '@vandelft/modules/shared/state/auth';

@Component({
  selector: 'app-work-orders-list',
  templateUrl: './list.component.html',
})
export class ListComponent implements OnInit {
  public workOrders$: Observable<WorkOrder[]>;
  public isAdmin = false;

  @Select(AuthState.token)
  public token$: Observable<string>;

  public apiUrl = environment.apiUrl;

  public filterQs$ = new Subject();

  public filterForm = new FormGroup({
    start: new FormControl(moment().subtract(1, 'week').format('YYYY-MM-DD')),
    end: new FormControl(moment().add(1, 'week').format('YYYY-MM-DD')),
    paymentMethod: new FormControl(null),
    state: new FormControl(null),
  });

  public constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private workOrdersService: WorkOrdersService,
  ) {
    const queryParams = this.route.snapshot.queryParams;
    this.filterForm.patchValue(queryParams);
    this.filterForm.valueChanges.subscribe((data) => {
      this.updateRoute(data);
    });
  }

  public async ngOnInit(): Promise<void> {
    const user = this.store.selectSnapshot(AuthState.user);
    const mechanicId = user.role === 'mechanic' ? user.id : null;
    this.isAdmin = user.role === 'admin';

    this.workOrders$ = this.filterForm.valueChanges.pipe(
      startWith(this.filterForm.value),
      tap((data) => setTimeout(() => this.filterQs$.next(this.workOrdersService.getQueryString(data)), 1)),
      switchMap((data) => this.workOrdersService.getWorkOrders({ ...data, mechanicId })),
    );
  }

  public updateRoute(data: any): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: data,
    });
  }
}
