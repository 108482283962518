import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { firstValueFrom, Observable } from 'rxjs';

import { WorkOrder } from '@vandelft/modules/shared/models';
import {
  CancelReportBack,
  LoadReportBackItemDetails,
  ReportBack,
  ReportBackItemsState,
} from '@vandelft/modules/shared/state/report-back-items';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@vandelft/environments/environment';
import { AuthState } from '@vandelft/modules/shared/state/auth';
import { IOrderLine, IReportBackReplacements, IWorkOrder } from '@vandelft/shared/interfaces';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { first, switchMap } from 'rxjs/operators';
import { stringify } from 'qs';

@Component({
  selector: 'app-report-back-items-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit {
  @Select(ReportBackItemsState.ReportBackItem)
  public reportBackItem$: Observable<WorkOrder>;

  @Select(AuthState.token)
  public token$: Observable<string>;

  public replacements = {};

  public isBusy = false;

  public form = new FormGroup({
    customRemarks: new FormControl(null),
    products: new FormArray([]),
    orderLines: new FormArray([]),
  });

  public apiUrl = environment.apiUrl;

  public get productControls(): FormGroup[] {
    return (this.form.get('products') as FormArray).controls as FormGroup[];
  }

  public get orderLineControls(): FormGroup[] {
    return (this.form.get('orderLines') as FormArray).controls as FormGroup[];
  }

  public constructor(
    private route: ActivatedRoute,
    private store: Store,
  ) {}

  public async ngOnInit(): Promise<void> {
    const reportId = this.route.snapshot.params.id;
    const workOrder = await firstValueFrom(
      this.store
        .dispatch(new LoadReportBackItemDetails(reportId))
        .pipe(switchMap(() => this.reportBackItem$.pipe(first()))),
    );

    this.patchForm(workOrder);
  }

  public onSubmit(): void {
    this.updateReplacements();
  }

  public async cancelReportBack(): Promise<void> {
    try {
      this.isBusy = true;
      const workOrder: WorkOrder = await firstValueFrom(this.reportBackItem$.pipe(first()));
      await firstValueFrom(this.store.dispatch(new CancelReportBack(workOrder.id)));
    } finally {
      this.isBusy = false;
    }
  }

  public async reportBack(): Promise<void> {
    try {
      this.isBusy = true;
      const workOrder: WorkOrder = await firstValueFrom(this.reportBackItem$.pipe(first()));
      await firstValueFrom(
        this.store.dispatch(new ReportBack(workOrder.id, this.form.value as IReportBackReplacements)),
      );
    } finally {
      this.isBusy = false;
    }
  }

  private updateReplacements(): void {
    this.replacements = stringify(
      {
        replacements: this.form.value,
        date: new Date().getMilliseconds(),
      },
      {
        indices: true,
      },
    );
  }

  private patchForm(workOrder: IWorkOrder): void {
    const orderLines = (workOrder?.order?.orderLines ?? []).map((o: IOrderLine) => {
      return new FormGroup({
        id: new FormControl(o.id, [Validators.required]),
        customDescription: new FormControl(o.description, [Validators.required]),
      });
    });

    const products = (workOrder?.products ?? []).map((p) => {
      return new FormGroup({
        id: new FormControl(p.id, [Validators.required]),
        customDescription: new FormControl(p.description, [Validators.required]),
      });
    });

    this.form.setControl('orderLines', new FormArray(orderLines));
    this.form.setControl('products', new FormArray(products));
    this.form.patchValue({ customRemarks: workOrder.publicRemarks });
    this.updateReplacements();
  }
}
