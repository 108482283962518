import { DepositItem } from '../../models';

export class LoadDepositItems {
  public static readonly type = '[Deposit] Load deposit items';
}

export class OpenDepositItemsList {
  public static readonly type = '[Deposit] Open deposit items list';
}

export class RegisterDeposit {
  public static readonly type = '[Deposit] Register deposit';
  public constructor(public depositItem: DepositItem) {}
}

export class RefreshUserDepositItem {
  public static readonly type = '[Deposit] Refresh user deposit item';
  public constructor() {}
}

export class LoadMechanicDepositItem {
  public static readonly type = '[Deposit] Get deposit item by mechanic id';
  public constructor(public mechanicId: string) {}
}
