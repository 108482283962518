<form class="form" [formGroup]="productForm" (ngSubmit)="onSubmit()">
  <app-page-header title="Product toevoegen">
    <div class="actions ml-auto">
      <button type="submit" class="btn btn-primary ml-auto" [disabled]="productForm.invalid">
        <span *ngIf="!isBusy" class="text">opslaan</span>
        <span *ngIf="isBusy" class="loader"><img src="assets/img/loader-primary.svg" /></span>
      </button>
    </div>
  </app-page-header>

  <app-page-body>
    <div class="container">
      <ng-container *ngIf="!isBusy; else loading">
        <div class="row">
          <div class="col col-45 card">
            <div class="body py-2">
              <div class="form-group required">
                <label class="label">Artikelnummer</label>
                <input type="text" class="form-control" formControlName="articleNumber" placeholder="P-nummer" />
              </div>

              <div class="form-group required">
                <label class="label">Omschrijving</label>
                <textarea
                  class="form-control"
                  formControlName="description"
                  placeholder="Omschrijving van het product"
                  rows="5"></textarea>
              </div>
            </div>
          </div>

          <div class="col col-45 card">
            <div class="body py-2">
              <div class="form-group required">
                <label class="label">Prijs (incl. BTW)</label>
                <input
                  type="number"
                  step="0.01"
                  min="0"
                  class="form-control"
                  formControlName="price"
                  placeholder="Prijs" />
              </div>

              <div class="form-group required">
                <label class="label">Toon 'in bruikleen' optie</label>
                <select class="form-control" formControlName="onLoan">
                  <option [ngValue]="false">Nee</option>
                  <option [ngValue]="true">Ja</option>
                </select>
              </div>

              <div class="form-group required">
                <label class="label">Afbeeldingen</label>
                <tna-file-upload appFileType="productInternalImage" formControlName="images"></tna-file-upload>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </app-page-body>
</form>

<ng-template #loading>
  <div class="loader">
    <img class="loader" src="assets/img/loader-primary.svg" />
  </div>
</ng-template>
