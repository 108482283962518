<form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <app-page-header title="Werkzaamheden"></app-page-header>

  <app-page-body>
    <div class="container">
      <ng-container class="list" *ngIf="workOrder$ | async as workOrder; else loading">
        <div class="row">
          <div class="col card">
            <div class="body px-2">
              <div class="form-group">
                <label class="label">
                  Interne opmerking na uitvoering werkzaamheden
                  <small class="text-muted">(niet zichtbaar voor klant)</small>
                </label>
                <textarea class="form-control" formControlName="remarks" rows="5"></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col card">
            <div class="body px-2">
              <div class="form-group">
                <label class="label">
                  Publieke opmerking na uitvoering werkzaamheden
                  <small class="text-danger">(ZICHTBAAR VOOR KLANT)</small>
                </label>
                <textarea class="form-control" formControlName="publicRemarks" rows="5"></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-1">
          <div class="col card">
            <div class="header px-2 pt-2">
              <h2>Arbeid / materiaal</h2>
            </div>
            <div class="body p-2">
              <table>
                <thead>
                  <tr style="text-align: left">
                    <th class="title">Arbeid / materiaal</th>
                    <th class="quantity">Aantal</th>
                    <th class="price" *ngIf="!isCompany">Prijs</th>
                    <th class="remarks">Opmerking</th>
                    <th class="actions" style="width: 1%"></th>
                  </tr>
                </thead>
                <tbody formArrayName="products">
                  <tr *ngFor="let product of products.controls; let i = index" (click)="openProductModal(workOrder, i)">
                    <td class="title">
                      {{ product.value.description }}
                      <ng-container *ngIf="product.value.articleNumber">
                        ({{ product.value.articleNumber }})
                      </ng-container>
                    </td>
                    <td class="quantity">{{ product.value.quantity }}</td>
                    <td class="price" *ngIf="!isCompany && product.value['onLoan'] !== true">
                      <div
                        tna-money
                        [amount]="product.value.price * product.value.quantity"
                        *ngIf="product.value['price'] !== null"></div>
                      <div *ngIf="product.value['price'] === null">Nader te bepalen</div>
                      <div *ngIf="product.value.onLoan">In bruikleen</div>
                    </td>
                    <td class="remarks">{{ product.value.remarks }}</td>
                    <td class="actions">
                      <a
                        class="btn btn-primary btn-icon btn-outline btn-sm ml-auto"
                        (click)="deleteProduct($event, product)">
                        <img src="/assets/img/trash.svg" />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="footer mt-2">
                <div class="actions">
                  <a class="btn btn-primary btn-sm add-btn" (click)="openProductModal(workOrder)">toevoegen</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-1">
          <div class="col card">
            <div class="body p-2">
              <ul class="thumbnails">
                <li *ngFor="let thumbnail of thumbnails; let index = index">
                  <ng-container *ngIf="thumbnail | async as thumb">
                    <div class="thumbnail-container">
                      <img [src]="thumb" class="thumb" />
                      <a class="btn btn-primary btn-delete btn-outline btn-sm btn-icon">
                        <img src="/assets/img/trash.svg" />
                      </a>
                    </div>
                  </ng-container>
                </li>
              </ul>

              <div>
                <tna-file-upload appFileType="workOrderImage" formControlName="images"></tna-file-upload>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col card">
            <div class="body px-2">
              <div class="form-group required">
                <label class="label">Moeten er producten besteld worden?</label>
                <select class="form-control" #orderProducts formControlName="orderProducts">
                  <option [ngValue]="false">Nee</option>
                  <option [ngValue]="true">Ja</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-1">
          <div class="col card">
            <div class="body p-2">
              <button type="submit" class="btn btn-success ml-auto" [disabled]="form.invalid">
                <ng-container *ngIf="form.value.orderProducts === true">Naar bestelformulier</ng-container>
                <ng-container *ngIf="form.value.orderProducts === false">Werkbon afronden</ng-container>
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </app-page-body>
</form>

<ng-template #loading>
  <div class="loader">
    <img class="loader" src="assets/img/loader-primary.svg" />
  </div>
</ng-template>
