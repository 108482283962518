import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';

import { RefillableProduct, StockItem } from '@vandelft/modules/shared/models';
import { StockService } from '@vandelft/modules/shared/services/stock.service';
import { OpenStockList } from '@vandelft/modules/shared/state/stock';
import { Store } from '@ngxs/store';
import { AppEventsService } from '@vandelft/modules/shared/services/app-events.service';
import { plainToInstance } from 'class-transformer';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
})
export class FormComponent implements OnInit {
  public stockItem: StockItem;
  public isBusy = false;

  public stockItemForm = new FormGroup({
    products: new FormArray([]),
  });

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private store: Store,
    private appEventsService: AppEventsService,
    private stockService: StockService,
  ) {}

  async ngOnInit(): Promise<void> {
    const userId = this.route.snapshot.paramMap.get('id');
    this.stockItem = await firstValueFrom(this.stockService.getStockItemByMechanicId(userId).pipe(first()));

    for (const product of this.stockItem.products) {
      this.addProduct(product);
    }
  }

  public addProduct(product: RefillableProduct): void {
    const data = { ...product, refilled: product.quantity };
    const formGroup = this.fb.group(data);
    this.products.push(formGroup);
  }

  public get products(): FormArray {
    return this.stockItemForm.get('products') as FormArray;
  }

  public async onSubmit(): Promise<void> {
    const data = [...this.stockItemForm.value.products];
    const refillableProducts = [...data];
    const stockItemData = {
      ...this.stockItem,
      products: refillableProducts,
    };
    const stockItem = plainToInstance(StockItem, stockItemData);
    await firstValueFrom(
      this.appEventsService.save({
        event: 'mechanic.refilled',
        payload: stockItem,
      }),
    );
    this.store.dispatch(new OpenStockList());
  }
}
