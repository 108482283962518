import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { CLIENT_TYPES } from '@vandelft/modules/shared/constants';
import { Report } from '@vandelft/modules/shared/models';
import { ReportsState, LoadReport, ApproveReport } from '@vandelft/modules/shared/state/reports';

@Component({
  selector: 'app-reports-approve',
  templateUrl: './approve.component.html',
})
export class ApproveComponent {
  @Select(ReportsState.report)
  public report$: Observable<Report>;

  public clientTypeMapping = {};

  public constructor(private route: ActivatedRoute, private store: Store) {
    const reportId = this.route.snapshot.paramMap.get('id');

    for (const clientType of CLIENT_TYPES) {
      this.clientTypeMapping[clientType.key] = clientType.label;
    }

    this.store.dispatch([new LoadReport(reportId)]);
  }

  public approve(report: Report): void {
    this.store.dispatch(new ApproveReport(report));
  }
}
