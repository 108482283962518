import { IPayment, IQuantifiedProduct } from '../interfaces';

export class PaymentsHelper {
  public static getProductPriceExc(product: IQuantifiedProduct): number {
    return product.price;
  }

  public static getTotalPayed(payments: IPayment[]): number {
    return payments.reduce((acc: any, payment: any) => {
      return acc + payment.amount;
    }, 0);
  }
}
