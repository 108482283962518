import { Component, QueryList, AfterContentInit, ContentChildren } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { WizardStepComponent } from '../wizard-step';
import { SetProgress, ProgressState } from '@vandelft/modules/shared/state/progress';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
})
export class WizardComponent implements AfterContentInit {
  @ContentChildren(WizardStepComponent)
  public steps!: QueryList<WizardStepComponent>;

  @Select(ProgressState)
  public progress$: Observable<number>;

  public constructor(private store: Store) {}

  public ngAfterContentInit(): void {
    this.updateStore();
    this.steps.changes.subscribe(() => this.updateStore());
    this.progress$.subscribe(() => this.updateActive());
  }

  private updateStore(): void {
    const steps = [];
    for (const step of this.steps) {
      const stepData = { title: step.title };
      steps.push(stepData);
    }

    this.store.dispatch(new SetProgress(1, steps.length));
  }

  private updateActive(): void {
    const currentActive = this.store.selectSnapshot(ProgressState.current);
    this.steps.forEach((item, index) => {
      item.setState(index + 1 === currentActive);
    });
  }
}
