<app-page-header title="Financieel">
  <div class="actions ml-auto">
    <a
      class="btn btn-primary"
      download
      href="{{ apiUrl }}/invoices/export?token={{ token$ | async }}&{{ filterQs$ | async }}">
      <img class="icon" src="assets/img/excel.svg" />
      Exporteren
    </a>
  </div>
</app-page-header>

<div class="container">
  <div class="card">
    <div class="body">
      <form class="form" [formGroup]="filterForm">
        <div class="row">
          <div class="col col-35">
            <div class="form-group">
              <label class="label">Periode</label>
              <div class="input-group">
                <input type="date" class="form-control" formControlName="start" />
                <input type="date" class="form-control ml-1" formControlName="end" />
              </div>
            </div>
          </div>
          <div class="col col-32">
            <div class="form-group ml-1">
              <label class="label">Betaalmethode</label>
              <select class="form-control" formControlName="paymentMethod">
                <option [ngValue]="null">-- Alles --</option>
                <option value="pin">Pin</option>
                <option value="cash">Contant</option>
                <option value="on-account">Op rekening</option>
              </select>
            </div>
          </div>
          <div class="col col-35">
            <div class="form-group ml-1">
              <label class="label">Monteur</label>
              <select class="form-control" formControlName="mechanic" *ngIf="mechanics$ | async as mechanics">
                <option [ngValue]="null">-- Alles --</option>
                <option *ngFor="let mechanic of mechanics" [value]="mechanic.id">{{ mechanic.fullName }}</option>
              </select>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="card mt-3">
    <div class="body">
      <ul class="list" *ngIf="workOrders$ | async as workOrders; else loading">
        <li *ngFor="let workOrder of workOrders" (click)="open(workOrder)" class="py-2">
          <div class="details">
            <div class="row">
              <div class="col">
                <div class="bold">
                  {{ workOrder.invoiceNumber }} - {{ workOrder.report.client.clientAddress.contact }}
                  <ng-container *ngIf="workOrder.report.company">
                    (via: {{ workOrder.report.company.name }})
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="muted row">
              <div class="col col-40">
                <i>Adres</i>
                {{ workOrder.report.client.clientAddress.streetAndHouseNumber }}
                <br />
                {{ workOrder.report.client.clientAddress.postalCode }} {{ workOrder.report.client.clientAddress.city }}
              </div>

              <div class="col col-35">
                <i>Uitvoering</i>
                {{ workOrder.mechanic?.fullName }}
                <br />
                {{ workOrder.executed | date: 'dd-MM-yyyy HH:mm' }}
              </div>

              <div class="col col-25">
                <div class="ml-auto">
                  <div><div tna-money [amount]="workOrder.totalPriceInc"></div></div>
                  <div class="muted"><div tna-money [amount]="workOrder.totalPayed"></div></div>
                  <div class="muted"><div tna-money [amount]="workOrder.totalOpen"></div></div>
                </div>
              </div>
            </div>
            <div class="row mt-1">
              <div class="badges">
                <div class="badge" [ngClass]="{ 'badge-completed': workOrder['isPaid'] }">betaald</div>
                <ng-container *ngIf="workOrder.report.client.type === 'company'">
                  <div class="badge badge-on-account">Op rekening</div>
                </ng-container>
                <ng-container *ngIf="workOrder.payments">
                  <ng-container *ngIf="workOrder.report.client.type !== 'company'">
                    <div *ngFor="let payment of workOrder.payments" class="badge badge-{{ payment.method }}">
                      <span *ngIf="payment.method === 'cash'">Contant</span>
                      <span *ngIf="payment.method === 'pin'">Pin</span>
                      <span *ngIf="payment.method === 'on-account'">Op rekening</span>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="loader">
    <img class="loader" src="assets/img/loader-primary.svg" />
  </div>
</ng-template>
