import { IQuantifiedProduct } from '../interfaces/quantified-product';

export class QuantifiedProductHelper {
  public static getProductPriceExc(product: IQuantifiedProduct): number {
    const priceInc = QuantifiedProductHelper.getProductPriceInc(product);
    const tax = QuantifiedProductHelper.getProductTax(product);
    return priceInc - tax;
  }

  public static getProductTax(product: IQuantifiedProduct): number {
    if (product.onLoan) {
      return 0;
    }

    const priceInc = QuantifiedProductHelper.getProductPriceInc(product);
    return (priceInc / 121) * 21;
  }

  public static getProductPriceInc(product: IQuantifiedProduct): number {
    if (product.onLoan) {
      return 0;
    }

    return product.price;
  }

  public static getTotalProductPriceExc(product: IQuantifiedProduct): number {
    const priceExc = QuantifiedProductHelper.getProductPriceExc(product);
    return priceExc * product.quantity;
  }

  public static getTotalProductTax(product: IQuantifiedProduct): number {
    const tax = QuantifiedProductHelper.getProductTax(product);
    return tax * product.quantity;
  }

  public static getTotalProductPriceInc(product: IQuantifiedProduct): number {
    const priceInc = QuantifiedProductHelper.getProductPriceInc(product);
    return priceInc * product.quantity;
  }

  public static getTotalPriceExc(products: IQuantifiedProduct[]): number {
    return products.reduce((acc: any, product: any) => {
      const priceExc = QuantifiedProductHelper.getTotalProductPriceExc(product);
      return acc + priceExc;
    }, 0);
  }

  public static getTotalTax(products: IQuantifiedProduct[]): number {
    return products.reduce((acc: any, product: any) => {
      const tax = QuantifiedProductHelper.getTotalProductTax(product);
      return acc + tax;
    }, 0);
  }

  public static getTotalPriceInc(products: IQuantifiedProduct[]): number {
    return products.reduce((acc: any, product: any) => {
      const priceInc = QuantifiedProductHelper.getTotalProductPriceInc(product);
      return acc + priceInc;
    }, 0);
  }
}
