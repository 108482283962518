import { Expose, Exclude } from 'class-transformer';

import { IUser } from '../../../shared/interfaces';
import { UserHelper } from '../../../shared/helpers';

@Exclude()
export class User implements IUser {
  @Expose()
  public id: string;

  @Expose()
  public username: string;

  @Expose()
  public firstName: string;

  @Expose()
  public middleName: string;

  @Expose()
  public lastName: string;

  @Expose()
  public lastRefill: Date;

  @Expose()
  public lastDeposit: Date;

  @Expose()
  public role: string;

  @Expose()
  public modified: Date;

  @Expose()
  public created: Date;

  @Expose()
  public deleted: Date;

  public get fullName(): string {
    return UserHelper.getFullName(this);
  }
}
