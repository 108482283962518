import { VERSION } from '../version';

export const environment = {
  production: true,
  apiUrl: 'https://werkbonnen-api.vandelftslotenmaker.nl',
  version: VERSION,
  firebase: {
    apiKey: 'AIzaSyDX2ncBS24iD1svKOlUK8I5e13X7D8VAKA',
    authDomain: 'van-delft-werkbonnen-prod.firebaseapp.com',
    projectId: 'van-delft-werkbonnen-prod',
    storageBucket: 'van-delft-werkbonnen-prod.appspot.com',
    messagingSenderId: '694313039816',
    appId: '1:694313039816:web:eca4bb73c225df3584b2fd',
  },
};
