<app-page-header title="Terugmeldingen"></app-page-header>

<div class="container">
  <ng-container *ngIf="ReportBackItems$ | async as ReportBackItems; else loading">
    <ul class="list">
      <div class="no-results" *ngIf="ReportBackItems?.length === 0">Er zijn geen terugmeldingen gevonden</div>

      <div (itemClicked)="view($event)" app-work-orders-list [workOrders]="ReportBackItems"></div>
    </ul>
  </ng-container>
</div>

<ng-template #loading>
  <div class="loader">
    <img class="loader" src="assets/img/loader-primary.svg" />
  </div>
</ng-template>
