<ng-container *ngIf="summary?.workOrder">
  <div class="container">
    <div class="row">
      <div class="col col-50">
        <div class="summary-container">
          <div class="summary-content">
            <div>
              <b>{{ summary.workOrder.planned | date: 'dd-MM-yyyy' }}</b>
            </div>
            <div>
              <b>Type:</b>
              <span *ngIf="summary?.workOrder?.client.type == 'company'">Zakelijk</span>
              <span *ngIf="summary?.workOrder?.client.type == 'private'">Particulier</span>
            </div>
            <div>
              <b>{{ summary.workOrder.client.fullName }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
