import { Component, Input } from '@angular/core';
import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'tna-confirm-modal',
  templateUrl: './confirm-modal.component.html',
})
export class ConfirmModalComponent {
  @Input()
  public message = 'Weet u het zeker?';

  @Input()
  public confirmText = 'Ja';

  @Input()
  public cancelText = 'Nee';

  constructor(private modalService: ModalService) {}

  public onConfirm(): void {
    this.modalService.close(true);
  }

  public onCancel(): void {
    this.modalService.close(false);
  }
}
