import { Order } from '../../models';

export class OpenOrdersList {
  public static readonly type = '[Orders] Open orders list';
}

export class OpenOrdersDetails {
  public static readonly type = '[Orders] Open order detail';
  public constructor(public order: Order) {}
}

export class LoadOrders {
  public static readonly type = '[Orders] Load orders';
  public constructor(public filters?: any) {}
}

export class LoadOrder {
  public static readonly type = '[Orders] Load order';
  public constructor(public id: string) {}
}
