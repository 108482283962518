import { IOrder } from '../interfaces';
import { materials, doorTypes, yesOrNo, backsetLocks, spacingLocks } from '../constants';

export class OptionsHelper {
  public static getSelectedOptions(order: IOrder): any {
    return {
      doorMaterial: OptionsHelper.getLabel(materials, order.doorMaterial),
      frameMaterial: OptionsHelper.getLabel(materials, order.frameMaterial),
      doorType: OptionsHelper.getLabel(doorTypes, order.doorType),
      cylinderOperated: OptionsHelper.getLabel(yesOrNo, order.cylinderOperated, true),
      backsetLock: OptionsHelper.getLabel(backsetLocks, order.backsetLock),
      spacingLock: OptionsHelper.getLabel(spacingLocks, order.spacingLock),
      keyRepeatOrder: OptionsHelper.getLabel(yesOrNo, order.keyRepeatOrder, true),
      cylinder: OptionsHelper.getLabel(yesOrNo, order.cylinder, true),
    };
  }

  public static getLabel(options: any[], value: any, isBoolean?: boolean): string {
    const match = options.find((i: any) => {
      if (isBoolean) {
        return i.value === (value === 1);
      }
      return i.value === value;
    });
    return match?.label;
  }
}
