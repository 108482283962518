import { Message } from '@vandelft/modules/shared/models';

export class OpenChatOverview {
  public static readonly type = '[Messages] Open messages overview';
}

export class OpenChat {
  public static readonly type = '[Messages] Open chat screen';
  public constructor(public addressId: string) {}
}

export class EditMessage {
  public static readonly type = '[Messages] Open edit message screen';
  public constructor(public message: Message) {}
}

export class MarkChatAsRead {
  public static readonly type = '[Messages] Mark chat as read';
  public constructor(public addressId: string) {}
}

export class LoadMessageTemplates {
  public static readonly type = '[Messages] Load Message templates';
  public constructor() {}
}

export class LoadChats {
  public static readonly type = '[Messages] Load Messages';
  public constructor() {}
}

export class LoadChatQueue {
  public static readonly type = '[Messages] Load Messages queue';
  public constructor() {}
}

export class LoadChat {
  public static readonly type = '[Messages] Load chat';
  public constructor(public addressId: string) {}
}

export class LoadMessage {
  public static readonly type = '[Messages] Load message';
  public constructor(public id: string) {}
}

export class SendMessage {
  public static readonly type = '[Messages] Send message';
  public constructor(public message: Partial<Message>) {}
}

export class UpdateMessage {
  public static readonly type = '[Messages] Update message';
  public constructor(public message: Partial<Message>) {}
}

export class DeleteMessage {
  public static readonly type = '[Messages] Delete message';
  public constructor(public id: string) {}
}
