import { Expose, Exclude } from 'class-transformer';

import { Product } from './product';

@Exclude()
export class QuantifiedProduct extends Product {
  @Expose()
  public productId: string;

  @Expose()
  public quantity: number;

  @Expose()
  public remarks: string;

  public get totalExc(): number {
    return this.price * this.quantity;
  }

  public get totalTax(): number {
    return this.tax * this.quantity;
  }

  public get totalInc(): number {
    return this.totalExc + this.totalTax;
  }
}
