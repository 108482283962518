<app-page-header title="PDF exporteren">
  <div class="actions ml-auto">
    <a
      href="{{ apiUrl }}/reports/mechanic/{{ mechanicId }}/download?date={{ date }}&token={{ token$ | async }}"
      target="_blank"
      class="btn btn-primary ml-auto">
      <span class="text">Download</span>
    </a>
  </div>
</app-page-header>

<div class="container">
  <div class="card mt-3">
    <div class="body">
      <ngx-extended-pdf-viewer
        src="{{ apiUrl }}/reports/mechanic/{{ mechanicId }}/download?date={{ date }}&token={{ token$ | async }}"
        [showToolbar]="true"
        [showSidebarButton]="false"
        [showFindButton]="false"
        [showPagingButtons]="true"
        [showZoomButtons]="true"
        [showPresentationModeButton]="false"
        [showOpenFileButton]="false"
        [showPrintButton]="true"
        [showDownloadButton]="true"
        [showSecondaryToolbarButton]="true"
        [showRotateButton]="false"
        [showHandToolButton]="false"
        [showScrollingButton]="false"
        [showSpreadButton]="false"
        [showPropertiesButton]="false"
        backgroundColor="#ffffff"
        [showBorders]="true"
        height="81rem"></ngx-extended-pdf-viewer>
    </div>
  </div>
</div>
