import { Expose, Exclude, Type } from 'class-transformer';

import { IAddress } from '@vandelft/shared/interfaces';

@Exclude()
export class Address implements IAddress {
  @Expose()
  @Type(() => String)
  public id: string | null;

  @Expose()
  public contact: string | null;

  @Expose()
  public street: string | null;

  @Expose()
  public houseNumber: string | null;

  @Expose()
  public houseNumberSuffix: string | null;

  @Expose()
  public postalCode: string | null;

  @Expose()
  public city: string | null;

  @Expose()
  public email: string | null;

  @Expose()
  public phoneNumber: string | null;

  @Expose()
  public phoneNumber2: string | null;

  public get streetAndHouseNumber(): string {
    return `${this.street} ${this.houseNumber}${this.houseNumberSuffix || ''}`.trim();
  }

  public get fullAddress(): string {
    return `${this.streetAndHouseNumber}+${this.postalCode}+${this.city}`;
  }
}
