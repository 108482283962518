import { AuthState } from './auth';
import { CompaniesState } from './companies';
import { DepositState } from './deposit';
import { EnvironmentState } from './environment';
import { ErrorState } from './error';
import { ReportBackItemsState } from './report-back-items';
import { ModulesState } from './modules';
import { ProductsState } from './products';
import { ProgressState } from './progress';
import { ReportsState } from './reports';
import { SyncState } from './sync';
import { ExternalInvoicesState } from './external-invoices';
import { InvoicesState } from './invoices';
import { UsersState } from './users';
import { WorkOrdersState } from './work-orders';
import { OrdersState } from './orders';
import { StockState } from './stock';
import { WorkOrderDraftState } from './work-order-draft';
import { OrderedProductsState } from './ordered-products';
import { MessagesState } from '@vandelft/modules/shared/state/messages';

export const statesList = [
  AuthState,
  DepositState,
  EnvironmentState,
  ErrorState,
  ReportBackItemsState,
  CompaniesState,
  InvoicesState,
  ExternalInvoicesState,
  ModulesState,
  ProgressState,
  ProductsState,
  ReportsState,
  SyncState,
  UsersState,
  StockState,
  OrdersState,
  WorkOrdersState,
  WorkOrderDraftState,
  OrderedProductsState,
  MessagesState,
];
