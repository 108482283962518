import { Component, Input } from '@angular/core';

@Component({
  selector: '[tna-money]',
  templateUrl: './money.component.html',
})
export class MoneyComponent {
  @Input()
  public amount;

  @Input()
  public alignRight = true;
}
