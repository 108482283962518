<app-page-header title="Werkbonnen">
  <div class="actions ml-auto">
    <a
      class="btn btn-primary"
      download
      href="{{ apiUrl }}/work-orders/export?token={{ token$ | async }}&{{ filterQs$ | async }}">
      <img class="icon" src="assets/img/excel.svg" />
      Exporteren
    </a>
  </div>
</app-page-header>

<div class="container">
  <div class="card">
    <div class="body">
      <form class="form" [formGroup]="filterForm">
        <div class="row">
          <div class="col col-65">
            <div class="form-group">
              <label class="label">Periode</label>
              <div class="input-group">
                <input type="date" class="form-control" formControlName="start" />
                <input type="date" class="form-control ml-1" formControlName="end" />
              </div>
            </div>
          </div>
          <div class="col col-32">
            <div class="form-group">
              <label class="label">Betaalmethode</label>
              <select class="form-control" formControlName="paymentMethod">
                <option [ngValue]="null">-- Alles --</option>
                <option value="pin">Pin</option>
                <option value="cash">Contant</option>
              </select>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="card mt-3">
    <div class="body">
      <ng-container *ngIf="workOrders$ | async as workOrders; else loading">
        <div app-work-orders-list [workOrders]="workOrders"></div>
        <div class="p-2" *ngIf="workOrders?.length === 0">Er zijn geen werbonnen gevonden</div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="loader">
    <img class="loader" src="assets/img/loader-primary.svg" />
  </div>
</ng-template>
