import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

import { StateFilterPipe } from './pipes/state-filter.pipe';
import { declarations } from './components';
import { NgxPaginationModule } from 'ngx-pagination';
import { TextFieldModule } from '@angular/cdk/text-field';

@NgModule({
  declarations: [...declarations, StateFilterPipe],
  exports: declarations,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxMaterialTimepickerModule,
    NgxExtendedPdfViewerModule,
    NgxPaginationModule,
    TextFieldModule,
  ],
})
export class SharedModule {}
