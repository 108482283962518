import { Component } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { WorkOrder } from '@vandelft/modules/shared/models';
import {
  ReportBackItemsState,
  OpenReportBackItemDetails,
  LoadReportBackItems,
} from '@vandelft/modules/shared/state/report-back-items';

@Component({
  selector: 'app-report-back-items-list',
  templateUrl: './list.component.html',
})
export class ListComponent {
  @Select(ReportBackItemsState.ReportBackItems)
  public ReportBackItems$: Observable<WorkOrder[]>;

  public constructor(private store: Store) {
    this.store.dispatch(new LoadReportBackItems());
  }

  public view({ workOrder }): Observable<any> {
    return this.store.dispatch(new OpenReportBackItemDetails(workOrder.id));
  }
}
