import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map, switchMap, toArray } from 'rxjs/operators';

import { Product } from '../models';

import { environment } from '../../../../environments/environment';

import { transformModel } from '../rxjs/operators/transform-model';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  public constructor(private http: HttpClient) {}

  public getProducts(): Observable<Product[]> {
    return this.http.get(`${environment.apiUrl}/products`).pipe(
      switchMap((data: Product[]) => from(data)),
      transformModel<Product>(Product),
      toArray(),
    );
  }

  public getProductsByQuery(query: string): Observable<Product[]> {
    return this.getProducts().pipe(
      map((products) => {
        return products.filter((p) => {
          const parts = [p.articleNumber, p.description];
          return parts.join(' ').toLowerCase().includes(query.toLowerCase());
        });
      }),
    );
  }

  public getProductById(id: string): Observable<Product> {
    return this.http.get<Product>(`${environment.apiUrl}/products/${id}`).pipe(transformModel<Product>(Product));
  }
}
