<div class="form" *ngIf="report$ | async as report">
  <div class="container px-2">
    <div class="card">
      <div class="body py-2">
        <div class="row">
          <div class="col">
            <div class="form-group plain-text">
              {{ report.client?.clientAddress?.contact }} ({{ clientTypeMapping[report.client?.type] }})
              <br />
              {{ report.client?.clientAddress?.street }}
              {{ report.client?.clientAddress?.houseNumber }}
              {{ report.client?.clientAddress?.houseNumberSuffix }}
              <br />
              {{ report.client?.clientAddress?.postalCode }} {{ report.client?.clientAddress?.city }}
              <br />
              <a href="tel:{{ report.client?.clientAddress?.phoneNumber }}">
                {{ report.client?.clientAddress?.phoneNumber }}
              </a>
              <ng-container *ngIf="report.client?.clientAddress?.phoneNumber2">
                <br />
                <a href="tel:{{ report.client?.clientAddress?.phoneNumber2 }}">
                  {{ report.client?.clientAddress?.phoneNumber2 }}
                </a>
              </ng-container>
              <ng-container *ngIf="report?.company">
                <br />
                <br />
                via {{ report.company.name }}
              </ng-container>
              <ng-container *ngIf="report?.reference">
                <br />
                <br />
                Referentie: {{ report.reference }}
              </ng-container>
            </div>
          </div>
          <div class="col">
            <div class="form-group plain-text">
              {{ report.client?.effectiveLocationAddress?.contact }}
              <br />
              {{ report.client?.effectiveLocationAddress?.street }}
              {{ report.client?.effectiveLocationAddress?.houseNumber }}
              {{ report.client?.effectiveLocationAddress?.houseNumberSuffix }}
              <br />
              {{ report.client?.effectiveLocationAddress?.postalCode }}
              {{ report.client?.effectiveLocationAddress?.city }}
              <ng-container *ngIf="report.client?.effectiveLocationAddress?.phoneNumber2">
                <br />
                <a href="tel:{{ report.client?.effectiveLocationAddress?.phoneNumber2 }}">
                  {{ report.client?.effectiveLocationAddress?.phoneNumber2 }}
                </a>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-1 mb-3">
      <div class="col card">
        <div class="body">
          <div class="invoice-header border-top border-bottom">
            <div class="row border-bottom">
              <div class="col bold">Aangemaakt op</div>
              <div class="col bold">Door</div>
            </div>

            <div class="row">
              <div class="col">
                {{ report.created | date: 'dd-MM-yyyy':'utc' }} &#64; {{ report.created | date: 'HH:mm' }}
              </div>
              <div class="col">{{ report.user?.fullName || 'Onbekend' }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-1 mb-3">
      <div class="col card">
        <div class="body">
          <div class="invoice-header border-top border-bottom">
            <div class="row border-bottom">
              <div class="col bold">Gepland op</div>
              <div class="col bold">Monteur</div>
            </div>

            <div class="row">
              <div class="col">
                {{ report.plannedStart | date: 'dd-MM-yyyy':'utc' }}
                &#64; {{ report.plannedStart | date: 'HH:mm':'utc' }} - {{ report.plannedEnd | date: 'HH:mm':'utc' }}
              </div>
              <div class="col">{{ report.mechanic?.fullName || 'Onbekend' }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-1 mb-3">
      <div class="col card">
        <div class="body">
          <div class="invoice-header border-top border-bottom">
            <div class="row border-bottom">
              <div class="col bold">Omschrijving werkzaamheden</div>
            </div>

            <div class="row">
              <div class="col">{{ report.description || 'Geen' }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="report.images?.length > 0">
      <div class="row mt-1 mb-3" *ngIf="token$ | async as token">
        <div class="col card">
          <div class="body">
            <div class="invoice-header border-top border-bottom">
              <div class="row border-bottom">
                <div class="col bold">Interne bestanden</div>
              </div>

              <div class="row">
                <div class="thumbnails-list">
                  <ng-container *ngFor="let image of report.images">
                    <div class="image-container">
                      <a href="{{ apiUrl }}/images/{{ image.id }}?token={{ token }}" target="_blank">
                        <img src="{{ apiUrl }}/images/{{ image.id }}/thumbnail?token={{ token }}" />
                      </a>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="card mt-3" *ngIf="report.workOrders?.length > 0 || report.notes?.length > 0">
      <h2 class="mb-3">Deze melding</h2>
      <div class="body read-only">
        <h3 class="mb-2">Notities</h3>
        <form class="form mb-3" [formGroup]="notesForm" (ngSubmit)="addNote()">
          <div class="form-group">
            <textarea class="form-control" formControlName="note" rows="5"></textarea>
          </div>

          <div class="row">
            <label class="btn btn-primary add-photo">
              <ng-container *ngIf="notesForm.value.attachment?.name; else defaultText">
                {{ notesForm.value.attachment?.name }}
              </ng-container>
              <ng-template #defaultText>Bijlage toevoegen</ng-template>
              <input type="file" multiple="false" (change)="onAttachmentSelected($event)" />
            </label>
            <button type="submit" class="btn btn-success ml-auto" [disabled]="notesForm.invalid">
              Notitie toevoegen
            </button>
          </div>
        </form>
        <div class="timeline">
          <app-timeline-note
            class="timeline-item"
            *ngFor="let note of report.notes"
            [note]="note"
            [token]="token$ | async"
            [apiUrl]="apiUrl"></app-timeline-note>
        </div>
      </div>
      <div class="body read-only" *ngIf="report.workOrders?.length > 0">
        <h3 class="mb-2">Werkbonnen</h3>
        <div class="timeline">
          <app-timeline-work-order
            class="timeline-item"
            *ngFor="let workOrder of report.workOrders"
            (edit)="onEditWorkOrder($event)"
            [workOrder]="workOrder"
            [report]="report"
            [token]="token$ | async"
            [apiUrl]="apiUrl"
            [user]="user$ | async"></app-timeline-work-order>
        </div>
      </div>
    </div>

    <div class="card mt-3" *ngIf="report.related?.length > 0">
      <div class="body read-only">
        <h2 class="mb-2">Gerelateerde meldingen (o.b.v. adres)</h2>
        <ng-container *ngFor="let relatedReport of report.related">
          <div class="timeline">
            <app-timeline-work-order
              class="timeline-item"
              *ngFor="let workOrder of relatedReport.workOrders"
              [workOrder]="workOrder"
              [report]="report"
              [token]="token$ | async"
              [apiUrl]="apiUrl"
              [user]="user$ | async"></app-timeline-work-order>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="card mt-3" *ngIf="report.workOrders">
      <div class="body read-only" *ngIf="token$ | async as token">
        <ul class="thumbnails">
          <ng-container *ngFor="let workOrder of report.workOrders">
            <ng-container *ngFor="let img of workOrder.images">
              <li *ngIf="img.model === 'Report'">
                <div class="thumbnail-container">
                  <img [src]="apiUrl + '/images/' + img.id + '?token=' + token" class="thumb" />
                </div>
              </li>
            </ng-container>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</div>
