<div class="app-page-header">
  <div class="page-title">
    <div class="container">
      <h1>Gebruikers</h1>
      <div class="actions ml-auto">
        <a class="btn btn-primary ml-auto" (click)="addItem()">toevoegen</a>
      </div>
    </div>
  </div>
</div>
<div class="container py-1">
  <div class="card">
    <div class="body">
      <ul class="list" *ngIf="companies$ | async as companies; else loading">
        <li *ngFor="let company of companies" class="py-1">
          <a (click)="selectItem(company)">
            <div class="details">
              <div class="bold">{{ company.name }}</div>
            </div>

            <div class="actions">
              <a class="btn btn-primary btn-outline btn-sm btn-icon" (click)="deleteCompany($event, company)">
                <img src="/assets/img/trash.svg" />
              </a>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="loader">
    <img class="loader" src="assets/img/loader-primary.svg" />
  </div>
</ng-template>
