import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DepositItem } from '@vandelft/modules/shared/models';
import { Select, Store } from '@ngxs/store';
import { DepositState, LoadDepositItems } from '@vandelft/modules/shared/state/deposit';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
})
export class ListComponent {
  @Select(DepositState.depositItems)
  public depositItems$: Observable<DepositItem[]>;

  public constructor(private router: Router, private route: ActivatedRoute, private store: Store) {
    this.store.dispatch(new LoadDepositItems());
  }

  public async show(id: string): Promise<void> {
    await this.router.navigate([id], {
      relativeTo: this.route,
    });
  }
}
