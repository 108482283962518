import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';
import * as moment from 'moment/moment';
import { v4 as uuid } from 'uuid';

import { ConfirmModalComponent } from '@vandelft/modules/shared/components/ui/confirm-modal';
import { ModalService } from '@vandelft/modules/shared/services/modal.service';

import { Report, WorkOrder, WorkOrderProduct } from '@vandelft/modules/shared/models';
import { ProductFormModalComponent } from '@vandelft/modules/shared/components/work-orders/product-form-modal';
import { DraftBaseFormComponent } from '../draft-base-form';
import {
  OpenDraftCompletionForm,
  OpenDraftOrderProductForm,
  PatchWorkOrderDraft,
  SaveWorkOrderDraft,
  WorkOrderDraftState,
} from '@vandelft/modules/shared/state/work-order-draft';
import { SetProgress } from '@vandelft/modules/shared/state/progress';

@Component({
  selector: 'app-draft-activities-form',
  templateUrl: 'draft-activities-form.component.html',
})
export class DraftActivitiesFormComponent extends DraftBaseFormComponent implements OnInit {
  public isCompany = false;

  public report: Report;

  public form = new FormGroup({
    executed: new FormControl(null, [Validators.required]),
    remarks: new FormControl(null),
    publicRemarks: new FormControl(null),
    orderProducts: new FormControl(false),
    products: new FormArray([]),
    images: new FormControl([]),
  });

  public thumbnails = [];

  public constructor(
    protected route: ActivatedRoute,
    protected store: Store,
    private modalService: ModalService,
  ) {
    super(route, store);
  }

  public get products(): FormArray {
    return this.form.get('products') as FormArray;
  }

  public ngOnInit(): void {
    this.store.dispatch(new SetProgress(2, 4));
  }

  public initForm(workOrder: WorkOrder): void {
    if (!workOrder || !this.form) {
      return;
    }

    this.report = workOrder.report;

    this.isCompany = this.report.client.type === 'company';
    this.form.reset();
    this.products.clear();
    this.thumbnails = [];

    this.form.get('images').clearValidators();
    if (this.isCompany) {
      this.form.get('images').setValidators([Validators.required]);
    }

    this.form.patchValue({
      ...workOrder,
      orderProducts: !!workOrder?.order?.id,
      executed: moment(workOrder.executed).format('YYYY-MM-DD'),
    });

    for (const product of workOrder.products) {
      this.addProduct(product);
    }
  }

  public async openProductModal(workOrder: WorkOrder, index = null): Promise<void> {
    const formGroup = this.products.controls[index];
    const modalRef = this.modalService.open(ProductFormModalComponent);
    modalRef.componentInstance.isCompany = this.isCompany;
    modalRef.componentInstance.workOrder = workOrder;

    if (formGroup) {
      modalRef.componentInstance.product = {
        ...formGroup.value,
        id: formGroup.get('productId').value,
      };
    }

    const result = await modalRef.result;

    if (!result) {
      return;
    }

    if (result.priceLater) {
      result.price = null;
    }

    delete result.priceLater;

    if (formGroup) {
      formGroup.patchValue({
        ...result,
        productId: result.id,
        id: formGroup.get('id').value,
      });
      return;
    }

    this.addProduct({
      ...result,
      productId: result.id,
      id: uuid(),
    });
  }

  public async deleteProduct(e: Event, product: AbstractControl): Promise<void> {
    e.preventDefault();
    e.stopPropagation();

    if (this.modalService.activeModal) {
      this.modalService.close();
    }

    const modalRef = this.modalService.open(ConfirmModalComponent, {
      modalType: 'modal-floating-confirmation',
      barrier: false,
      alignment: {
        element: e.target,
      },
    });

    modalRef.componentInstance.message = `Weet u zeker dat u ${product.value.description} wilt verwijderen?`;

    const result = await modalRef.result;

    if (!result) {
      return;
    }

    const index = this.products.controls.indexOf(product);
    this.products.removeAt(index);
  }

  public addProduct(product: WorkOrderProduct): void {
    const formGroup = new FormGroup({
      id: new FormControl(product.id || uuid()),
      productId: new FormControl(product.productId),
      articleNumber: new FormControl(product.articleNumber),
      price: new FormControl(product.price),
      onLoan: new FormControl(product.onLoan),
      description: new FormControl(product.description, [Validators.required]),
      quantity: new FormControl(product.quantity, [Validators.required]),
      remarks: new FormControl(product.remarks),
    });

    this.products.push(formGroup);
  }

  public async onSubmit(): Promise<any> {
    const formData = { ...this.form.value };

    const data = {
      executed: formData.executed,
      remarks: formData.remarks,
      publicRemarks: formData.publicRemarks,
      products: formData.products,
      images: formData.images,
    };

    await firstValueFrom(this.store.dispatch(new PatchWorkOrderDraft(data)));
    const newWorkOrderDraft = this.store.selectSnapshot(WorkOrderDraftState.workOrder);
    await firstValueFrom(this.store.dispatch(new SaveWorkOrderDraft(newWorkOrderDraft)));

    if (formData.orderProducts) {
      return this.store.dispatch(new OpenDraftOrderProductForm());
    }

    return this.store.dispatch(new OpenDraftCompletionForm());
  }
}
