<form class="form" [formGroup]="externalInvoicesForm" (ngSubmit)="onSubmit()">
  <app-page-header title="Facturatie">

    <div class="actions ml-auto">
      <a
        class="btn btn-primary mr-1"
        download
        href="{{ apiUrl }}/external-invoices/export?token={{ token$ | async }}&{{ filterQs$ | async }}">
        <img class="icon" src="assets/img/excel.svg" />
        Exporteren
      </a>
      <button type="submit" class="btn btn-primary ml-auto" [disabled]="externalInvoicesForm.invalid">
        <span *ngIf="!isBusy" class="text">Opslaan</span>
        <span *ngIf="isBusy" class="loader"><img src="assets/img/loader-white.svg" /></span>
      </button>
    </div>
  </app-page-header>

  <div class="container">
    <div class="card">
      <div class="body">
        <form class="form" [formGroup]="filterForm">
          <div class="row">
            <div class="col col-65">
              <div class="form-group">
                <label class="label">Periode</label>
                <div class="input-group">
                  <input type="date" class="form-control" formControlName="start" />
                  <input type="date" class="form-control ml-1" formControlName="end" />
                </div>
              </div>
            </div>
            <div class="col col-32">
              <div class="form-group">
                <label class="label">Opdrachtgever</label>
                <div class="input-group">
                  <select class="form-control" formControlName="companyId">
                    <option [ngValue]="company.id" *ngFor="let company of companies$ | async">{{ company.name }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card list">
      <div class="body">
        <ul class="list" *ngIf="workOrders$ | async as workOrders; else loading">
          <li *ngFor="let workOrder of workOrders; let i = index" class="py-2">
            <div class="details">
              <div class="row">
                <div class="col">
                  <div class="bold">
                    {{ workOrder.invoiceNumber }} - {{ workOrder.report.client.clientAddress.contact }}
                    <ng-container *ngIf="workOrder.report.company">
                      (via: {{ workOrder.report.company.name }})
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="muted row">
                <div class="col col-40">
                  <i>Adres</i>
                  {{ workOrder.report.client.clientAddress.streetAndHouseNumber }}
                  <br />
                  {{ workOrder.report.client.clientAddress.postalCode }} {{ workOrder.report.client.clientAddress.city }}
                </div>

                <div class="col col-40" *ngIf="externalInvoices?.length > 0">
                  <div class="ml-auto" [formGroup]="externalInvoiceByIndex(i)">
                    <div class="form-group"><label>Factuurnr:</label><input type="text" class="form-control" formControlName="externalInvoiceNumber" /></div>
                    <div class="form-group"><label>Offertenr:</label><input type="text" class="form-control" formControlName="externalOfferNumber" /></div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <ng-template #loading>
    <div class="loader">
      <img class="loader" src="assets/img/loader-primary.svg" />
    </div>
  </ng-template>
</form>
