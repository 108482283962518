<form class="form" [formGroup]="companyForm" (ngSubmit)="onSubmit()">
  <div class="app-page-header">
    <div class="page-title">
      <div class="container">
        <h1>Klant toevoegen</h1>
        <div class="actions ml-auto">
          <button type="submit" class="btn btn-primary ml-auto" [disabled]="companyForm.invalid">
            <span *ngIf="!isBusy" class="text">opslaan</span>
            <span *ngIf="isBusy" class="loader"><img src="assets/img/loader-primary.svg" /></span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="card">
      <div class="body p-2">
        <div class="form-group required">
          <label class="label">Naam</label>
          <div class="input-group">
            <input type="text" class="form-control" formControlName="name" placeholder="Bedrijfsnaam" />
          </div>
        </div>

        <div class="form-group required">
          <label class="label">E-mailadres</label>
          <div class="input-group">
            <input type="text" class="form-control" formControlName="email" placeholder="E-mailadres" />
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
