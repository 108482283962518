import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-body',
  templateUrl: './page-body.component.html',
})
export class PageBodyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
