import { Product } from '../../models';

export class OpenProductsList {
  public static readonly type = '[Products] Open products list';
}

export class OpenProductForm {
  public static readonly type = '[Products] Open product form';
  public constructor(public product?: Product) {}
}

export class LoadProducts {
  public static readonly type = '[Products] Load products';
}

export class DeleteProduct {
  public static readonly type = '[Products] Delete product';
  public constructor(public product: Product) {}
}

export class SaveProduct {
  public static readonly type = '[Products] Save product';
  public constructor(public product: Product) {}
}
