import { Expose, Exclude, Type } from 'class-transformer';

import { IProduct } from '@vandelft/shared/interfaces';
import { LocalImage } from './local-image';
import { Image } from './image';
import { BaseFile } from './base-file';

@Exclude()
export class Product implements IProduct {
  @Expose()
  public id: string;

  @Expose()
  public articleNumber: string;

  @Expose()
  public description: string;

  @Expose()
  public price: number;

  @Expose()
  public onLoan: boolean;

  @Expose()
  @Type(() => BaseFile, {
    discriminator: {
      property: 'internalType',
      subTypes: [
        { value: Image, name: 'image' },
        { value: LocalImage, name: 'localImage' },
      ],
    },
  })
  public images?: Image[] | LocalImage[];

  public get tax(): number {
    return (this.price / 100) * 21;
  }

  public get priceExc(): number {
    return this.price - this.tax;
  }
}
