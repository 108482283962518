<app-page-header title="Producten">
  <div class="actions ml-auto">
    <a class="btn btn-primary ml-auto" (click)="addItem()">toevoegen</a>
  </div>
</app-page-header>

<div class="container py-1">
  <div class="card">
    <div class="body">
      <ul class="list" *ngIf="products$ | async as products; else loading">
        <li *ngFor="let product of products" class="py-1">
          <a (click)="selectItem(product)">
            <div class="details">
              <div class="bold">{{ product.articleNumber }} {{ product.description }}</div>
              <div class="muted" tna-money [amount]="product.price" [alignRight]="false"></div>
            </div>

            <div class="actions">
              <a class="btn btn-primary btn-outline btn-sm btn-icon" (click)="deleteProduct($event, product)">
                <img src="/assets/img/trash.svg" />
              </a>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="loader">
    <img class="loader" src="assets/img/loader-primary.svg" />
  </div>
</ng-template>
