import { IImage, IWorkOrder } from '../interfaces';

export class WorkOrderHelper {
  public static getClientSignature(workOrder: IWorkOrder): IImage {
    return (workOrder.images as any[])?.find((i: IImage) => i.fileType === 'clientSignature');
  }
  public static getMechanicSignature(workOrder: IWorkOrder): IImage {
    return (workOrder.images as any)?.find((i: IImage) => i.fileType === 'mechanicSignature');
  }
  public static getWorkOrderImages(workOrder: IWorkOrder): IImage[] | undefined {
    return (workOrder.images as any[])?.filter((i: IImage) => i.fileType === 'workOrderImage');
  }

  public static getPaymentMethods(workOrder: IWorkOrder): string[] {
    if (workOrder.report?.client?.type === 'company') {
      return ['Op rekening'];
    }
    const paymentMethods = [];
    for (const payment of workOrder.payments) {
      if (payment.method === 'cash') {
        paymentMethods.push('Contant');
        continue;
      }

      if (payment.method === 'pin') {
        paymentMethods.push('Pin');
        continue;
      }
    }

    return paymentMethods;
  }
}
