import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { firstValueFrom, Observable } from 'rxjs';

import { Select } from '@ngxs/store';
import { CompaniesState, LoadCompanies } from '@vandelft/modules/shared/state/companies';
import { Company, User, WorkOrder } from '@vandelft/modules/shared/models';
import { DraftBaseFormComponent } from '../draft-base-form';
import {
  OpenDraftActivitiesForm,
  PatchWorkOrderDraft,
  SaveWorkOrderDraft,
  WorkOrderDraftState,
} from '@vandelft/modules/shared/state/work-order-draft';
import { SetProgress } from '@vandelft/modules/shared/state/progress';
import { OpenReportsList, SaveReport } from '@vandelft/modules/shared/state/reports';
import { AuthState } from '@vandelft/modules/shared/state/auth';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-draft-client-info-form',
  templateUrl: './draft-client-info-form.component.html',
})
export class DraftClientInfoFormComponent extends DraftBaseFormComponent implements OnInit {
  @Select(CompaniesState.companies)
  public companies$: Observable<Company[]>;

  @Select(AuthState.user)
  public user$: Observable<User>;

  public form = new FormGroup({
    report: new FormControl(),
  });

  public ngOnInit(): void {
    this.store.dispatch([new SetProgress(1, 4), new LoadCompanies()]);
  }

  public async onSubmit(action: string): Promise<any> {
    const report = this.form.value.report;
    const user: User = await firstValueFrom(this.user$.pipe(first()));
    const status = user?.role === 'mechanic' ? 'in-progress' : report.status;
    const mechanicId = user?.role === 'mechanic' ? user.id : report.mechanicId;

    const reportData = { ...report, status, mechanicId };
    const data = { report: reportData };

    await firstValueFrom(this.store.dispatch(new PatchWorkOrderDraft(data)));
    const workOrderDraft = this.store.selectSnapshot(WorkOrderDraftState.workOrder);

    await firstValueFrom(this.store.dispatch(new SaveWorkOrderDraft(workOrderDraft)));

    if (action === 'saveAndContinue') {
      return this.store.dispatch([new OpenDraftActivitiesForm()]);
    }

    await firstValueFrom(this.store.dispatch(new SaveReport(reportData)));
    return this.store.dispatch(new OpenReportsList());
  }

  protected initForm(workOrder: WorkOrder): void {
    this.form.get('report').setValue(workOrder.report);
  }
}
