import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { WorkOrder } from '@vandelft/modules/shared/models';

import { environment } from '@vandelft/environments/environment';
import { Store, Select } from '@ngxs/store';
import { LoadInvoice, InvoicesState } from '@vandelft/modules/shared/state/invoices';
import { AuthState } from '@vandelft/modules/shared/state/auth';
import { PAYMENT_METHODS } from '@vandelft/modules/shared/constants';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
})
export class ViewComponent {
  @Select(InvoicesState.workOrder)
  public workOrder$: Observable<WorkOrder>;

  @Select(AuthState.token)
  public token$: Observable<string>;

  public apiUrl = environment.apiUrl;
  public token: string;

  public paymentMethods = [];

  public constructor(private store: Store, private route: ActivatedRoute) {
    const invoiceId = this.route.snapshot.paramMap.get('id');
    this.store.dispatch(new LoadInvoice(invoiceId));

    for (const paymentMethod of PAYMENT_METHODS) {
      this.paymentMethods[paymentMethod.key] = paymentMethod.label;
    }
  }
}
