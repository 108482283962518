<form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <app-page-header title="Bestelformulier"></app-page-header>

  <app-page-body>
    <div class="container">
      <ng-container class="list" *ngIf="workOrder$ | async as workOrder; else loading">
        <div class="row">
          <div class="col card">
            <div class="header px-2 pt-2">
              <h2>Bestelling</h2>
            </div>
            <div class="body p-2">
              <ul class="material-list">
                <li class="header">
                  <div class="details">
                    <div class="title">Arbeid / materiaal</div>
                    <div class="quantity">Aantal</div>
                    <div class="price" *ngIf="!isCompany">Prijs</div>
                    <div class="remarks">Opmerking</div>
                    <div class="actions"></div>
                  </div>
                </li>
                <ng-container formArrayName="orderLines">
                  <li *ngFor="let orderLine of orderLines.controls; let i = index">
                    <a (click)="openProductModal(i)">
                      <div class="details">
                        <div class="title">
                          {{ orderLine.value.description }}
                          <ng-container *ngIf="orderLine.value.articleNumber">
                            ({{ orderLine.value.articleNumber }})
                          </ng-container>
                        </div>
                        <div class="quantity">{{ orderLine.value.quantity }}</div>
                        <div class="price" *ngIf="!isCompany">
                          <div
                            tna-money
                            [amount]="orderLine.value.price * orderLine.value.quantity"
                            *ngIf="orderLine.value['price'] !== null"></div>
                          <div *ngIf="orderLine.value['price'] === null">Nader te bepalen</div>
                        </div>
                        <div class="remarks">{{ orderLine.value.remarks }}</div>
                        <div class="actions">
                          <a
                            class="btn btn-primary btn-icon btn-outline btn-sm ml-auto"
                            (click)="deleteOrderLine($event, orderLine)">
                            <img src="/assets/img/trash.svg" />
                          </a>
                        </div>
                      </div>
                    </a>
                  </li>
                </ng-container>
                <li class="footer">
                  <div class="actions">
                    <a class="btn btn-primary btn-sm" id="addOrderProduct" (click)="openProductModal()">toevoegen</a>
                  </div>
                </li>
              </ul>

              <div class="form-group" *ngIf="workOrder?.report?.client?.type !== 'company'">
                <label class="label">
                  Aanbetaling
                  <small class="deposit">
                    (
                    <span tna-money [amount]="minDeposit" [alignRight]="false"></span>
                    &nbsp;-&nbsp;
                    <span tna-money [amount]="maxDeposit" [alignRight]="false"></span>
                    )
                  </small>
                </label>
                <input
                  type="number"
                  step="0.01"
                  min="{{ minDeposit }}"
                  max="{{ maxDeposit }}"
                  class="form-control"
                  formControlName="deposit"
                  placeholder="Aanbetaling" />
              </div>

              <div class="form-group">
                <label class="label">Meegenomen product</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="takenProduct"
                  placeholder="Meegenomen product" />
              </div>

              <div class="form-group">
                <label class="label">Opmerking</label>
                <textarea class="form-control" row="5" formControlName="remarks" placeholder="Opmerking"></textarea>
              </div>

              <div class="form-group required">
                <label class="label">Materiaal deur</label>
                <app-checkbox-formgroup
                  formControlName="doorMaterial"
                  [options]="materialOptions"></app-checkbox-formgroup>
              </div>

              <div class="form-group required">
                <label class="label">Materiaal kozijn</label>
                <app-checkbox-formgroup
                  formControlName="frameMaterial"
                  [options]="materialOptions"></app-checkbox-formgroup>
              </div>

              <div class="form-group required">
                <label class="label">Deurtype</label>
                <app-checkbox-formgroup formControlName="doorType" [options]="doorTypeOptions"></app-checkbox-formgroup>
              </div>

              <div class="form-group required">
                <label class="label">Cylinderbediend</label>
                <app-checkbox-formgroup
                  formControlName="cylinderOperated"
                  [options]="yesNoOptions"></app-checkbox-formgroup>
              </div>

              <div class="form-group required">
                <label class="label">Doornmaat</label>
                <app-checkbox-formgroup
                  formControlName="backsetLock"
                  [options]="backsetLockOptions"></app-checkbox-formgroup>
              </div>

              <div class="form-group required">
                <label class="label">H.O.H. PC maat slot</label>
                <app-checkbox-formgroup
                  formControlName="spacingLock"
                  [options]="spacingLockOptions"></app-checkbox-formgroup>
              </div>

              <div class="form-group required">
                <label class="label">Sleutel nabestelling</label>
                <app-checkbox-formgroup
                  formControlName="keyRepeatOrder"
                  [options]="yesNoOptions"></app-checkbox-formgroup>
              </div>

              <div class="form-group required">
                <label class="label">Cylinder</label>
                <app-checkbox-formgroup formControlName="cylinder" [options]="yesNoOptions"></app-checkbox-formgroup>
              </div>

              <div class="form-group">
                <label>Foto certificaat</label>
                <tna-file-upload
                  appFileType="certificate"
                  [allowMultiple]="false"
                  formControlName="certificates"></tna-file-upload>
              </div>

              <div class="form-group">
                <label>Foto product</label>
                <tna-file-upload
                  appFileType="productImage"
                  [allowMultiple]="false"
                  formControlName="productImages"></tna-file-upload>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-1">
          <div class="col card">
            <div class="body p-2">
              <button type="submit" class="btn btn-success ml-auto" [disabled]="form.invalid">Naar afronden</button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </app-page-body>
</form>

<ng-template #loading>
  <div class="loader">
    <img class="loader" src="assets/img/loader-primary.svg" />
  </div>
</ng-template>
