<form class="form" id="loginForm" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  <div class="header">Welkom</div>
  <div class="body">
    <div class="form-group">
      <label>E-mailadres</label>
      <input
        type="text"
        id="username"
        class="form-control"
        formControlName="email"
        autocorrect="off"
        autocapitalize="none"
        placeholder="E-mailadres" />
    </div>

    <div class="form-group">
      <label>Wachtwoord</label>
      <input type="password" id="password" class="form-control" formControlName="password" placeholder="Wachtwoord" />
    </div>
  </div>

  <div class="footer text-center">
    <button type="submit" class="btn btn-primary btn-block mb-1" [disabled]="loginForm.invalid || isBusy">
      <span *ngIf="!isBusy" class="text">Inloggen</span>
      <span *ngIf="isBusy" class="loader"><img src="assets/img/loader-white.svg" /></span>
    </button>

    <a routerLink="/forgot-password">Wachtwoord vergeten</a>
  </div>
</form>
