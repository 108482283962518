import { Component } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { ConfirmModalComponent } from '@vandelft/modules/shared/components/ui/confirm-modal';
import { ModalService } from '@vandelft/modules/shared/services/modal.service';

import { Company } from '@vandelft/modules/shared/models';
import {
  OpenCompanyForm,
  LoadCompanies,
  DeleteCompany,
  CompaniesState,
} from '@vandelft/modules/shared/state/companies';

@Component({
  selector: 'tna-companies-list',
  templateUrl: './list.component.html',
})
export class ListComponent {
  @Select(CompaniesState.companies)
  public companies$: Observable<Company[]>;

  public tabs = [
    {
      key: 'order',
      label: 'Opdracht',
    },
    {
      key: 'activities',
      label: 'Werkzaamheden',
    },
    {
      key: 'completion',
      label: 'Afronding',
    },
  ];

  public constructor(private store: Store, private modalService: ModalService) {
    this.store.dispatch([new LoadCompanies()]);
  }

  public addItem(): void {
    this.store.dispatch([new OpenCompanyForm()]);
  }

  public selectItem(company: Company): void {
    this.store.dispatch([new OpenCompanyForm(company)]);
  }

  public async deleteCompany(e: Event, company: Company): Promise<void> {
    e.preventDefault();
    e.stopPropagation();

    const modalRef = this.modalService.open(ConfirmModalComponent, {
      modalType: 'modal-floating-confirmation',
      barrier: false,
      alignment: {
        element: e.target,
      },
    });

    modalRef.componentInstance.message = `Weet u zeker dat ${company.name} wilt verwijderen?`;
    const result = await modalRef.result;

    if (!result) {
      return;
    }

    this.store.dispatch([new DeleteCompany(company)]);
  }
}
