import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Company } from '../models';
import { environment } from '../../../../environments/environment';
import { plainToInstance } from 'class-transformer';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService {
  public constructor(private http: HttpClient) {}

  public getCompanies(): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/companies`)
      .pipe(map((data: Company[]) => plainToInstance(Company, data)));
  }

  public getCompanyById(id: string): Observable<Company> {
    return this.http
      .get(`${environment.apiUrl}/companies/${id}`)
      .pipe(map((data: Company) => plainToInstance(Company, data)));
  }
}
