import * as moment from 'moment/moment';

export const datetimeToPlain = (value: any): string => {
  if (!value) {
    return value;
  }

  const effectiveValue = value?.value ?? value;
  return moment(effectiveValue).format('YYYY-MM-DD HH:mm:ss');
};
