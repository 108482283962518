import { Expose, Exclude } from 'class-transformer';

import { IWorkOrderProduct } from '../../../shared/interfaces';

import { QuantifiedProductHelper } from '../../../shared/helpers';
import { WorkOrder } from './work-order';
import { Product } from './product';

@Exclude()
export class WorkOrderProduct implements IWorkOrderProduct {
  @Expose()
  public id: string;

  @Expose()
  public workOrderId: string;

  @Expose()
  public productId: string;

  @Expose()
  public articleNumber: string;

  @Expose()
  public description: string;

  @Expose()
  public price: number;

  @Expose()
  public onLoan: boolean;

  @Expose()
  public quantity: number;

  @Expose()
  public refilled: number;

  @Expose()
  public remarks: string;

  @Expose()
  public workOrder: WorkOrder;

  @Expose()
  public product: Product;

  public get priceExc(): number {
    return QuantifiedProductHelper.getProductPriceExc(this);
  }

  public get tax(): number {
    return QuantifiedProductHelper.getProductTax(this);
  }

  public get priceInc(): number {
    return QuantifiedProductHelper.getProductPriceInc(this);
  }

  public get totalPriceExc(): number {
    return QuantifiedProductHelper.getTotalProductPriceExc(this);
  }

  public get totalTax(): number {
    return QuantifiedProductHelper.getTotalProductTax(this);
  }

  public get totalPriceInc(): number {
    return QuantifiedProductHelper.getTotalProductPriceInc(this);
  }
}
