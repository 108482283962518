<ng-container *ngIf="steps$ | async as steps">
  <div class="container">
    <ul>
      <li *ngFor="let step of steps" (click)="goToStep(step)" [ngClass]="{ done: step.done, todo: !step.done }">
        <div class="icon">
          <img class="circle" src="assets/img/circle.svg" />
          <img class="check" src="assets/img/check.svg" />
        </div>
        {{ step.label }}
      </li>
    </ul>
  </div>
</ng-container>
