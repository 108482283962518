<form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <app-page-header title="Klantinformatie"></app-page-header>
  <app-page-body>
    <div class="container">
      <ng-container *ngIf="!isBusy; else loading">
        <ng-container class="list" *ngIf="report$ | async as report; else loading">
          <ng-container class="list" *ngIf="user$ | async as user; else loading">
            <app-report-form formControlName="report" [isNew]="isNewReport" [user]="user"></app-report-form>

            <div class="row mt-1">
              <div class="col card">
                <div class="body p-2">
                  <button type="submit" class="btn btn-success ml-auto" [disabled]="form.invalid">Opslaan</button>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </app-page-body>
</form>

<ng-template #loading>
  <div class="loader">
    <img class="loader" src="assets/img/loader-primary.svg" />
  </div>
</ng-template>
