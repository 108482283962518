<div class="date">{{ workOrder.executed | date: 'dd-MM-yyyy' }}</div>
<div class="details">
  <div class="row">
    <div class="col col-48">
      <div>
        <b>Werkbonnummer</b>
        <br />
        {{ workOrder.number }}
        <br />
      </div>

      <div class="mt-2">
        <b>Monteur</b>
        <br />
        {{ workOrder.mechanic.fullName }}
        <br />
      </div>

      <div class="mt-2">
        <b>Opmerkingen</b>
        <br />
        {{ workOrder.remarks || 'Geen' }}
      </div>
    </div>

    <div class="col col-48 financial-summary">
      <ng-container *ngIf="report?.client?.type === 'private'">
        <div class="row">
          <b>Totaalbedrag</b>
          <span tna-money [amount]="workOrder.totalPriceInc"></span>
        </div>

        <div class="row">
          <b>Reeds voldaan</b>
          <span tna-money [amount]="workOrder.totalPayed"></span>
        </div>

        <div class="row">
          <b>Nog te voldoen</b>
          <span tna-money [amount]="workOrder.totalOpen"></span>
        </div>

        <div class="row">
          <b>Factuur</b>
          <a href="{{ apiUrl }}/invoices/{{ workOrder.id }}/download?token={{ token }}" target="_blank">Downloaden</a>
        </div>
      </ng-container>

      <div class="row" *ngIf="workOrder.order">
        <b>Bestelling</b>
        <a href="{{ apiUrl }}/orders/{{ workOrder.order.id }}/download?token={{ token }}" target="_blank">Downloaden</a>
      </div>

      <div class="row">
        <b>Externe werkbon</b>
        <a
          href="{{ apiUrl }}/work-orders/{{ workOrder.id }}/download?isInternal=false&token={{ token }}"
          target="_blank">
          Downloaden
        </a>
      </div>

      <div class="row">
        <b>Interne werkbon</b>
        <a
          href="{{ apiUrl }}/work-orders/{{ workOrder.id }}/download?isInternal=true&token={{ token }}"
          target="_blank">
          Downloaden
        </a>
      </div>

      <div class="row" *ngIf="workOrder.externalInvoiceNumber">
        <b>Factuurnummer</b>
        {{ workOrder.externalInvoiceNumber }}
      </div>

      <div class="row" *ngIf="workOrder.externalOfferNumber">
        <b>Offertenummer</b>
        {{ workOrder.externalOfferNumber }}
      </div>


      <div class="mt-2" *ngIf="workOrder.clientSignature">
        <b>Handtekening</b>
        <br />
        <a href="{{ apiUrl }}/images/{{ workOrder.clientSignature.id }}?token={{ token }}" target="_blank">
          <img src="{{ apiUrl }}/images/{{ workOrder.clientSignature.id }}?token={{ token }}" />
        </a>
      </div>
    </div>
  </div>

  <div class="products-list">
    <b>Arbeid / materiaal</b>
    <br />
    <div *ngFor="let product of workOrder.products">
      {{ product.quantity }} x {{ product.description }}
      <small *ngIf="product.remarks">({{ product.remarks }})</small>
    </div>
  </div>

  <div class="mt-2" *ngIf="workOrder.payments?.length > 0">
    <b>Betalingen</b>
    <br />
    <div class="row payment-row" *ngFor="let payment of workOrder.payments">
      <span tna-money [amount]="payment.amount"></span>
      (
      <span *ngIf="payment.method === 'cash'">Contant</span>
      <span *ngIf="payment.method === 'pin'">Pin</span>
      <span *ngIf="payment.method === 'on-account'">Op rekening</span>
      )
    </div>
  </div>

  <div class="products-list" *ngIf="workOrder.order">
    <b>Bestelling</b>
    <br />
    <div *ngFor="let product of workOrder.order?.orderLines">
      {{ product.quantity }} x {{ product.description }}
      <small *ngIf="product.remarks">({{ product.remarks }})</small>
    </div>
  </div>

  <div class="mt-2">
    <div class="images-list">
      <div class="image-container" *ngIf="workOrder.order?.certificate">
        <b>Foto Certificaat</b>
        <br />
        <a href="{{ apiUrl }}/images/{{ workOrder.order.certificate.id }}?token={{ token }}" target="_blank">
          <img src="{{ apiUrl }}/images/{{ workOrder.order.certificate.id }}?token={{ token }}" />
        </a>
      </div>

      <div class="image-container" *ngIf="workOrder.order?.productImage">
        <b>Foto product</b>
        <br />
        <a href="{{ apiUrl }}/images/{{ workOrder.order.productImage.id }}?token={{ token }}" target="_blank">
          <img src="{{ apiUrl }}/images/{{ workOrder.order.productImage.id }}?token={{ token }}" />
        </a>
      </div>
    </div>
  </div>

  <div class="mt-2" *ngIf="workOrder.workOrderImages?.length > 0">
    <b>Afbeeldingen</b>
    <br />
    <div class="images-list">
      <div class="image-container" *ngFor="let image of workOrder.workOrderImages">
        <a href="{{ apiUrl }}/images/{{ image.id }}?token={{ token }}" target="_blank">
          <img src="{{ apiUrl }}/images/{{ image.id }}?token={{ token }}" />
        </a>
      </div>
    </div>
  </div>

  <div class="mt-2" *ngIf="workOrder.order?.images?.length > 0">
    <b>Afbeeldingen bij bestelling</b>
    <br />
    <div class="images-list">
      <div class="image-container" *ngFor="let image of workOrder.order.images">
        <a href="{{ apiUrl }}/images/{{ image.id }}?token={{ token }}" target="_blank">
          <img src="{{ apiUrl }}/images/{{ image.id }}?token={{ token }}" />
        </a>
      </div>
    </div>
  </div>

  <div class="mt-3 mb-1">
    <div class="mt-3 mb-1 d-flex" *ngIf="['admin'].includes(user.role)">
      <a (click)="edit.emit(workOrder)" class="btn btn-primary d-flex">Bewerken</a>
    </div>
  </div>
</div>
