<ul class="list" *ngIf="workOrders">
  <li *ngFor="let workOrder of workOrders" class="py-2" (click)="onClick(workOrder)">
    <div class="details">
      <div class="row">
        <div class="col">
          <div class="bold">
            {{ workOrder.number }} - {{ workOrder.report.client.clientAddress.contact }}
            <ng-container *ngIf="workOrder.report.company">(via: {{ workOrder.report.company.name }})</ng-container>
          </div>
        </div>
      </div>
      <div class="muted row">
        <div class="col col-40">
          <i>Adres</i>
          {{ workOrder.report.client.clientAddress.streetAndHouseNumber }}
          <br />
          {{ workOrder.report.client.clientAddress.postalCode }} {{ workOrder.report.client.clientAddress.city }}
        </div>

        <div class="col col-35">
          <i>Uitvoering</i>
          {{ workOrder.mechanic.fullName }}
          <br />
          {{ workOrder.executed | date: 'dd-MM-yyyy HH:mm' }}
        </div>
      </div>
      <div class="row mt-1">
        <div class="badges">
          <div class="badge mr-1" *ngIf="workOrder['isPaid']" [ngClass]="{ 'badge-completed': workOrder['isPaid'] }">
            Betaald
          </div>
          <ng-container *ngIf="workOrder.report.client.type === 'company'">
            <div class="badge badge-on-account mr-1">Op rekening</div>
          </ng-container>
          <ng-container *ngIf="workOrder.payments">
            <ng-container *ngIf="workOrder.report.client.type !== 'company'">
              <div *ngFor="let payment of workOrder.payments" class="badge badge-{{ payment.method }}">
                <span *ngIf="payment.method === 'cash'">Contant</span>
                <span *ngIf="payment.method === 'pin'">Pin</span>
                <span *ngIf="payment.method === 'on-account'">Op rekening</span>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </li>
</ul>
