<div class="container">
  <app-back-button></app-back-button>
  <div class="row">
    <div class="col col-50">
      <div class="logo-container">
        <img src="assets/img/logo.png" class="logo" />
      </div>
    </div>
  </div>
  <a class="logout" (click)="logout()">Afmelden</a>

  <app-queue-button *ngIf="(user$ | async)?.role === 'mechanic'"></app-queue-button>
  <div class="version">v{{ version }}</div>
</div>

<app-summary></app-summary>
<app-navigation></app-navigation>
<app-progress></app-progress>
