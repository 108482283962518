import { Injectable, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { User } from '@vandelft/modules/shared/models';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { AuthState } from '@vandelft/modules/shared/state/auth';
import { OpenDepositItemsList } from '@vandelft/modules/shared/state/deposit/deposit.actions';
import { OpenInvoicesList } from '@vandelft/modules/shared/state/invoices/invoices.actions';
import { OpenExternalInvoicesList } from '@vandelft/modules/shared/state/external-invoices';
import { OpenProductsList } from '@vandelft/modules/shared/state/products';
import { OpenReportsList } from '@vandelft/modules/shared/state/reports';
import { OpenUsersList } from '@vandelft/modules/shared/state/users';
import { OpenWorkOrdersList } from '@vandelft/modules/shared/state/work-orders/work-orders.actions';
import { OpenOrdersList } from '@vandelft/modules/shared/state/orders/orders.actions';
import { OpenStockList } from '@vandelft/modules/shared/state/stock/stock.actions';
import { SetActiveModule, SetModules } from '@vandelft/modules/shared/state/modules';
import { SetPrefix } from '@vandelft/modules/shared/state/environment';
import { OpenCompaniesList } from '@vandelft/modules/shared/state/companies';
import { OpenReportBackItemsList } from '@vandelft/modules/shared/state/report-back-items';
import { OpenOrderedProductsList } from '@vandelft/modules/shared/state/ordered-products';
import { OpenChatOverview } from '@vandelft/modules/shared/state/messages';

@Injectable({
  providedIn: 'root',
})
export class AppService implements OnDestroy {
  @Select(AuthState.user)
  public user$: Observable<User>;

  private destroy$: Subject<void> = new Subject<void>();

  public constructor(
    private route: ActivatedRoute,
    private store: Store,
  ) {
    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user: User) => this.setModules(user));
  }

  public setModules(user: User): void {
    const role: string = user.role;

    const availableModules = [
      {
        icon: '/assets/img/chat.svg',
        label: 'Berichten',
        id: 'messages',
        url: '/messages',
        action: new OpenChatOverview(),
        roles: ['admin', 'planner', 'financial'],
      },
      {
        icon: '/assets/img/reports.svg',
        label: 'Meldingen',
        id: 'reports',
        url: '/reports',
        action: new OpenReportsList(),
        roles: ['admin', 'mechanic', 'planner', 'financial'],
      },
      {
        icon: '/assets/img/products.svg',
        label: 'Terugmeldingen',
        id: 'report-back',
        url: '/report-back',
        action: new OpenReportBackItemsList(),
        roles: ['admin', 'planner', 'financial'],
      },
      {
        icon: '/assets/img/work-orders.svg',
        label: 'Werkbonnen',
        id: 'work-orders',
        url: '/work-orders',
        action: new OpenWorkOrdersList(),
        roles: ['admin'],
      },
      {
        icon: '/assets/img/orders.svg',
        label: 'Bestellingen',
        id: 'orders',
        url: '/orders',
        action: new OpenOrdersList(),
        roles: ['admin', 'planner', 'financial'],
      },
      {
        icon: '/assets/img/products.svg',
        label: 'Producten',
        id: 'products',
        url: '/products',
        action: new OpenProductsList(),
        roles: ['admin'],
      },
      {
        icon: '/assets/img/financial.svg',
        label: 'Financieel',
        id: 'financial',
        action: new OpenInvoicesList(),
        url: '/financial',
        roles: ['admin', 'financial'],
      },
      {
        icon: '/assets/img/financial.svg',
        label: 'Facturatie',
        id: 'facturatie',
        action: new OpenExternalInvoicesList(),
        url: '/external-invoices',
        roles: ['admin', 'financial'],
      },
      {
        icon: '/assets/img/stock.svg',
        label: 'Voorraad',
        id: 'stock',
        url: '/stock',
        action: new OpenStockList(),
        roles: ['admin', 'planner', 'financial'],
      },
      {
        icon: '/assets/img/products.svg',
        label: 'Bestelde producten',
        id: 'ordered-products',
        url: '/ordered-products',
        action: new OpenOrderedProductsList(),
        roles: ['admin', 'planner', 'financial'],
      },
      {
        icon: '/assets/img/money.svg',
        label: 'Afletteren',
        id: 'desposit',
        url: '/deposit',
        action: new OpenDepositItemsList(),
        roles: ['admin', 'planner', 'financial'],
      },
      {
        icon: '/assets/img/customers.svg',
        label: 'Klanten',
        id: 'companies',
        url: '/companies',
        action: new OpenCompaniesList(),
        roles: ['admin'],
      },
      {
        icon: '/assets/img/users.svg',
        label: 'Gebruikers',
        id: 'users',
        url: '/users',
        action: new OpenUsersList(),
        roles: ['admin'],
      },
    ];

    const modules = [];

    for (const module of availableModules) {
      if (!module.roles.includes(role)) {
        continue;
      }

      modules.push(module);
    }

    const child: ActivatedRouteSnapshot = this.route.snapshot.firstChild.firstChild.firstChild;
    let activeModule = modules[0];

    if (child) {
      const path: string = child.routeConfig.path;
      activeModule = modules.find((m) => m.url.startsWith(`/${path}`)) || modules[0];
    }

    this.store.dispatch([new SetPrefix(role), new SetModules(modules), new SetActiveModule(activeModule)]);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
