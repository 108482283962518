<app-page-header title="Bestelde producten"></app-page-header>

<div class="container">
  <div class="card">
    <div class="body">
      <form class="form" [formGroup]="filterForm">
        <div class="row">
          <div class="col col-65">
            <div class="form-group">
              <label class="label">Datum</label>
              <div class="input-group">
                <input type="date" class="form-control" formControlName="date" />
              </div>
            </div>
          </div>
          <div class="col col-32"></div>
        </div>
      </form>
    </div>
  </div>

  <div class="card">
    <div class="body">
      <ng-container *ngIf="reports$ | async as data; else loading">
        <ul class="list">
          <ng-container *ngIf="data?.length > 0; else noItems">
            <li *ngFor="let report of data" class="py-1">
              <div class="details" (click)="open(report.mechanic)">
                <div class="bold">{{ report.mechanic.fullName }}</div>
                <div *ngIf="report['reports'].length === 1">Er staan producten voor 1 melding klaar</div>
                <div *ngIf="report['reports'].length > 1">
                  Er staan producten voor {{ report['reports'].length }} meldingen klaar
                </div>
              </div>
            </li>
          </ng-container>
        </ul>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #noItems>
  <li>Geen resultaten gevonden</li>
</ng-template>

<ng-template #loading>
  <h3 class="mt-3">Geen resultaten gevonden</h3>
</ng-template>
