import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import { VERSION } from '../../../../version';
import { timeout } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppEventsService {
  public constructor(private http: HttpClient) {}

  public save(data: any): Observable<any> {
    const postData = { ...data, appVersion: VERSION };

    return this.http.post(`${environment.apiUrl}/app-events`, postData).pipe(timeout(120000));
  }

  public processingPending(): Observable<any> {
    return this.http.post(`${environment.apiUrl}/app-events/process`, {}).pipe(timeout(120000));
  }
}
