<app-page-header title='Berichten'></app-page-header>

<ng-container *ngIf='templates$ | async as templates'>
  <div class='container all-chats'>
    <div class='mt-3 mb-3'>
      <div class='card'>
        <div class='body'>
          <h1>Alle chats</h1>
        </div>
      </div>
    </div>
    <div class='container chats' *ngIf='allChats$ | async as chats'>
      <div class='row'
           [class.unread]='chat.lastMessage.read == null && chat.lastMessage.sentByUs'
           *ngFor='let chat of chats | paginate: { id: "all", itemsPerPage: 10, currentPage: allChatsPage }'
           (click)='openChat(chat.address.id)'>
        <div class='col-10'>
          {{ chat.address.contact }}<br>
          <span class='message'>
          {{ (chat.lastMessage.message?.substring(0, 200) ?? getTemplate(chat.lastMessage, templates)?.bodyText.substring(0, 200)) + '...' }}
        </span>
        </div>
        <div class='col-2 chat-time'>
          {{ chat.lastMessage.created | date:'YYYY-MM-dd HH:mm' }}
        </div>
      </div>
    </div>
    <div class='text-center'>
      <pagination-controls
        id='all'
        previousLabel='Vorige'
        nextLabel='Volgende'
        (pageChange)='allChatsPage = $event'>
      </pagination-controls>
    </div>
  </div>

  <div class='container chat-queue'>
    <div class='mt-3 mb-1'>
      <div class='card'>
        <div class='body'>
          <h1>Berichten wachtrij</h1>
        </div>
      </div>
    </div>
    <div class='container chats' *ngIf='chatQueue$ | async as chats'>
      <div class='row'
           *ngFor='let chat of chats | paginate: { id: "unsent", itemsPerPage: 10, currentPage: chatsQueuePage }'>
        <div class='col-8'>
          {{ chat.address.contact }}<br>
          <span class='message'>
          {{ (chat.lastMessage.message?.substring(0, 200) ?? getTemplate(chat.lastMessage, templates)?.bodyText.substring(0, 200)) + '...' }}
        </span>
        </div>
        <div class='col-2 chat-time'>
          <ng-container *ngIf='chat.lastMessage.scheduled !== null else noDateSet'>
            {{ chat.lastMessage.scheduled | date:'YYYY-MM-dd HH:mm' }}
          </ng-container>
          <ng-template #noDateSet>
            Niet ingepland
          </ng-template>
        </div>
        <div class='edit' *ngIf='chat.lastMessage.sent == null && chat.lastMessage.sentByUs'>
          <a (click)='editMessage($event, chat.lastMessage)'>
            Bewerken
          </a>
        </div>
      </div>
    </div>
    <div class='text-center'>
      <pagination-controls
        id='unsent'
        previousLabel='Vorige'
        nextLabel='Volgende'
        (pageChange)='chatsQueuePage = $event'>
      </pagination-controls>
    </div>
  </div>
</ng-container>
