import { Injectable } from '@angular/core';
import { State, Action, StateContext, Store, Selector, NgxsOnInit } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { jwtDecode } from 'jwt-decode';
import { switchMap } from 'rxjs/operators';

import { LoginUser, LogoutUser, RefreshToken } from './auth.actions';
import { User } from '../../models';
import { UsersService } from '../../services/users.service';
import { Observable, of } from 'rxjs';
import { plainToInstance } from 'class-transformer';

const getUserFromToken = (token: string): User => {
  const decoded = jwtDecode(token) as any;
  return plainToInstance(User, { ...decoded, id: decoded.sub });
};

export interface AuthStateModel {
  user: User;
  token: string;
  prefix: string;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    user: null,
    token: null,
    prefix: null,
  },
})
@Injectable()
export class AuthState implements NgxsOnInit {
  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.token;
  }

  @Selector()
  static isSuperAdmin(state: AuthStateModel): boolean {
    const user = AuthState.user(state);

    if (!user) {
      return false;
    }

    return state.user.username === 'admin@thenextapp.nl';
  }

  @Selector()
  static isAdmin(state: AuthStateModel): boolean {
    const user = AuthState.user(state);

    if (!user) {
      return false;
    }

    return state.user.role === 'admin';
  }

  @Selector()
  static isMechanic(state: AuthStateModel): boolean {
    const user = AuthState.user(state);

    if (!user) {
      return false;
    }

    return state.user.role === 'mechanic';
  }

  @Selector()
  static isPlanner(state: AuthStateModel): boolean {
    const user = AuthState.user(state);

    if (!user) {
      return false;
    }

    return state.user.role === 'planner';
  }

  @Selector()
  static isFinancial(state: AuthStateModel): boolean {
    const user = AuthState.user(state);

    if (!user) {
      return false;
    }

    return state.user.role === 'financial';
  }

  @Selector()
  static token(state: AuthStateModel): string {
    return state.token;
  }

  @Selector()
  static user(state: AuthStateModel): User {
    return state.user;
  }

  public constructor(
    private store: Store,
    private usersService: UsersService,
  ) {}

  public ngxsOnInit(ctx: StateContext<AuthStateModel>): void {
    const state = ctx.getState();
    if (!state.user && state.token) {
      const user = getUserFromToken(state.token);
      ctx.patchState({ user });
    }
  }

  @Action(LoginUser)
  public loginUser(ctx: StateContext<AuthStateModel>, action: LoginUser): Observable<any> {
    return this.usersService.signInWithEmailAndPassword(action.email, action.password).pipe(
      switchMap((token: string) => {
        const user = getUserFromToken(token);
        ctx.patchState({ user, token, prefix: user.role });

        if (!user) {
          return ctx.dispatch([new Navigate(['/login'])]);
        }

        const moduleName = user.role === 'financial' ? 'invoices' : 'reports';

        return ctx.dispatch([new Navigate([`/${user.role}/${moduleName}`])]);
      }),
    );
  }

  @Action(RefreshToken)
  public refreshToken(ctx: StateContext<AuthStateModel>, action: RefreshToken): Observable<any> {
    return this.usersService.refreshToken(action.token).pipe(
      switchMap((token: string) => {
        ctx.patchState({ token });

        const state = ctx.getState();
        const user = AuthState.user(state);

        if (!user) {
          return this.store.dispatch([new Navigate(['/login'])]);
        }

        return of(token);
      }),
    );
  }

  @Action(LogoutUser)
  public logoutUser(ctx: StateContext<AuthStateModel>): Observable<any> {
    ctx.patchState({ token: null });
    return this.store.dispatch([new Navigate(['/login'])]);
  }
}
