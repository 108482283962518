<form class="form" [formGroup]="form" (ngSubmit)="onSubmit('saveAndContinue')">
  <app-page-header title="Klantinformatie"></app-page-header>
  <app-page-body>
    <div class="container">
      <ng-container class="list" *ngIf="workOrder$ | async as workOrder; else loading">
        <ng-container *ngIf="user$ | async as user; else loading">
          <app-report-form [editMode]="!workOrder.isNew" formControlName="report" [user]="user"></app-report-form>

          <div class="row mt-1">
            <div class="col card">
              <div class="body p-2">
                <div class="row">
                  <button
                    type="button"
                    *ngIf="workOrder?.isNew"
                    (click)="onSubmit('save')"
                    class="btn btn-secondary"
                    [disabled]="form.invalid">
                    Opslaan
                  </button>
                  <button
                    type="button"
                    (click)="onSubmit('saveAndContinue')"
                    class="btn btn-success ml-auto"
                    [disabled]="form.invalid">
                    Naar werkzaamheden
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </app-page-body>
</form>

<ng-template #loading>
  <div class="loader">
    <img class="loader" src="assets/img/loader-primary.svg" />
  </div>
</ng-template>
