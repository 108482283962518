import { State, Action, StateContext, Selector, Store, NgxsOnInit } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';

import { UsersStateModel } from './users.state-model';
import { UsersService } from '../../services/users.service';

import {
  LoadUsers,
  LoadMechanics,
  OpenUsersList,
  OpenUserForm,
  DeleteUser,
  SaveUser,
  ResetPassword,
} from './users.actions';
import { EnvironmentState } from '../environment';
import { User } from '../../models';
import { Observable } from 'rxjs';
import { tap, switchMap, filter } from 'rxjs/operators';
import { AppEventsService } from '../../services/app-events.service';
import { removeItem, iif, patch, updateItem, insertItem } from '@ngxs/store/operators';
import { AuthState } from '../auth';
import { plainToInstance } from 'class-transformer';

@State<UsersStateModel>({
  name: 'users',
  defaults: {
    users: [],
    mechanics: [],
  },
})
@Injectable()
export class UsersState implements NgxsOnInit {
  @Selector()
  static users(state: UsersStateModel): Array<User> {
    return state.users;
  }

  @Selector()
  static mechanics(state: UsersStateModel): Array<User> {
    return state.mechanics;
  }

  public constructor(
    private store: Store,
    private appEventsService: AppEventsService,
    private usersService: UsersService,
  ) {}

  public ngxsOnInit(ctx: StateContext<UsersStateModel>): void {
    this.store
      .select(AuthState.token)
      .pipe(
        filter((token: string) => !!token),
        switchMap(() => this.store.select(AuthState.isAdmin)),
        filter((isAdmin) => isAdmin),
        switchMap(() => ctx.dispatch(new LoadUsers())),
      )
      .subscribe();
  }

  @Action(OpenUsersList)
  public openUsersList(_: StateContext<UsersStateModel>): Observable<any> {
    const prefix = this.store.selectSnapshot(EnvironmentState.prefix);
    return this.store.dispatch(new Navigate([`/${prefix}/users`]));
  }

  @Action(OpenUserForm)
  public openUserForm(_: StateContext<UsersStateModel>, { user }: OpenUserForm): Observable<any> {
    const prefix = this.store.selectSnapshot(EnvironmentState.prefix);

    if (user) {
      return this.store.dispatch(new Navigate([`/${prefix}/users/${user.id}`]));
    }

    return this.store.dispatch(new Navigate([`/${prefix}/users/add`]));
  }

  @Action(LoadUsers)
  public loadUsers(ctx: StateContext<UsersStateModel>): Observable<Array<User>> {
    return this.usersService.getUsers().pipe(
      tap((users: Array<User>) => {
        return ctx.patchState({ users });
      }),
    );
  }

  @Action(LoadMechanics)
  public loadMechanics(ctx: StateContext<UsersStateModel>): Observable<Array<User>> {
    return this.usersService.getMechanics().pipe(tap((users: Array<User>) => ctx.patchState({ mechanics: users })));
  }

  @Action(DeleteUser)
  public deleteUser(ctx: StateContext<UsersStateModel>, { user }: DeleteUser): Observable<UsersStateModel> {
    return this.appEventsService
      .save({
        event: 'user.deleted',
        payload: user,
      })
      .pipe(
        tap((appEvent: any) =>
          ctx.setState(
            patch({
              users: removeItem<User>((u) => u.id === appEvent.payload.id),
            }),
          ),
        ),
      );
  }

  @Action(SaveUser)
  public saveUser(ctx: StateContext<UsersStateModel>, { user }: SaveUser): Observable<any> {
    return this.appEventsService
      .save({
        event: 'user.saved',
        payload: user,
      })
      .pipe(
        tap((appEvent: any) => {
          ctx.setState(
            patch({
              users: iif<User[]>(
                (users) => users.some((u) => u.id === appEvent.payload.id),
                updateItem<User>((u) => u.id === appEvent.payload.id, plainToInstance(User, appEvent.payload)),
                insertItem<User>(plainToInstance(User, appEvent.payload)),
              ),
            }),
          );
        }),
      );
  }

  @Action(ResetPassword)
  public resetPassword(_: StateContext<UsersStateModel>, { username }: ResetPassword): Observable<any> {
    return this.appEventsService.save({
      event: 'user.passwordReset',
      payload: { username },
    });
  }
}
