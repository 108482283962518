import { Injectable, ComponentFactoryResolver, Injector, ApplicationRef, EmbeddedViewRef } from '@angular/core';

export class ModalRef {
  public result: Promise<any>;
  private resolveFn: any;
  private rejectFn: any;

  public constructor(public componentRef: any) {
    this.result = new Promise((resolve, reject) => {
      this.resolveFn = resolve;
      this.rejectFn = reject;
    });
  }

  public get componentInstance(): any {
    return this.componentRef.instance;
  }

  public resolve(result): any {
    return this.resolveFn(result);
  }
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  public activeModal: ModalRef;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector,
  ) {}

  public open(component, options?): ModalRef {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    const modalRef = componentFactory.create(this.injector);
    this.applicationRef.attachView(modalRef.hostView);

    const modal = (modalRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    modal.classList.add('modal-dialog');
    modal.classList.add(options?.modalType || 'large-modal');

    if (options?.barrier === false) {
      document.body.appendChild(modal);
    } else {
      const container = document.createElement('div');
      container.classList.add('modal-container');
      container.appendChild(modal);
      document.body.appendChild(container);
    }

    if (options?.alignment) {
      const element = options.alignment.element;
      const position = this.getPosition(modal, element);
      modal.style.left = `${position.x}px`;
      modal.style.top = `${position.y}px`;
    }

    this.activeModal = new ModalRef(modalRef);
    return this.activeModal;
  }

  public close(result = null): void {
    this.activeModal.resolve(result);
    const modalInstance = this.activeModal.componentRef;
    const modal = (modalInstance.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    try {
      document.body.removeChild(modal.parentNode);
    } catch (e) {
      document.body.removeChild(modal);
    }

    this.applicationRef.detachView(modalInstance.hostView);
    this.activeModal = null;
  }

  private getPosition(modal: HTMLElement, element: HTMLElement): { x: number; y: number } {
    const targetRect = element.getBoundingClientRect();
    const modalRect = modal.getBoundingClientRect();

    const position = {
      x: targetRect.left + targetRect.width - window.scrollX - modalRect.width,
      y: targetRect.top + targetRect.height + 8,
    };

    if (modalRect.width + position.x > document.body.clientWidth) {
      position.x = document.body.clientWidth - modalRect.width;
    }

    if (modalRect.height + position.y > document.body.clientHeight) {
      position.y = document.body.clientHeight - modalRect.height;
    }

    return position;
  }
}
