import { Exclude, Expose, Transform, TransformFnParams, Type } from 'class-transformer';
import * as moment from 'moment/moment';

import { User } from './user';
import { Payment } from './payment';
import { Image } from './image';
import { LocalImage } from './local-image';
import { Report } from './report';
import { Order } from './order';
import { WorkOrderProduct } from './work-order-product';

import { IImage, IInvoice, IWorkOrder } from '../../../shared/interfaces';

import { PaymentsHelper, QuantifiedProductHelper, WorkOrderHelper } from '@vandelft/shared/helpers';
import { datetimeToPlain } from '../transformers';
import { BaseFile } from './base-file';

@Exclude()
export class WorkOrder implements IWorkOrder, IInvoice {
  @Expose()
  public id: string;

  @Expose()
  public reportId: string;

  @Expose()
  public mechanicId: string;

  @Expose()
  public state: string;

  @Expose()
  @Transform(datetimeToPlain, { toPlainOnly: true })
  public planned: Date;

  @Expose()
  @Type(() => WorkOrderProduct)
  @Transform((value: TransformFnParams) => {
    if (!value.value) {
      return [];
    }

    return Array.isArray(value.value) ? value.value : [value.value];
  })
  public products: WorkOrderProduct[];

  @Expose()
  @Type(() => Report)
  public report: Report;

  @Expose()
  @Type(() => User)
  public mechanic: User;

  @Expose()
  public number: string;

  @Expose()
  public invoiceNumber: string;

  @Expose()
  public externalInvoiceNumber: string;

  @Expose()
  public externalOfferNumber: string;

  @Expose()
  public contact: string;

  @Expose()
  public clientName: string;

  @Expose()
  public clientEmail: string;

  @Expose()
  @Transform(datetimeToPlain, { toPlainOnly: true })
  public executed: Date;

  @Expose()
  @Type(() => Payment)
  @Transform((value: TransformFnParams) => {
    if (!value.value) {
      return [];
    }

    return Array.isArray(value.value) ? value.value : [value.value];
  })
  public payments: Payment[];

  @Expose()
  @Type(() => Order)
  public order: Order;

  @Expose()
  public remarks: string;

  @Expose()
  public publicRemarks?: string;

  @Expose()
  @Type(() => BaseFile, {
    discriminator: {
      property: 'internalType',
      subTypes: [
        { value: Image, name: 'image' },
        { value: LocalImage, name: 'localImage' },
      ],
    },
  })
  public images?: Image[] | LocalImage[];

  @Expose()
  @Type(() => LocalImage)
  @Transform((value: TransformFnParams) => {
    if (!value.value) {
      return [];
    }

    return Array.isArray(value.value) ? value.value : [value.value];
  })
  public localImages: LocalImage[];

  @Expose()
  public localClientSignature: string;

  @Expose()
  public localMechanicSignature: string;

  @Expose()
  public isNew: boolean;

  @Expose()
  public isEdit: boolean;

  public orderProducts: boolean;
  public get effectiveDeposit(): number {
    return this.order?.effectiveDeposit || 0;
  }

  public get expires(): Date {
    return moment(this.executed).add(1, 'month').toDate();
  }

  public get paymentMethods(): string[] {
    return WorkOrderHelper.getPaymentMethods(this);
  }

  public get clientSignature(): IImage {
    return WorkOrderHelper.getClientSignature(this);
  }

  public get mechanicSignature(): IImage {
    return WorkOrderHelper.getMechanicSignature(this);
  }

  public get workOrderImages(): IImage[] | undefined {
    return WorkOrderHelper.getWorkOrderImages(this);
  }

  public get totalPriceExc(): number {
    return QuantifiedProductHelper.getTotalPriceExc(this.products) + (this.order?.totalPriceExc || 0);
  }

  public get totalTax(): number {
    return QuantifiedProductHelper.getTotalTax(this.products) + (this.order?.totalTax || 0);
  }

  public get totalProductsPriceInc(): number {
    return QuantifiedProductHelper.getTotalPriceInc(this.products);
  }

  public get totalDeposit(): number {
    return this.totalProductsPriceInc + this.effectiveDeposit;
  }

  public get totalPriceInc(): number {
    return this.totalProductsPriceInc + (this.order?.totalPriceInc || 0);
  }

  public get totalPayed(): number {
    return PaymentsHelper.getTotalPayed(this.payments);
  }

  public get totalOpen(): number {
    return this.totalPriceInc - this.totalPayed;
  }
}
