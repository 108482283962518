export const reportStatuses = [
  {
    value: 'open',
    label: 'Open',
  },
  {
    value: 'in-progress',
    label: 'In behandeling',
  },
  {
    value: 'completed',
    label: 'Afgerond',
  },
  {
    value: 'cancelled',
    label: 'Geannuleerd',
  },
];

export const materials = [
  {
    value: 'wood',
    label: 'Hout',
  },
  {
    value: 'synthetic',
    label: 'Kunstof',
  },
  {
    value: 'aluminium',
    label: 'Aluminium',
  },
  {
    value: 'steel',
    label: 'Staal',
  },
];

export const doorTypes = [
  {
    value: 'single',
    label: 'Enkel',
  },
  {
    value: 'double',
    label: 'Dubbel',
  },
];

export const yesOrNo = [
  {
    value: true,
    label: 'Ja',
  },
  {
    value: false,
    label: 'Nee',
  },
];

export const backsetLocks = [
  {
    value: 25,
    label: '25mm',
  },
  {
    value: 30,
    label: '30mm',
  },
  {
    value: 35,
    label: '35mm',
  },
  {
    value: 40,
    label: '40mm',
  },
  {
    value: 45,
    label: '45mm',
  },
  {
    value: 50,
    label: '50mm',
  },
  {
    value: 55,
    label: '55mm',
  },
  {
    value: 60,
    label: '60mm',
  },
];

export const spacingLocks = [
  {
    value: 55,
    label: 'PC 55',
  },
  {
    value: 70,
    label: 'PC 70',
  },
  {
    value: 72,
    label: 'PC 72',
  },
  {
    value: 85,
    label: 'PC 85',
  },
  {
    value: 92,
    label: 'PC 92',
  },
];
