import { WorkOrder } from '../../models';

export class OpenWorkOrdersList {
  public static readonly type = '[WorkOrders] Open work orders list';
}

export class OpenWorkOrderForm {
  public static readonly type = '[WorkOrders] Open work order form';
  public constructor(public workOrder?: WorkOrder) {}
}

export class LoadWorkOrder {
  public static readonly type = '[WorkOrders] Load work order';
  public constructor(public id: string) {}
}

export class LoadWorkOrders {
  public static readonly type = '[WorkOrders] Load work orders';
}

export class SaveWorkOrder {
  public static readonly type = '[WorkOrders] Save work order';
  public constructor(public workOrder: WorkOrder) {}
}
