<ul class="previews">
  <ng-container *ngFor="let preview of previews; let index = index">
    <li *ngIf="preview.fileType === appFileType">
      <ng-container *ngIf="preview.thumbnail | async as thumbnail">
        <img [src]="thumbnail" class="preview" />
        <a class="btn btn-primary btn-delete btn-outline btn-sm btn-icon" (click)="deleteFile(index, preview.id)">
          <img src="/assets/img/trash.svg" />
        </a>
      </ng-container>
    </li>
  </ng-container>
</ul>
<label type="button" class="btn btn-primary add-photo">
  {{ buttonLabel }}
  <input type="file" [multiple]="allowMultiple" [accept]="fileTypeAccept" (change)="onFileSelected($event)" />
</label>
