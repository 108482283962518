import { Component, Input, forwardRef, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl } from '@angular/forms';

@Component({
  selector: 'app-checkbox-formgroup',
  templateUrl: './checkbox-formgroup.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxFormgroupComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CheckboxFormgroupComponent),
      multi: true,
    },
  ],
})
export class CheckboxFormgroupComponent implements ControlValueAccessor, OnChanges {
  @Input()
  public options = [];

  public internalOptions = [];

  @Input()
  public allowMultiple = false;

  private onChange: any = () => {};
  public onTouch: any = () => {};

  public ngOnChanges(changes: SimpleChanges): void {
    if ('options' in changes) {
      this.internalOptions = [];
      for (const option of changes.options.currentValue) {
        this.internalOptions.push({ ...option });
      }
    }
  }

  public writeValue(value: any): void {
    if (value !== undefined) {
      const option = this.internalOptions.find((o) => o.value === value);

      if (option) {
        option.checked = true;
      }
    }

    return this.onChange(value);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public toggleOption(option: { value: string }, e: any): void {
    e.preventDefault();
    e.stopPropagation();
    this.internalOptions.map((o) => (o.checked = false));
    const value = e.target.checked ? option.value : null;
    this.writeValue(value);
  }

  public validate(_: FormControl): void {
    return null;
  }
}
