import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@vandelft/environments/environment';
import { plainToInstance } from 'class-transformer';
import { Chat, Message, MessageTemplate } from '@vandelft/modules/shared/models';

@Injectable({
  providedIn: 'root',
})
export class WhatsappService {
  public constructor(private http: HttpClient) {}

  public getMessageTemplates(): Observable<MessageTemplate[]> {
    return this.http
      .get(`${environment.apiUrl}/whatsapp/templates`)
      .pipe(map((data: MessageTemplate[]) => plainToInstance(MessageTemplate, data)));
  }

  public getChats(filter: string = 'all'): Observable<Chat[]> {
    return this.http
      .get(`${environment.apiUrl}/whatsapp/chats?filter=${filter}`)
      .pipe(map((data: Chat[]) => plainToInstance(Chat, data)));
  }

  public getChat(addressId: string): Observable<Chat> {
    return this.http
      .get(`${environment.apiUrl}/whatsapp/chat/${addressId}`)
      .pipe(map((data: Chat) => plainToInstance(Chat, data)));
  }

  public getMessage(id: string): Observable<Message> {
    return this.http
      .get(`${environment.apiUrl}/whatsapp/message/${id}`)
      .pipe(map((data: Message) => plainToInstance(Message, data)));
  }

  public sendMessage(message: Partial<Message>): Observable<Message> {
    return this.http
      .post(`${environment.apiUrl}/whatsapp/message/${message.id}`, message)
      .pipe(map((data: Message) => plainToInstance(Message, data)));
  }

  public updateMessage(message: Partial<Message>): Observable<Message> {
    return this.http
      .put(`${environment.apiUrl}/whatsapp/message/${message.id}`, message)
      .pipe(map((data: Message) => plainToInstance(Message, data)));
  }

  public deleteMessage(id: string): Observable<object> {
    return this.http.delete(`${environment.apiUrl}/whatsapp/message/${id}`);
  }

  public markChatAsRead(addressId: string): Observable<Chat> {
    return this.http
      .put(`${environment.apiUrl}/whatsapp/chat/${addressId}/markAsRead`, {})
      .pipe(map((data: Chat) => plainToInstance(Chat, data)));
  }
}
