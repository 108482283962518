import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { combineLatest, firstValueFrom, interval, Observable, Subject } from 'rxjs';
import { filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import * as moment from 'moment';

import { Company, Report, User } from '@vandelft/modules/shared/models';
import { EnvironmentState } from '@vandelft/modules/shared/state/environment';
import {
  DeleteReport,
  OpenReportApproval,
  OpenReportDetails,
  OpenReportForm,
  SaveReportTimes,
} from '@vandelft/modules/shared/state/reports';
import { CreateWorkOrderDraftAndOpenWorkOrderForm } from '@vandelft/modules/shared/state/work-order-draft';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ReportsService } from '@vandelft/modules/shared/services/reports.service';
import { AuthState } from '@vandelft/modules/shared/state/auth';
import { CompaniesState, LoadCompanies } from '@vandelft/modules/shared/state/companies';
import { LoadMechanics, UsersState } from '@vandelft/modules/shared/state/users';
import { reportStatuses } from '@vandelft/shared/constants';
import { ConfirmModalComponent, ReportTimesModalComponent } from '../../ui';
import { ModalService } from '@vandelft/modules/shared/services/modal.service';
import { PageVisiblityService } from '@vandelft/modules/shared/services/page-visibility.service';

@Component({
  selector: 'app-reports-planner-list',
  templateUrl: './planner-list.component.html',
  styleUrls: ['./planner-list.component.scss'],
})
export class PlannerListComponent implements OnInit {
  public reports$: Observable<Report[]>;

  @Select(CompaniesState.companies)
  public companies$: Observable<Company[]>;

  @Select(UsersState.mechanics)
  public mechanics$: Observable<User[]>;

  @Select(AuthState.user)
  public user$: Observable<User>;

  public statuses = [];

  public prefix: string;

  public showFilters = false;

  public filterQs$ = new Subject();

  public searchForm = new FormGroup({
    start: new FormControl(moment().subtract(1, 'week').format('YYYY-MM-DD')),
    end: new FormControl(moment().format('YYYY-MM-DD')),
    city: new FormControl(null),
    street: new FormControl(null),
    houseNumber: new FormControl(null),
    postalCode: new FormControl(null),
    phoneNumber: new FormControl(null),
    mechanicId: new FormControl(null),
    executed: new FormControl(null),
    number: new FormControl(null),
    companyId: new FormControl(null),
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private modalService: ModalService,
    private reportsService: ReportsService,
    private pageVisiblityService: PageVisiblityService,
  ) {
    const user: User = this.store.selectSnapshot(AuthState.user);
    this.showFilters = ['admin', 'planner', 'financial'].includes(user.role);

    type Status = { label: string; value: string };

    const statusGroups = ['in-progress', 'open', 'completed', 'cancelled'];
    this.statuses = reportStatuses
      .filter((s: Status) => statusGroups.includes(s.value))
      .sort((a: Status, b: Status) => {
        const indexA = statusGroups.findIndex((s: string) => s === a.value);
        const indexB = statusGroups.findIndex((s: string) => s === b.value);

        if (indexA > indexB) {
          return 1;
        }

        if (indexA < indexB) {
          return -1;
        }

        return 0;
      });

    const queryParams: Params = this.route.snapshot.queryParams;
    this.searchForm.patchValue(queryParams);
    this.searchForm.valueChanges.subscribe((data) => {
      this.updateRoute(data);
    });

    this.prefix = this.store.selectSnapshot(EnvironmentState.prefix);

    if (this.showFilters) {
      this.store.dispatch([new LoadMechanics(), new LoadCompanies()]);
    }
  }

  public async ngOnInit(): Promise<void> {
    const combined$ = combineLatest([
      interval(30000).pipe(startWith(() => null)),
      this.pageVisiblityService.visibilityChanged$.pipe(
        startWith(() => true),
        filter((v) => !!v),
      ),
      this.searchForm.valueChanges.pipe(startWith(() => null)),
    ]);

    this.reports$ = combined$.pipe(
      map(() => this.searchForm.value),
      tap((data) => {
        setTimeout(() => this.filterQs$.next(this.reportsService.getQueryString(data)), 1);
      }),
      switchMap((data) => this.reportsService.getReports({ ...data })),
    );
  }

  public addWorkOrder(e: Event, report?: Report): boolean {
    e.preventDefault();
    e.stopPropagation();
    this.store.dispatch(new CreateWorkOrderDraftAndOpenWorkOrderForm(report));
    return false;
  }

  public async addReport(): Promise<any> {
    if (!['admin', 'planner', 'financial'].includes(this.prefix)) {
      return;
    }

    return this.store.dispatch(new OpenReportForm());
  }

  public async edit(report?: Report): Promise<any> {
    return this.store.dispatch(new OpenReportForm(report));
  }

  public async view(report?: Report): Promise<any> {
    return this.store.dispatch(new OpenReportDetails(report));
  }

  public async approve(report?: Report): Promise<any> {
    return this.store.dispatch(new OpenReportApproval(report));
  }

  // public async sendMessage(report?: Report): Promise<any> {
  //   console.log(report);
  // }

  public updateRoute(data: any): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: data,
    });
  }

  public compareById(item1: any, item2: any): boolean {
    return item1?.id === item2?.id;
  }

  public async setTimes(e: Event, report: Report): Promise<void> {
    e.preventDefault();
    e.stopPropagation();
    const result = await this.modalService.open(ReportTimesModalComponent, {
      modalType: 'modal-floating-confirmation',
      barrier: false,
      alignment: {
        element: e.target,
      },
    }).result;

    if (result.success) {
      setTimeout(() => {
        report.onTheGo = result.data.onTheGo;
        report.arrival = result.data.arrival;
        report.departure = result.data.departure;
      });
      await firstValueFrom(this.store.dispatch(new SaveReportTimes(report, result.data)));
    }
  }

  public async deleteReport(e: Event, report: Report): Promise<void> {
    e.preventDefault();
    e.stopPropagation();

    const modalRef = this.modalService.open(ConfirmModalComponent, {
      modalType: 'modal-floating-confirmation',
      barrier: false,
      alignment: {
        element: e.target,
      },
    });

    modalRef.componentInstance.message = `Weet u zeker deze melding wilt verwijderen?`;
    const result = await modalRef.result;
    if (!result) {
      return;
    }

    this.store.dispatch([new DeleteReport(report)]);
  }
}
