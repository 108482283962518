<app-page-header title="Bestelling">
  <div class="actions ml-auto" *ngIf="orders$ | async as orders">
    <a
      class="btn btn-primary"
      *ngIf="orders.length > 0"
      target="_blank"
      href="{{ apiUrl }}/orders/download?token={{ token$ | async }}&{{ filterQs$ | async }}">
      Download PDF
    </a>
  </div>
</app-page-header>

<div class="container">
  <div class="card">
    <div class="body">
      <form class="form" [formGroup]="filterForm">
        <div class="row">
          <div class="col col-65">
            <div class="form-group">
              <label class="label">Periode</label>
              <div class="input-group">
                <input type="date" class="form-control" formControlName="start" />
                <input type="date" class="form-control ml-1" formControlName="end" />
              </div>
            </div>
          </div>
          <div class="col col-32"></div>
        </div>
      </form>
    </div>
  </div>

  <div class="card">
    <div class="body">
      <ul class="list" *ngIf="orders$ | async as orders; else loading">
        <div class="p-2" *ngIf="orders?.length === 0">Er zijn geen bestellingen gevonden</div>

        <li *ngFor="let order of orders" (click)="open(order)" class="py-2">
          <div class="details">
            <div class="row">
              <div class="col">
                <div class="bold">
                  {{ order.workOrder.number }} - {{ order.workOrder.report.client.clientAddress.contact }}
                  <ng-container *ngIf="order.workOrder.report.company">
                    (via: {{ order.workOrder.report.company.name }})
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="muted row">
              <div class="col col-40">
                <i>Adres</i>
                {{ order.workOrder.report.client.clientAddress.streetAndHousenumber }}
                <br />
                {{ order.workOrder.report.client.clientAddress.postalCode }}
                {{ order.workOrder.report.client.clientAddress.city }}
              </div>

              <div class="col col-35">
                <i>Uitvoering</i>
                {{ order.workOrder.mechanic?.fullName }}
                <br />
                {{ order.workOrder.executed | date: 'dd-MM-yyyy HH:mm' }}
              </div>
            </div>
            <div class="muted row">
              <div class="col">Aantal producten: {{ order?.orderLines?.length || 0 }}</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="loader">
    <img class="loader" src="assets/img/loader-primary.svg" />
  </div>
</ng-template>
