import { StockItem } from '../../models';

export class OpenStockList {
  public static readonly type = '[Stock] Open stock list';
}

export class OpenStockItemForm {
  public static readonly type = '[Stock] Open stock item form';
  public constructor(public stockItem: StockItem) {}
}
