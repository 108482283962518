import { Component, HostListener } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { SyncState, ProcessUploadQueue } from '@vandelft/modules/shared/state/sync';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-queue-button',
  templateUrl: './queue-button.component.html',
})
export class QueueButtonComponent {
  @Select(SyncState.queueSize)
  public queueSize$: Observable<number>;

  @Select(SyncState.isRunning)
  public isRunning$: Observable<boolean>;

  public constructor(private store: Store) {}

  @HostListener('click')
  public startSync(): void {
    this.store.dispatch(new ProcessUploadQueue());
  }
}
