import { Component, Input, Output, EventEmitter } from '@angular/core';
import { WorkOrder } from '@vandelft/modules/shared/models';
import { Store } from '@ngxs/store';

@Component({
  selector: '[app-work-orders-list]',
  templateUrl: './work-orders-list.component.html',
})
export class WorkOrdersListComponent {
  @Input()
  public workOrders: Array<WorkOrder>;

  @Output()
  public itemClicked = new EventEmitter();

  public constructor(private store: Store) {}

  public onClick(workOrder: WorkOrder): void {
    this.itemClicked.emit({ workOrder });
  }
}
