<form class="form" [formGroup]="form">
  <ng-container class="list">
    <div class="card mt-auto">
      <div class="header px-2 pt-2">
        <h2>Klantgegevens</h2>
      </div>
      <div class="body px-2">
        <ng-container formGroupName="client">
          <div class="form-group">
            <label class="label">Klantsoort</label>
            <select class="form-control" formControlName="type">
              <option [ngValue]="null">---- SELECTEER EEN KLANTSOORT ----</option>
              <option [ngValue]="'private'">Particulier</option>
              <option [ngValue]="'company'">Zakelijk</option>
            </select>
          </div>
        </ng-container>

        <div class="form-group" *ngIf="form.value.client.type === 'company'">
          <label class="label">Opdrachtgever</label>
          <app-autocomplete [items]="companies$ | async" formControlName="company"></app-autocomplete>
        </div>

        <ng-container formGroupName="client">
          <app-address-form-group
            [isCompany]="form.value.client.type === 'company'"
            formControlName="clientAddress"></app-address-form-group>
        </ng-container>

        <div class="form-group" [ngClass]="{ required: form.get('reference').validator !== null }">
          <label class="label">Referentie</label>
          <input type="text" class="form-control" formControlName="reference" />
        </div>

        <div class="form-group" *ngIf="form.value.client.type === 'private'">
          <label class="label">Is het werkadres hetzelfde als bovenstaand adres?</label>
          <select class="form-control" formControlName="locationIsSameAddress">
            <option [ngValue]="true">Ja</option>
            <option [ngValue]="false">Nee</option>
          </select>
        </div>
      </div>
    </div>

    <div class="card mt-1" *ngIf="!form.value.locationIsSameAddress">
      <div class="header px-2 pt-2">
        <h2>Werkadres</h2>
      </div>
      <div class="body p-2">
        <ng-container formGroupName="client">
          <app-address-form-group
            [isCompany]="form.value.client.type === 'company'"
            formControlName="locationAddress"></app-address-form-group>
        </ng-container>
      </div>
    </div>

    <div class="card mt-1">
      <div class="header px-2 pt-2">
        <h2>Werkzaamheden</h2>
      </div>
      <div class="body px-2">
        <div class="form-group">
          <label class="label">Omschrijving werkzaamheden</label>
          <textarea class="form-control" rows="5" formControlName="description"></textarea>
        </div>
      </div>
    </div>

    <ng-container *ngIf="['planner', 'financial', 'admin'].includes(user?.role) && editMode === false">
      <div class="card mt-1 px-2">
        <div class="form-group required">
          <div class="header pt-2">
            <h2>Interne bestanden</h2>
          </div>
          <tna-file-upload
            appFileType="reportInternalImage"
            formControlName="images"
            fileTypeAccept="image/*,application/pdf"
            buttonLabel="Bestanden toevoegen"></tna-file-upload>
        </div>
      </div>

      <div class="card mt-1 px-2">
        <div class="form-group d-flex">
          <input
            type="checkbox"
            #installOrderedProducts
            id="installOrderedProducts"
            formControlName="installOrderedProducts" />
          &nbsp;
          <label for="installOrderedProducts" class="label">Gaat om plaatsen van bestelling?</label>
        </div>
      </div>

      <div class="card px-2" *ngIf="installOrderedProducts.checked">
        <div class="form-group">
          <label class="label">Opmerking voor bestelling plaatsen</label>
          <textarea class="form-control" rows="5" formControlName="installRemarks"></textarea>
        </div>
      </div>
      <div class="card mt-1">
        <div class="header px-2 pt-2">
          <h2>Planning</h2>
        </div>
        <div class="body px-2">
          <div class="form-group required">
            <label class="label">Datum</label>
            <input type="date" class="form-control" formControlName="date" />
          </div>

          <div class="row">
            <div class="col col-48">
              <div class="form-group required">
                <label class="label">Van</label>
                <ngx-timepicker-field
                  [format]="24"
                  class="form-control"
                  formControlName="start"
                  defaultTime="00:00"
                  [controlOnly]="true"></ngx-timepicker-field>
              </div>
            </div>

            <div class="col col-48">
              <div class="form-group required">
                <label class="label">Tot</label>
                <ngx-timepicker-field
                  [format]="24"
                  class="form-control"
                  formControlName="end"
                  defaultTime="00:00"
                  [controlOnly]="true"></ngx-timepicker-field>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label class="label">Monteur</label>
            <ng-container *ngIf="mechanics$ | async as mechanics">
              <select class="form-control" formControlName="mechanic" [compareWith]="compareById">
                <option [ngValue]="null">---- SELECTEER EEN MONTEUR ----</option>
                <option *ngFor="let mechanic of mechanics" [ngValue]="mechanic">{{ mechanic.fullName }}</option>
              </select>
            </ng-container>
          </div>

          <div class="row" *ngIf="!isNew">
            <div class="col">
              <div class="form-group required">
                <label class="label">Onderweg</label>
                <ngx-timepicker-field
                  [format]="24"
                  class="form-control"
                  formControlName="onTheGo"
                  defaultTime="00:00"
                  [controlOnly]="true"></ngx-timepicker-field>
              </div>
            </div>

            <div class="col mx-2">
              <div class="form-group required">
                <label class="label">Aankomst</label>
                <ngx-timepicker-field
                  [format]="24"
                  class="form-control"
                  formControlName="arrival"
                  defaultTime="00:00"
                  [controlOnly]="true"></ngx-timepicker-field>
              </div>
            </div>

            <div class="col">
              <div class="form-group required">
                <label class="label">Vertrek</label>
                <ngx-timepicker-field
                  [format]="24"
                  class="form-control"
                  formControlName="departure"
                  defaultTime="00:00"
                  [controlOnly]="true"></ngx-timepicker-field>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label class="label">Status</label>
            <select class="form-control" formControlName="status">
              <option *ngFor="let status of statuses" [ngValue]="status.value">{{ status.label }}</option>
            </select>
          </div>

          <app-planning-warning [user]="user"></app-planning-warning>
        </div>
      </div>

      <div class="card mt-1">
        <div class="header px-2 pt-2">
          <h2>Opmerkingen</h2>
        </div>
        <div class="body px-2">
          <div class="form-group">
            <label class="label">Opmerking van planning</label>
            <textarea class="form-control" rows="5" formControlName="remarks"></textarea>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</form>
