import { IQuantifiedProduct } from '../interfaces/quantified-product';
import { QuantifiedProductHelper } from './quantified-product-helper';

export class DepositHelper {
  public static getDeposit(products: IQuantifiedProduct[]): number {
    const nonDepositProducts = products?.filter((o) => o.articleNumber?.toUpperCase() !== 'P5-0011');
    const nonDepositTotalInc = QuantifiedProductHelper.getTotalPriceInc(nonDepositProducts);

    const depositProducts = products?.filter((o) => o.articleNumber?.toUpperCase() === 'P5-0011');
    const depositTotalInc = QuantifiedProductHelper.getTotalPriceInc(depositProducts);

    return nonDepositTotalInc / 2 + depositTotalInc;
  }
}
