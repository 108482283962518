import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import {
  EditMessage,
  LoadChatQueue,
  LoadChats,
  LoadMessageTemplates,
  MessagesState,
  OpenChat,
} from '@vandelft/modules/shared/state/messages';
import { Observable } from 'rxjs';
import { Chat, Message, MessageTemplate } from '@vandelft/modules/shared/models';

@Component({
  selector: 'app-message-overview',
  styleUrls: ['./message-overview.component.scss'],
  templateUrl: './message-overview.component.html',
})
export class MessageOverviewComponent implements OnInit {
  @Select(MessagesState.chats) public allChats$: Observable<Chat[]>;
  @Select(MessagesState.chatQueue) public chatQueue$: Observable<Chat[]>;
  @Select(MessagesState.templates) public templates$: Observable<MessageTemplate[]>;

  public allChatsPage = 0;
  public chatsQueuePage = 0;

  public constructor(private store: Store) {}

  public ngOnInit(): void {
    this.store.dispatch(new LoadChats());
    this.store.dispatch(new LoadChatQueue());
    this.store.dispatch(new LoadMessageTemplates());
  }

  public openChat(id: string): void {
    this.store.dispatch(new OpenChat(id));
  }

  public editMessage(event: Event, message: Message): void {
    event.preventDefault();
    this.store.dispatch(new EditMessage(message));
  }

  public getTemplate(message: Message, templates: MessageTemplate[]): MessageTemplate {
    return templates.find((template: MessageTemplate): boolean => template.name === message.template);
  }
}
