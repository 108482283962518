import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { stringify } from 'qs';

import { WorkOrder } from '../models';
import { environment } from '../../../../environments/environment';
import { plainToInstance } from 'class-transformer';

interface ExternalInvoicesFilters {
  end?: string;
  start?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ExternalInvoicesService {
  public constructor(private http: HttpClient) {}

  public getQueryString(options?: any): string {
    const defaultOptions = {
      end: null,
      start: null,
    };

    const mergedOptions = { ...defaultOptions, ...options };

    if (mergedOptions.start !== null && mergedOptions.start.length > 0) {
      mergedOptions.start = moment(mergedOptions.start).format('YYYY-MM-DD');
    }

    if (mergedOptions.end !== null && mergedOptions.end.length > 0) {
      mergedOptions.end = moment(mergedOptions.end).format('YYYY-MM-DD');
    }

    for (const key of Object.keys(mergedOptions)) {
      const value = mergedOptions[key];
      if (!value) {
        delete mergedOptions[key];
      }
    }

    return stringify(mergedOptions);
  }

  public getExternalInvoices(options?: ExternalInvoicesFilters): Observable<WorkOrder[]> {
    const queryString = this.getQueryString(options);
    return this.http
      .get(`${environment.apiUrl}/external-invoices?${queryString}`)
      .pipe(map((data: WorkOrder[]) => plainToInstance(WorkOrder, data)));
  }

  public processExternalInvoices(
    processedExternalInvoices: any[],
    filters?: ExternalInvoicesFilters,
  ): Observable<WorkOrder[]> {
    return this.http
      .post(`${environment.apiUrl}/external-invoices`, { processedExternalInvoices, filters })
      .pipe(map((data: WorkOrder[]) => plainToInstance(WorkOrder, data)));
  }
}
