import { Injectable } from '@angular/core';
import { AuthState, LogoutUser } from '../modules/shared/state/auth';
import { Store } from '@ngxs/store';
import { CanActivate } from '@angular/router';

@Injectable()
export class SuperAdminGuard implements CanActivate {
  constructor(private store: Store) {}

  public canActivate(): any {
    const isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated);
    const isSuperAdmin = this.store.selectSnapshot(AuthState.isSuperAdmin);

    if (!isAuthenticated || !isSuperAdmin) {
      return this.store.dispatch(new LogoutUser());
    }

    return isAuthenticated;
  }
}
