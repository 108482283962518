import { AfterViewInit, Component, ElementRef, forwardRef, OnDestroy } from '@angular/core';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'canvas[app-signature-pad]',
  template: '',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SignaturePadComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SignaturePadComponent),
      multi: true,
    },
  ],
})
export class SignaturePadComponent implements AfterViewInit, OnDestroy {
  private signaturePad: SignaturePad;
  private oldWidth = 0;
  private value: string;
  private ratio = 1;

  private onChange: any = (): void => {};
  public onTouch: any = (): void => {};

  public constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    this.createSignaturePad();
    this.ratio = this.el.nativeElement.height / this.el.nativeElement.width;
    this.detectSizeChange();
  }

  private endStrokeEvent(): void {
    if (!this.signaturePad || this.signaturePad.isEmpty()) {
      this.onChange?.(null);
      return;
    }

    const data: string = this.signaturePad.toDataURL();
    this.value = data;
    this.onChange?.(data);
  }

  private createSignaturePad(): void {
    const signaturePad: SignaturePad = new SignaturePad(this.el.nativeElement, {
      penColor: 'rgb(66, 133, 244)',
    });

    signaturePad.addEventListener('endStroke', this.endStrokeEvent.bind(this));

    this.signaturePad = signaturePad;
  }

  public detectSizeChange(): void {
    setInterval((): void => {
      const width = this.el.nativeElement.offsetWidth;

      if (isNaN(width)) {
        return;
      }

      const diffWidth = Math.abs(this.oldWidth - width);

      if (diffWidth !== 0) {
        const ratio = Math.max(window.devicePixelRatio || 1, 1);
        this.el.nativeElement.width = width * ratio;
        this.el.nativeElement.height = width * ratio * this.ratio;
        this.el.nativeElement.getContext('2d').scale(ratio, ratio);
        this.signaturePad.clear();

        if (this.value) {
          this.signaturePad.fromDataURL(this.value);
        }

        this.oldWidth = width;
      }
    }, 500);
  }

  public writeValue(value: any): void {
    if (value) {
      this.onChange(value);
      this.value = value;
      this.signaturePad.fromDataURL(value);
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public validate(_: FormControl): { invalid: boolean } {
    if (!this.signaturePad || !this.signaturePad.isEmpty()) {
      return null;
    }

    return { invalid: true };
  }

  ngOnDestroy(): void {
    this.signaturePad.removeEventListener('endStroke', this.endStrokeEvent);
  }
}
