import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { v4 as uuid } from 'uuid';

import { WorkOrdersStateModel } from '@vandelft/modules/shared/state/work-orders/work-orders.state-model';
import { WorkOrdersService } from '@vandelft/modules/shared/services/work-orders.service';

import { LoadWorkOrder, LoadWorkOrders, OpenWorkOrderForm, OpenWorkOrdersList } from './work-orders.actions';
import { WorkOrder } from '../../models';
import { EnvironmentState } from '../environment';
import { firstValueFrom, Observable } from 'rxjs';
import { plainToInstance } from 'class-transformer';

@State<WorkOrdersStateModel>({
  name: 'workOrders',
  defaults: {
    workOrders: [],
    workOrder: null,
  },
})
@Injectable()
export class WorkOrdersState {
  public constructor(
    private store: Store,
    private workOrdersService: WorkOrdersService,
  ) {}

  @Selector()
  static workOrders(state: WorkOrdersStateModel): Array<WorkOrder> {
    return state.workOrders;
  }

  @Selector()
  static workOrder(state: WorkOrdersStateModel): WorkOrder {
    return state.workOrder;
  }

  @Action(LoadWorkOrders)
  public async loadWorkOrders(ctx: StateContext<WorkOrdersStateModel>): Promise<WorkOrdersStateModel> {
    const workOrders = await firstValueFrom(this.workOrdersService.getWorkOrders());
    return ctx.patchState({ workOrders });
  }

  @Action(LoadWorkOrder)
  public async loadWorkOrder(
    ctx: StateContext<WorkOrdersStateModel>,
    { id }: LoadWorkOrder,
  ): Promise<WorkOrdersStateModel> {
    let workOrder = await firstValueFrom(this.workOrdersService.getWorkOrderById(id));

    if (!workOrder) {
      workOrder = plainToInstance(WorkOrder, {
        id: uuid(),
        company: {
          id: uuid(),
        },
        client: {
          id: uuid(),
        },
      });
    }

    return ctx.patchState({ workOrder });
  }

  @Action(OpenWorkOrdersList)
  public openReportsList(): Observable<any> {
    const prefix = this.store.selectSnapshot(EnvironmentState.prefix);
    return this.store.dispatch(new Navigate([`/${prefix}/work-orders`]));
  }

  @Action(OpenWorkOrderForm)
  public openProductForm(ctx: StateContext<WorkOrdersStateModel>, { workOrder }: OpenWorkOrderForm): Observable<any> {
    const prefix = this.store.selectSnapshot(EnvironmentState.prefix);

    if (prefix === 'admin') {
      if (workOrder) {
        return this.store.dispatch(new Navigate([`/${prefix}/work-orders/${workOrder.id}`]));
      }

      ctx.patchState({ workOrder: null });
      return this.store.dispatch(new Navigate([`/${prefix}/work-orders/add`]));
    }

    if (workOrder) {
      return this.store.dispatch(new Navigate([`/${prefix}/work-orders/${workOrder.id}/client-type`]));
    }

    return this.store.dispatch(new Navigate([`/${prefix}/work-orders/add`]));
  }
}
