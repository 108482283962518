import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { ProgressState } from '@vandelft/modules/shared/state/progress';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
})
export class ProgressComponent {
  @Select(ProgressState.steps)
  public steps$: Observable<any[]>;

  public constructor(private route: ActivatedRoute, private router: Router) {}

  public goToStep(step: any): void {
    if (!step.done) {
      return;
    }

    this.router.navigate([`./${step.key}`], { relativeTo: this.route.firstChild.firstChild.firstChild.firstChild });
  }
}
