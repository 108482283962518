import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Order, Product, Report, WorkOrder } from '../../models';

export const transformModel =
  <T>(className: any) =>
  (source$: Observable<T>) => {
    const setTypeOnImages = (images: any[]) => {
      return images.map((image: any) => {
        image.internalType = 'image';
        return image;
      });
    };

    return source$.pipe(
      map((data: any) => {
        if (className === WorkOrder || className === Product || className === Order || className === Report) {
          data.images = setTypeOnImages(data?.images ?? []);
        }

        if (className === WorkOrder && data?.order) {
          data.order.images = setTypeOnImages(data?.order?.images ?? []);
        }

        if (className === Report) {
          (data?.workOrders ?? []).map((workOrder: WorkOrder) => {
            workOrder.images = setTypeOnImages(workOrder.images ?? []);
            return workOrder;
          });
        }

        return data;
      }),
      map((data: T) => plainToInstance<T, any>(className, data)),
    );
  };
