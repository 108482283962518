import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { startWith } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import * as moment from 'moment';

import { environment } from '@vandelft/environments/environment';
import { User, WorkOrder } from '@vandelft/modules/shared/models';
import { OpenInvoicesDetails, InvoicesState, LoadInvoices } from '@vandelft/modules/shared/state/invoices';
import { AuthState } from '@vandelft/modules/shared/state/auth';
import { WorkOrdersService } from '@vandelft/modules/shared/services/work-orders.service';
import { LoadMechanics, UsersState } from '@vandelft/modules/shared/state/users';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
})
export class ListComponent implements OnInit {
  @Select(InvoicesState.workOrders)
  public workOrders$: Observable<WorkOrder[]>;

  @Select(AuthState.token)
  public token$: Observable<string>;

  @Select(UsersState.mechanics)
  public mechanics$: Observable<User[]>;

  public apiUrl = environment.apiUrl;

  public filterQs$ = new Subject();

  public filterForm = new FormGroup({
    start: new FormControl(moment().subtract(1, 'week').format('YYYY-MM-DD')),
    end: new FormControl(moment().add(1, 'week').format('YYYY-MM-DD')),
    paymentMethod: new FormControl(null),
    mechanic: new FormControl(null),
    state: new FormControl(null),
  });

  public constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private workOrdersService: WorkOrdersService,
  ) {
    const queryParams = this.route.snapshot.queryParams;
    this.filterForm.patchValue(queryParams);
    this.filterForm.valueChanges.subscribe((data) => {
      this.updateRoute(data);
    });

    this.store.dispatch(new LoadMechanics());
  }

  public async ngOnInit(): Promise<void> {
    this.filterForm.valueChanges.pipe(startWith(this.filterForm.value)).subscribe((data: any) => {
      setTimeout(() => this.filterQs$.next(this.workOrdersService.getQueryString(data)));
      this.store.dispatch(new LoadInvoices(data));
    });
  }

  public updateRoute(data: any): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: data,
    });
  }

  public open(workOrder: WorkOrder): void {
    this.store.dispatch(new OpenInvoicesDetails(workOrder));
  }
}
