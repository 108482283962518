<div class="form" *ngIf="workOrder$ | async as workOrder">
  <app-page-header title="Factuur">
    <div class="actions ml-auto">
      <a
        class="btn btn-primary"
        target="_blank"
        href="{{ apiUrl }}/invoices/{{ workOrder.id }}/download?token={{ token$ | async }}">
        Download PDF
      </a>
    </div>
  </app-page-header>

  <div class="container px-2">
    <div class="card">
      <div class="body py-2">
        <div class="row">
          <div class="col">
            <div class="form-group plain-text">
              {{ workOrder.report.client.clientAddress.contact }}
              <br />
              {{ workOrder.report.client.clientAddress.street }}
              {{ workOrder.report.client.clientAddress.houseNumber }}
              {{ workOrder.report.client.clientAddress.houseNumberSuffix }}
              <br />
              {{ workOrder.report.client.clientAddress.postalCode }} {{ workOrder.report.client.clientAddress.city }}
              <br />
              {{ workOrder.clientEmail }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-1 mb-3">
      <div class="col card">
        <div class="body">
          <div class="invoice-header border-top border-bottom">
            <div class="row border-bottom">
              <div class="col bold">Factuurdatum</div>
              <div class="col bold">Vervaldatum</div>
              <div class="col bold">Factuurnummer</div>
            </div>

            <div class="row">
              <div class="col">{{ workOrder.executed | date: 'dd MMMM yyyy' }}</div>
              <div class="col">{{ workOrder.expires | date: 'dd MMMM yyyy' }}</div>
              <div class="col">{{ workOrder.invoiceNumber }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col card">
        <div class="body py-2">
          <ul class="material-list">
            <li class="border-bottom">
              <div class="details">
                <div class="title bold">Arbeid / materiaal</div>
                <div class="quantity bold">Aantal</div>
                <div class="price bold">Prijs</div>
                <div class="price bold">Totaal</div>
              </div>
            </li>

            <li *ngFor="let product of workOrder.products">
              <a>
                <div class="details">
                  <div class="title">
                    {{ product.description }}
                    <ng-container *ngIf="product.articleNumber">({{ product.articleNumber }})</ng-container>
                  </div>
                  <div class="quantity">{{ product.quantity }}</div>
                  <div *ngIf="!product.onLoan; else onLoan" class="price">
                    <div tna-money [amount]="product.priceInc"></div>
                  </div>
                  <div *ngIf="!product.onLoan; else onLoan" class="price">
                    <div tna-money [amount]="product.totalPriceInc"></div>
                  </div>
                  <ng-template #onLoan><div class="price">Bruikleen</div></ng-template>
                </div>
              </a>
            </li>
            <li *ngFor="let orderLine of workOrder.order?.orderLines">
              <a>
                <div class="details">
                  <div class="title">
                    {{ orderLine.description }}
                    <ng-container *ngIf="orderLine.articleNumber">({{ orderLine.articleNumber }})</ng-container>
                  </div>
                  <div class="quantity">{{ orderLine.quantity }}</div>
                  <div class="price"><div tna-money [amount]="orderLine.priceInc"></div></div>
                  <div class="price"><div tna-money [amount]="orderLine.totalPriceInc"></div></div>
                </div>
              </a>
            </li>
            <li class="footer">&nbsp;</li>
            <li class="footer">
              <div class="details">
                <div class="spacer"></div>
                <div class="label">Totaal exclusief BTW</div>
                <div class="price"><div tna-money [amount]="workOrder.totalPriceExc"></div></div>
              </div>
            </li>
            <li class="footer">
              <div class="details">
                <div class="spacer"></div>
                <div class="label">BTW</div>
                <div class="price"><div tna-money [amount]="workOrder.totalTax"></div></div>
              </div>
            </li>
            <li class="footer">
              <div class="details">
                <div class="spacer"></div>
                <div class="label">Totaal</div>
                <div class="price"><div tna-money [amount]="workOrder.totalPriceInc"></div></div>
              </div>
            </li>
            <li class="footer">&nbsp;</li>

            <li class="footer" *ngFor="let payment of workOrder.payments">
              <div class="details">
                <div class="spacer"></div>
                <div class="label">Reeds voldaan ({{ paymentMethods[payment.method] }})</div>
                <div class="price"><div tna-money [amount]="payment.amount"></div></div>
              </div>
            </li>
            <li class="footer">
              <div class="details">
                <div class="spacer"></div>
                <div class="label">Reeds voldaan (Totaal)</div>
                <div class="price"><div tna-money [amount]="workOrder.totalPayed"></div></div>
              </div>
            </li>
            <li class="footer">
              <div class="details">
                <div class="spacer"></div>
                <div class="label bold border-bottom border-top">Te voldoen</div>
                <div class="price bold border-bottom border-top">
                  <div tna-money [amount]="workOrder.totalOpen"></div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
