import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { first, switchMap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { Product } from '../../../models';
import { ProductsService } from '@vandelft/modules/shared/services/products.service';
import { OpenProductsList, SaveProduct } from '@vandelft/modules/shared/state/products';
import { plainToInstance } from 'class-transformer';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-products-form',
  templateUrl: './form.component.html',
})
export class FormComponent implements OnInit {
  @Output()
  public save = new EventEmitter<any>();

  public isBusy = false;
  public productId: string;

  public productForm = new FormGroup({
    articleNumber: new FormControl(null, [Validators.required]),
    description: new FormControl(null, [Validators.required]),
    price: new FormControl(null, [Validators.required]),
    onLoan: new FormControl(false, [Validators.required]),
    images: new FormControl([]),
  });

  public constructor(
    private store: Store,
    private route: ActivatedRoute,
    private productsService: ProductsService,
  ) {}

  public async ngOnInit(): Promise<void> {
    const productId: string = this.route.snapshot.paramMap.get('id');
    this.productId = productId || uuid();

    if (productId) {
      const product = await firstValueFrom(this.productsService.getProductById(productId).pipe(first()));
      this.productForm.patchValue(product);
    }
  }

  public onSubmit(): void {
    try {
      this.isBusy = true;
      const product: Product = plainToInstance(Product, {
        ...this.productForm.value,
        id: this.productId,
      });
      this.store
        .dispatch(new SaveProduct(product))
        .pipe(switchMap(() => this.store.dispatch(new OpenProductsList())))
        .subscribe();
    } catch (e) {
      console.error(e);
    } finally {
      this.isBusy = false;
    }
  }
}
